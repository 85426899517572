<template>
  <div class="flex flex-row min-h-content">
    <Sidenav v-if="this.hasSidenav" />
    <div
      class="mx-auto mt-3"
      :class="[
        shouldRenderContainer ? 'container px-2' : 'px-0 w-full',
        !showView && 'py-2',
      ]"
    >
      <router-view v-if="showView" />
      <div
        v-else
        class="bg-white mx-2 h-full flex items-center justify-center flex-col"
      >
        <h1 class="pb-1 font-bold text-grey-dark-2 text-mdxl">
          {{ subscriptionText }}
        </h1>
        <div class="text-grey-light text-md">
          <p v-if="isEmployee || isManager">
            Please contact your company administrator or ISM for more details.
          </p>
          <p v-if="isAdmin">
            {{ subscriptionDescription }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Sidenav from "./Sidenav";
export default {
  name: "Content",
  components: {
    Sidenav,
  },
  computed: {
    ...mapState({
      renderAs: (state) => state.home.renderAs,
      subDetails: (state) => state.auth.subDetails,
    }),
    ...mapGetters({
      isSubscriptionActive: "auth/isSubscriptionActive",
      getRole: "home/getBestRole",
    }),
    subscriptionText() {
      return this.subDetails.subscriptionStatus === 0
        ? "No Active Subscription"
        : "Subscription Expired";
    },
    subscriptionDescription() {
      return this.subDetails.subscriptionStatus === 0
        ? "Please contact ISM to activate your subscription."
        : "Please contact ISM to renew your subscription.";
    },
    isManager() {
      return this.getRole.toLowerCase() === "manager";
    },
    isEmployee() {
      return this.getRole.toLowerCase() === "employee";
    },
    isAdmin() {
      return this.getRole.toLowerCase() === "admin";
    },
    showView() {
      if (this.$route.meta.isPublic) {
        return true;
      }
      if (this.$route.name === "MyAccount") return true;
      return this.isSubscriptionActive;
    },
    hasSidenav() {
      return this.$route.meta.hasSidenav && this.canRender;
    },
    shouldRenderContainer() {
      return !this.$route.meta.noContainer;
    },
    canRender() {
      return this.$route.meta.sidenavFor?.find((el) => el === this.renderAs);
    },
  },
};
</script>
