import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import home from '@/views/Home/store';
import teams from '@/views/Team/store';
import modals from '../components/Modal/store';
import assessments from '@/views/Assessment/store';
import onboarding from '@/views/Onboarding/store';
import results from '@/views/Results/store';
import people from '@/views/People/store';
import auth from './modules/auth';
import alerts from './modules/alerts';
import calls from './modules/calls';
import utils from './modules/utils';
import invites from './modules/invites';
import account from '@/views/Account/store';
import assessmentSetup from '@/views/AdminAssessments/store';
import adminTeams from '@/views/AdminTeams/store';
import companies from '@/views/Companies/store';
import teamResults from '@/views/TeamResults/store';
import companyResults from '@/views/CompanyResults/store';
import products from '@/views/Products/store';
import subscriptions from './modules/subscriptions';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    home,
    subscriptions,
    people,
    assessments,
    results,
    account,
    onboarding,
    alerts,
    auth,
    modals,
    calls,
    teams,
    assessmentSetup,
    teamResults,
    companyResults,
    utils,
    adminTeams,
    companies,
    invites,
    products
  },
  plugins: [
    createPersistedState({
      paths: [
        'home',
        'auth',
        'assessments',
        'assessmentSetup',
        'companies',
        'people',
        'products',
      ],
    }),
  ],
});
