<template>
  <div class="flex items-center h-140 flex-grow rounded shadow bg-white">
    <div
      class="bg-blue-light-5 h-1/3 w-1/3 flex justify-center items-center mr-2"
      :style="`background-color: ${data.color}`"
    >
      <img :src="icon" />
    </div>
    <div>
      <p class="font-barlow font-bold text-xl text-grey-dark-2">
        {{ data.number }}
      </p>
      <p class="text-sm-2 text-grey-light">{{ data.text }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "SmallInfoCard",
  props: {
    icon: { default: null },
    data: { type: Object, default: null },
  },
};
</script>
