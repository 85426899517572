<template>
  <div>
    <div
      class="w-full h-full fixed z-40 bottom-0 flex justify-center items-center modal"
    >
      <div class="w-modal bg-white p-4 shadow flex-grow-0 rounded">
        <h1 class="font-headers font-bold text-grey-dark-2 text-l mb-1.5">
          Company Details
        </h1>
        <InputField
          placeholder="Type a company name"
          label="Company Name"
          :propValue="companyName"
          :onChange="setCompanyName"
          class="mb-2 mt-3"
          data-testid="name"
        />
        <Select
          label="Industry"
          :options="options"
          :searchable="true"
          :onSelect="selectIndustry"
          :value="selectedIndustry"
          :preselectFirst="false"
          data-testid="industry"
        />

        <div class="flex flex-row flex-grow justify-end mt-4 space-x-2">
          <Button
            type="tertiary"
            size="medium"
            text="Cancel"
            @click.native="closeModal"
            data-testid="cancel-1"
          />
          <Button
            text="Save"
            type="primary"
            size="medium"
            :disabled="disableSave"
            @click.native="save"
            data-testid="save-1"
          />
        </div>
      </div>
    </div>
    <div
      class="w-full h-full fixed z-30 bottom-0 bg-grey-dark-1 opacity-70 modal transition-all"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "CreateCompanyModal",
  data: () => ({
    companyName: "",
    selectedIndustry: null,
  }),
  computed: {
    ...mapState({
      industries: (state) => state.companies.industries,
      industrySectors: (state) => state.companies.industrySectors,
    }),
    options() {
      return this.industries.map((el) => ({
        label: el.name,
        id: el.id,
      }));
    },

    disableSave() {
      return !this.companyName || !this.selectedIndustry;
    },
  },
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      create: "companies/create",
    }),
    selectIndustry(val) {
      this.selectedIndustry = val;
    },
    setCompanyName(val) {
      this.companyName = val;
    },
    async save() {
      const payload = {
        name: this.companyName,
        industryId: this.selectedIndustry?.id,
      };
      const newCompany = await this.create({ payload });
      this.$router.push(`/company/${newCompany.id}/overview`);
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  top: 0;
}
</style>
