<template>
  <div class="bg-white rounded shadow px-2 pb-3.5">
    <div class="pr-2 py-2 flex flex-grow justify-between items-center">
      <p class="text-grey-light font-bold text-mdl font-headers">Activity</p>
      <router-link to="/results/history">
        <Button
          text="View Historical Results"
          size="medium"
          type="secondary"
          :iconRight="arrow"
        />
      </router-link>
    </div>
    <ul class="m-0">
      <li v-for="item in onlyThree" :key="item.id">
        <ListItem :data="item" />
      </li>
    </ul>
  </div>
</template>

<script>
import ListItem from "./ListItem.vue";
import Button from "../Button/Button.vue";
import arrow from "@/assets/img/icons/arrow-right.svg";

export default {
  components: { ListItem, Button },
  name: "ResultsList",
  props: { data: { type: Array, default: null } },
  data: () => ({ arrow }),
  computed: {
    onlyThree: function() {
      // return this.data.slice(0, 3);
      return this.data.sort((a, b) => +new Date(b.completedOn) - +new Date(a.completedOn));
    },
  },
};
</script>

<style lang="scss" scoped></style>
