var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"xl:min-w-250 min-w-200 border-r border-grey-nav"},[(_vm.options.isDeep)?_c('div',{staticClass:"border-b border-grey-nav p-3"},[(_vm.globalCompetency)?_c('router-link',{staticClass:"flex text-blue-dark-1 text-md font-medium",attrs:{"to":'/competencies'}},[_c('img',{staticClass:"mr-1",attrs:{"src":require("../../assets/img/menu/arrow-left.svg"),"width":"10"}}),_vm._v(" "+_vm._s('Competencies')+" ")]):_vm._e(),(!_vm.globalCompetency)?_c('router-link',{staticClass:"flex text-blue-dark-1 text-md font-medium",attrs:{"to":_vm.itemLink({ route: _vm.deepPathRoute }) || '/'}},[_c('img',{staticClass:"mr-1",attrs:{"src":require("../../assets/img/menu/arrow-left.svg"),"width":"10"}}),_vm._v(" "+_vm._s(_vm.deepNameRoute || 'Dashboard')+" ")]):_vm._e()],1):_vm._e(),(
      _vm.options.title &&
        !_vm.shouldRenderActiveAssessments &&
        !_vm.shouldRenderActiveCompanyAssessments
    )?_c('div',{staticClass:"pt-1 px-4"},[_c('p',{staticClass:"text-md text-grey-medium-2"},[_vm._v(_vm._s(_vm.options.title))])]):_vm._e(),(
      !_vm.shouldRenderActiveAssessments &&
        !_vm.shouldRenderClosedAssessments &&
        !_vm.shouldRenderActiveCompanyAssessments &&
        !_vm.shouldRenderNewAssessmentLinks
    )?_c('div',{staticClass:"flex flex-col mt-1"},_vm._l((_vm.options.links),function(link){return _c('router-link',{key:link.name,staticClass:"py-2 text-grey-light text-md font-semibold relative flex items-center cursor-pointer",class:[
        _vm.options.isDeep ? 'pl-6' : 'pl-3',
        link.route === '#' ? 'pointer-events-none' : '',
        _vm.isNested(link.route) ? 'router-link-exact-active' : '' ],attrs:{"to":_vm.itemLink(link)}},[(link.icon)?_c('div',{staticClass:"w-32 h-32 bg-grey-light-1 bg-opacity-20 rounded mr-1.5 icon flex justify-center items-center"},[_c('IconBase',{attrs:{"iconColor":_vm.isLinkActive(link) ? '#00A1D5' : '#748FA8',"width":link.icon === 'team' || link.icon === 'competencies' ? 18 : 16,"height":link.icon === 'competencies' ? 18 : 16}},[_c(_vm.linkIcon(link.icon),{tag:"component"})],1)],1):_vm._e(),_vm._v(" "+_vm._s(link.name)+" ")])}),1):(
      _vm.shouldRenderActiveAssessments || _vm.shouldRenderActiveCompanyAssessments
    )?_c('div',[_c('ActiveAssessmentsSidenav',{attrs:{"data":_vm.activeAssessments,"type":this.role}})],1):(_vm.shouldRenderClosedAssessments)?_c('div',[_c('router-link',{staticClass:"py-2 mt-1 text-grey-light text-md font-semibold relative flex items-center cursor-pointer pl-6",attrs:{"to":this.$route.path}},[_vm._v(" By Assessment ")])],1):(_vm.shouldRenderNewAssessmentLinks)?_c('div',[_c('NewAssessmentSidenav')],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }