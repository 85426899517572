<template>
  <div v-if="teamToReview">
    <div class="flex bg-white justify-between pr-6 pb-4 rounded">
      <div class="pl-4 pt-4 space-y-2">
        <p class="font-medium text-mdl text-grey-dark-1">
          A Team has been Created for You
        </p>
        <p class="text-sm-2 text-grey-dark-2 max-w-3/5">
          Your Company Admin has already setup a team for you to manage. Please
          take a look and check if everything is in order.
        </p>
        <Button
          text="Review Team"
          type="primary"
          size="medium"
          @click.native="reviewTeam"
        />
      </div>
      <img src="../../../assets/img/home/new-team.svg" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Button from "../../../components/Button/Button.vue";
export default {
  components: { Button },
  name: "InitialTeamCard",
  props: {
    teamToReview: { type: Number, default: null },
  },
  methods: {
    ...mapActions({
      review: "teams/review",
    }),
    async reviewTeam() {
      await this.review({ id: this.teamToReview });
      this.$router.push(`/teams/${this.teamToReview}`);
    },
  },
};
</script>

<style></style>
