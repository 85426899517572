import {
  getTeam,
  fetchTeams,
  fetchTeamsIds,
  reviewTeam,
  fetchMembersBySurvey,
} from "@/services/api/teams.js";

const getDefaultState = () => ({
  team: null,
  teams: null,
  teams_ids: null,
  surveyMembers: [],
});

const actions = {
  resetTeamsState({ commit }) {
    commit("resetState");
  },
  clearTeam({ commit }) {
    return commit("SET_TEAM", null);
  },
  getTeam({ commit }, { id }) {
    return getTeam(id).then((rsp) => {
      const { data } = rsp.data;
      commit("SET_TEAM", data);
    });
  },
  getMembersOfSurvey({ commit }, { teamId, surveyId, jobRoleId }) {
    const jobId = jobRoleId === 0 ? null : jobRoleId;
    return fetchMembersBySurvey(teamId, surveyId, jobId).then((rsp) => {
      commit("SET_SURVEY_MEMBERS", rsp.data.data);
    });
  },
  getTeams({ commit, rootGetters }) {
    return fetchTeams(rootGetters["auth/getOrganizationId"]).then((rsp) =>
      commit("SET_TEAMS", rsp.data.data)
    );
  },
  review(_, { id }) {
    return reviewTeam(id);
  },
  getTeamsIds({ commit }) {
    return fetchTeamsIds().then((rsp) => {
      commit("SET_TEAMS_IDS", rsp.data.data);
    });
  },
};

const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TEAM(state, payload) {
    state.team = payload;
  },
  SET_TEAMS(state, payload) {
    state.teams = payload;
  },
  SET_TEAMS_IDS(state, payload) {
    state.teams_ids = payload;
  },
  SET_SURVEY_MEMBERS(state, payload) {
    state.surveyMembers = payload;
  },
};

const getters = {
  getMemberDetails: (state) => (id) =>
    state.surveyMembers.find((el) => el.id === id),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
