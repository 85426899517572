<template>
  <div>
    <div
      class="w-full h-full fixed z-40 bottom-0 flex justify-center items-center modal"
    >
      <div class="w-modal bg-white p-4 shadow flex-grow-0 rounded">
        <h1 class="font-headers font-bold text-grey-dark-2 text-l mb-1.5">
          Company Admin Details
        </h1>
        <div class="flex flex-row w-full justify-between">
          <div class="w-1/2 pr-2">
            <InputField
              placeholder="First name"
              label="First name"
              type="text"
              :propValue="admin.firstName"
              :onChange="(val) => setValue('firstName', val)"
              class="mb-2 mt-3"
              data-testid="first-name-input"
            />
          </div>
          <div class="w-1/2">
            <InputField
              placeholder="Last name"
              label="Last name"
              type="text"
              :propValue="admin.lastName"
              :onChange="(val) => setValue('lastName', val)"
              class="mb-2 mt-3"
              data-testid="last-name-input"
            />
          </div>
        </div>
        <InputField
          placeholder="Email"
          label="Email"
          type="text"
          :disabled="email !== ''"
          :propValue="admin.email"
          :onChange="(val) => setValue('email', val)"
          class="mb-2 mt-2"
          data-testid="email-field"
        />
        <div class="flex flex-col mt-2" v-if="edit">
          <p class="text-grey-dark-1 text-md mb-1 font-semibold">
            Remove Admin Rights
          </p>
          <div class="ml-2">
            <p class="text-grey-light text-sm-2">
              If you remove this user as admin, they will no longer be able to
              manage this company. Other roles of the user will not be affected.
              If the user doesn't have other roles, their account will be
              deactivated.
            </p>
            <div class="flex flex-row mt-1.5">
              <div class="pt-0.5">
                <Checkbox
                  class="cursor-pointer"
                  :active="permisions.permisionCheck"
                  @click.native="check('permisionCheck')"
                  data-testid="permission-checkbox"
                />
              </div>
              <p
                class="text-mdh pl-1 cursor-pointer text-grey-dark-2"
                @click="check('permisionCheck')"
              >
                I understand that I am removing admin permission for this
                person.
              </p>
            </div>
            <div class="flex flex-row mt-1.5">
              <div class="pt-0.5">
                <Checkbox
                  class="cursor-pointer"
                  :active="permisions.roleCheck"
                  @click.native="check('roleCheck')"
                  data-testid="role-checkbox"
                />
              </div>
              <p
                class="text-mdh pl-1 cursor-pointer text-grey-dark-2"
                @click="check('roleCheck')"
              >
                I understand that if this user has no other roles, their account
                will be deactivated.
              </p>
            </div>
            <p v-if="showErr" class="text-red text-mdh">
              Both options should be checked in order to remove admin rights.
            </p>
          </div>
        </div>
        <div class="flex flex-row flex-grow justify-end mt-4 space-x-2">
          <Button
            type="tertiary"
            size="medium"
            text="Cancel"
            @click.native="closeModal"
            data-testid="cancel-button"
          />
          <Button
            text="Save"
            type="primary"
            size="medium"
            :disabled="disableSave || showErr"
            @click.native="edit ? update() : save()"
            data-testid="save-button"
          />
        </div>
      </div>
    </div>
    <div
      class="w-full h-full fixed z-30 bottom-0 bg-grey-dark-1 opacity-70 modal transition-all"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "EditAdminModal",
  props: {
    firstName: {
      type: String,
      default: "",
    },
    lastName: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    id: {
      type: Number,
      default: undefined,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    admin: {
      firstName: "",
      lastName: "",
      email: "",
    },
    permisions: {
      permisionCheck: false,
      roleCheck: false,
    },
  }),
  mounted() {
    this.admin.firstName = this.firstName;
    this.admin.lastName = this.lastName;
    this.admin.email = this.email;
  },
  computed: {
    disableSave() {
      return !this.admin.firstName || !this.admin.lastName || !this.admin.email;
    },
    showErr() {
      if (!this.permisions.roleCheck && !this.permisions.permisionCheck) {
        return;
      }
      if (this.permisions.roleCheck && this.permisions.permisionCheck) {
        return;
      }
      return true;
    },
  },
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      addAdminToCompany: "companies/addAdminToCompany",
      editCompanyAdmin: "companies/editCompanyAdmin",
    }),
    setValue(field, val) {
      this.admin[field] = val;
    },
    check(permision) {
      this.permisions[permision] = !this.permisions[permision];
    },
    async save() {
      const payload = {
        firstName: this.admin.firstName,
        lastName: this.admin.lastName,
        email: this.admin.email,
        organizationId: parseInt(this.$route.params.companyId),
        roles: [2],
      };
      await this.addAdminToCompany({
        id: this.$route.params.companyId,
        payload,
      });
      this.closeModal();
    },
    async update() {
      const payload = {
        id: this.id,
        firstName: this.admin.firstName,
        lastName: this.admin.lastName,
        removeAdminRights:
          this.permisions.permisionCheck && this.permisions.roleCheck,
      };
      await this.editCompanyAdmin({
        id: this.$route.params.companyId,
        payload,
      });
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  top: 0;
}
</style>
