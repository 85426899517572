<template>
  <Modal :data="modalData" :isVisible="visible">
    <div class="flex flex-row flex-grow justify-end">
      <Button
        type="tertiary"
        size="medium"
        text="No"
        @click.native="closeModal"
        class="mr-2"
      />
      <Button
        type="primary"
        text="Yes"
        size="medium"
        @click.native="confirm"
        class="px-3"
      />
    </div>
  </Modal>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "RetireAssessmentModal",
  props: ["visible", "id", "productDraftName"],
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      openModal: "modals/openModal",
      retireProduct: "products/retireProduct",
      getProducts: "products/getProducts",
      getDrafts: "products/getDrafts",
      getRetiredProducts: "products/getRetiredProducts",
      getVersionHistoryProducts: "products/getVersionHistoryProducts",
    }),
    async confirm() {
      const result = await this.retireProduct(this.id);
      if(result) {
        this.getDrafts();
        this.getProducts();
        this.getRetiredProducts();
        this.getVersionHistoryProducts();
        this.closeModal();
      }
    },
  },
  computed: {
    modalData() {
      return {
        title: "Retire Assessment",
        confirmationText: "Are you sure you want to retire this product?",
        content: `You are about to retire the ${this.productDraftName} product. Please note you will no longer be able to create new assessments of this type. Any draft versions of the assessment will be discontinued.`, 
      };
    }
  }
};
</script>
