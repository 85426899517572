<template>
  <div>
    <div
      class="w-full h-full fixed z-40 flex flex-col justify-center items-center modal"
    >
      <div class="w-modal bg-white p-4 shadow overflow-hidden rounded">
        <p class="font-barlow font-bold text-grey-dark-2 text-l mb-1.5">
          Manage User
        </p>
        <div class="p-1">
          <div class="flex flex-grow mb-3 space-x-2">
            <InputField
              label="First Name"
              class="flex-1"
              :onChange="setFirstName"
              :propValue="firstName"
              data-testid="first-name-field"
            />
            <InputField
              label="Last Name"
              class="flex-1"
              :onChange="setLastName"
              :propValue="lastName"
              data-testid="last-name-field"
            />
          </div>
          <InputField
            label="Job Title"
            class="mb-3"
            :onChange="setJobTitle"
            :propValue="jobTitle"
            data-testid="job-title-field"
          />
          <InputField
            label="Email"
            :onChange="setEmail"
            :propValue="email"
            :isTextOk="$v.email.email ? null : false"
            :errorText="errorText"
            data-testid="email-field"
          />
        </div>
        <div class="pl-1 mt-3">
          <p class="text-sm-2 text-grey-light mb-1">User Roles</p>
          <div class="flex flex-row space-x-4">
            <div class="flex flex-row items-center">
              <Checkbox
                class="cursor-pointer"
                :active="employeeRole"
                @click.native="switchRole('employeeRole')"
                data-testid="employee-checkbox"
              />
              <p
                class="pl-1 cursor-pointer"
                @click="switchRole('employeeRole')"
              >
                Employee
              </p>
            </div>
            <div class="flex flex-row items-center">
              <Checkbox
                class="cursor-pointer"
                :active="managerRole"
                @click.native="switchRole('managerRole')"
                data-testid="manager-checkbox"
              />
              <p class="pl-1 cursor-pointer" @click="switchRole('managerRole')">
                Manager
              </p>
            </div>
            <div class="flex flex-row items-center">
              <Checkbox
                class="cursor-pointer"
                :active="adminRole"
                @click.native="switchRole('adminRole')"
                data-testid="admin-checkbox"
              />
              <p class="pl-1 cursor-pointer" @click="switchRole('adminRole')">
                Admin
              </p>
            </div>
          </div>
        </div>
        <div class="flex flex-row flex-grow justify-end mt-4">
          <Button
            type="tertiary"
            size="medium"
            text="Cancel"
            @click.native="closeModal"
            class="mr-2"
            data-testid="cancel-button"
          />
          <Button
            text="Save"
            type="primary"
            size="medium"
            @click.native="close"
            :disabled="!$v.email.email"
            data-testid="save-button"
          />
        </div>
      </div>
    </div>
    <div
      class="w-full h-full fixed z-30 bottom-0 bg-grey-dark-1 opacity-70 modal transition-all"
    />
  </div>
</template>

<script>
import store from "@/store";
import { mapActions, mapState } from "vuex";
import _forEach from "lodash/forEach";
import { email } from "vuelidate/lib/validators";

export default {
  name: "ManageUserModal",
  data: () => ({
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    employeeRole: false,
    managerRole: false,
    adminRole: false,
    sellerRole: false,
    errorText: "Invalid email address.",
  }),
  computed: {
    ...mapState({
      user: (state) => state.people.user,
    }),
  },
  validations: {
    email: {
      email,
    },
  },
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      edit: "people/edit",
    }),
    setFirstName(value) {
      this.firstName = value;
    },
    setLastName(value) {
      this.lastName = value;
    },
    setEmail(value) {
      this.email = value;
    },
    setJobTitle(value) {
      this.jobTitle = value;
    },
    switchRole(key) {
      this[key] = !this[key];
    },
    setInitialRoles(id) {
      switch (id) {
        case 1:
          return (this.sellerRole = true);
        case 2:
          return (this.adminRole = true);
        case 3:
          return (this.managerRole = true);
        case 4:
          return (this.employeeRole = true);
        default:
          return null;
      }
    },
    async close() {
      const roles = [];
      if (this.employeeRole) roles.push(4);
      if (this.managerRole) roles.push(3);
      if (this.adminRole) roles.push(2);
      if (this.sellerRole) roles.push(1);
      if (roles.length === 0) {
        store.dispatch(
          "alerts/showError",
          {
            messageHeader: "User Role required",
            message: "You must select at least one user role for this user.",
          },
          { root: true }
        );
      } else {
        const payload = {
          id: this.user.id,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          jobTitle: this.jobTitle,
          organizationId: this.orgId,
          roles,
        };
        await this.edit({ payload });
        this.$attrs.callback(true);
        this.closeModal();
      }
    },
  },
  mounted() {
    if (this.user) {
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
      this.email = this.user.email;
      this.jobTitle = this.user.jobTitle;
      _forEach(this.user.roles, (el) => {
        this.setInitialRoles(el.id);
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.modal {
  top: 0;
}
</style>
