<template>
  <div>
    <div
      class="w-full h-full fixed z-40 bottom-0 flex justify-center items-center modal"
      :style="{ visibility }"
    >
      <div
        class="w-modal bg-white p-4 shadow flex-grow-0"
        :class="showOverflow ? '' : 'overflow-hidden'"
      >
        <div class="flex">
          <p class="font-headers font-bold text-grey-dark-2 text-l mb-1.5">
            {{ data.title }}
          </p>
          <img
            v-if="hasCloseIcon"
            class="ml-auto mb-auto w-2 h-2 cursor-pointer"
            :src="close"
            @click="closeModal"
          />
        </div>
        <p class="font-main text-md text-blue-dark-4 mb-4">
          {{ data.content }}
        </p>
        <p class="font-main text-md text-blue-dark-4 mb-2" v-if="data.confirmationText">
          {{ data.confirmationText }}
        </p>
        <slot></slot>
      </div>
    </div>
    <div
      class="w-full h-full fixed z-30 bottom-0 bg-grey-dark-1 opacity-70 modal transition-all"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import close from "@/assets/img/icons/close-grey.svg";

export default {
  name: "Modal",
  props: {
    data: { default: null, type: Object },
    isVisible: {
      default: false,
      type: Boolean,
    },
    hasCloseIcon: { default: false, type: Boolean },
    showOverflow: { default: false, type: Boolean },
  },
  data: () => ({ close }),
  computed: {
    visibility: function() {
      return this.isVisible ? "block" : "hidden";
    },
  },
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
    }),
  },
};
</script>

<style lang="scss" scoped>
.modal {
  top: 0;
}
</style>
