<template>
  <div class="mb-2 w-full">
    <div class="flex mb-3 justify-between mt-3 rounded bg-white shadow py-0 mt-2 p-3 w-full">
      <div class="w-full">
        <div class="flex mb-2 justify-between items-center">
          <p class="font-barlow text-mdl font-bold text-grey-light mt-3 mb-1.5">
            Assessment Setup in Progress
          </p>
          <Button v-if="!this.extended && drafts.length > 3" class="mr-2" text="View more" type="secondary"
            :iconRight="arrow" size="medium" @click.native="goToAssessments" />
        </div>
        <div v-if="anyDrafts" class="px-2">
          <div class="border-grey-light-1 border-t mb-3">
            <div v-for="draft in draftItems" :key="draft.id"
              class="flex flex-row py-1.5 w-full border-b border-grey-light-1">
              <div class="flex flex-col">
                <p class="text-mdl font-semibold text-grey-dark-1">
                  {{ draft.name }}
                  {{ displayVersionForSeller(draft.productVersion)}}
                </p>
                <p class="text-sm-2 text-grey-light mt-0.4">
                  Created on {{ formatDate(draft.createdDate) }}
                </p>
              </div>
              <div class="items-center ml-auto">
                <p class="text-sm-2 text-grey-light mt-1 pt-0.4">
                  Last edited on {{ formatDate(draft.updated) }}
                </p>
              </div>
              <div>
                <Button text="Continue" class="ml-2" type="secondary" :iconRight="arrow" size="medium"
                  @click.native="continueSetup(draft)" />
              </div>
              <div>
                <IconButton class="ml-1" :icon="remove" :onClick="() => deleteDraft(draft)" />
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <p class="text-md text-grey-dark-1 w-5/6 ml-3">
            No assessments in progress.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import moment from "moment";
import Button from "../../../components/Button/Button.vue";
import IconButton from "../../../components/IconButton/IconButton.vue";
import arrow from "@/assets/img/icons/arrow-right.svg";
import remove from "@/assets/img/icons/removeBig.svg";

export default {
  components: { Button, IconButton },
  name: "DraftAssessments",
  props: {
    extended: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({ arrow, remove }),
  methods: {
    ...mapActions({
      continueDraft: "assessmentSetup/continueDraft",
      setAssessmentTitle: "assessmentSetup/setAssessmentTitle",
      setFlowMode: "assessmentSetup/setFlowMode",
      setShouldEdit: "assessmentSetup/setShouldEdit",
      openModal: "modals/openModal"
    }),
    formatDate(date) {
      return moment(date).format("ddd, D MMM YYYY");
    },
    displayVersionForSeller(version) {
      if (version &&  version !== "" && (this.isImpersonating || this.isSeller)) 
        return " (v" + version + ")";
      return "";
    },
    async continueSetup(draft) {
      this.setFlowMode("set-up");
      this.setShouldEdit(false);
      this.setAssessmentTitle({ title: draft.name });
      await this.continueDraft({ id: draft.id });
      this.$router.push("/assessments/general-information");
    },
    async deleteDraft(draft) {
      this.openModal({
        modal: "deleteDraftAssessmentModal",
        props: { id: draft.id },
      });
    },
    goToAssessments() {
      this.$router.push("/assessments");
    },
  },
  computed: {
    ...mapState({
      drafts: (state) => state.assessmentSetup.draftSurveys,
    }),
    ...mapGetters({
      isImpersonating: "auth/isImpersonating",
      isSeller: "auth/isSeller",
    }),
    draftItems() {
      this.drafts.sort((a, b) => b.createdDate.localeCompare(a.createdDate));
      if (!this.extended) {
        return this.drafts.slice(0, 3);
      }
      return this.drafts;
    },
    anyDrafts() {
      return this.drafts.length > 0;
    },
  },
};
</script>

<style></style>
