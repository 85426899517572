<template>
  <Modal :data="$data" :isVisible="visible">
    <p class="font-main text-md text-blue-dark-4 mb-5.5">
      Are you sure you want to remove this Competency?
    </p>
    <div class="flex flex-row flex-grow justify-end">
      <Button
        type="tertiary"
        size="medium"
        text="Close"
        @click.native="closeModal"
        class="mr-2"
      />
      <Button
        type="danger"
        text="Remove"
        size="medium"
        @click.native="removeCompetency"
        class="px-3"
      />
    </div>
  </Modal>
</template>
<script>
import { mapActions, mapState } from "vuex";

import { deleteCompetency } from "../../../services/api/products";

export default {
  name: "DeleteCompetencyModal",
  props: ["visible", "id"],
  data: () => ({
    title: "Remove Competency",
    content:
      "Deleting this competency means it will be permanently removed from the current assessment draft.",
  }),
  computed: {
    ...mapState({
      productDetails: (state) => state.products.productDetails,
    }),
  },
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      getProductById: "products/getProductById",
    }),
    async removeCompetency() {
      await deleteCompetency(this.id).then(
        async () =>
          await this.getProductById({ id: this.productDetails.productId })
      );
      this.closeModal();
    },
  },
};
</script>
