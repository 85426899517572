<template>
  <div :class="!disabled ? 'hoverbox' : null">
    <p
      v-if="hasLabel"
      class="text-sm-2 font-medium text-grey-light mb-0.4 hovertext"
    >
      {{ label }}
    </p>
    <multiselect
      v-model="selectedValue"
      :options="options"
      :searchable="searchable"
      :allow-empty="false"
      @select="onSelect"
      :showLabels="false"
      :disabled="disabled"
      label="label"
      :preselectFirst="preselectFirst"
      :openDirection="openDirection"
    >
      <template slot="caret">
        <img
          class="multiselect__select"
          src="../../assets/img/icons/caret.svg"
        />
      </template>
      <template slot="singleLabel" slot-scope="props"
        ><div class="flex flex-row items-center">
          <img v-if="hasLeftIcon" :src="leftIcon" class="mr-2" />
          <div
            v-if="props.option.color"
            class="h-1 w-3 rounded mr-1"
            :style="{ backgroundColor: props.option.color }"
          />
          <p>{{ props.option.label }}</p>
        </div>
      </template>
      <template slot="option" slot-scope="props">
        <div class="flex flex-row items-center">
          <div
            v-if="props.option.color"
            class="h-1 w-3 rounded mr-1"
            :style="{ backgroundColor: props.option.color }"
          />
          <p :class="props.option.class">{{ props.option.label }}</p>
        </div>
      </template>
    </multiselect>
  </div>
</template>
<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
Vue.component("multiselect", Multiselect);
export default {
  name: "NewSelect",
  data: () => ({
    selectedValue: null,
  }),
  props: {
    options: { type: Array, default: null },
    value: { type: Object, default: null },
    onSelect: { type: Function, default: () => null },
    disabled: { type: Boolean, default: false },
    preselectFirst: { type: Boolean, default: true },
    label: { type: String, default: "Label" },
    hasLabel: { type: Boolean, default: true },
    searchable: { type: Boolean, default: false },
    openDirection: { type: String, default: "" },
    leftIcon: { default: null, type: String },
    hasLeftIcon: { type: Boolean, default: false },
  },
  watch: {
    value(val) {
      this.selectedValue = val;
    },
  },
  mounted() {
    if (this.value) {
      this.selectedValue = this.value;
    }
  },
};
</script>

<style lang="scss">
.hoverbox:hover {
  .hovertext {
    color: #00a1d5;
  }
  .multiselect .multiselect__tags {
    border: 1px solid #00a1d5;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
}
.multiselect {
  color: #35495e;
  &__tags {
    border: 1px solid #d0dae3;
    cursor: pointer;
    .multiselect__placeholder {
      color: #b1bfcd;
      padding-left: 8px;
    }
  }
  &:focus {
    outline: none;
    .multiselect__tags {
      border: 1px solid #00a1d5;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
  }
  &.multiselect--above {
    .multiselect__content-wrapper {
      border: 1px solid #00a1d5;
    }
  }
}
.multiselect__content-wrapper {
  border: 1px solid #00a1d5;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.multiselect__input,
.multiselect__single {
  font-weight: 500;
  color: #022f59;
  font-size: 14px;
}
.multiselect__placeholder {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0px;
  padding-top: 0px;
}
.multiselect__select {
  right: 1px;
  top: 1px;
  padding: 6px 12px;
}

.multiselect__option {
  font-weight: 500;
  font-size: 14px;
}
.multiselect__option--highlight {
  background: #e4ebf1;
  color: #022f59;
  outline: none;
  font-weight: 500;
}

.multiselect__option--selected {
  background: #e0eef9;
  color: #00a1d5;
  font-weight: 500;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #e4ebf1;
  color: #00a1d5;
  font-weight: 500;
}
.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: #fff;
  color: #748fa8;
}
</style>
