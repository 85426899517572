<template>
  <div class="flex flex-row items-center w-full py-2 pl-2">
    <p v-if="hasSubcomp" class="text-md text-grey-dark-1 font-bold w-1/4">
      {{ item.parentCompetencyName }}
    </p>
    <p
      class="text-md text-grey-dark-1"
      :class="hasSubcomp ? 'w-1/4' : 'font-bold w-1/2'"
    >
      {{ item.name }}
    </p>
    <div class="flex w-1/6 justify-center">
      <p class="text-md text-grey-dark-2">
        {{ item.score.toFixed(2) }}
      </p>
    </div>
    <div class="flex w-1/6 justify-center">
      <p class="text-md text-grey-dark-2">{{ item.target.toFixed(2) }}</p>
    </div>
    <div class="flex flex-row items-center w-1/6 justify-center">
      <p class="text-md text-grey-dark-2">
        {{ item.delta > 0 ? "+" : "" }}{{ item.delta }}
      </p>
      <div
        class="w-10 h-10 rounded-full ml-1"
        :style="`background-color: ${item.color}`"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "TableItemPreview",
  props: {
    item: { type: Object, default: null },
    type: { type: String, default: null },
  },
  computed: {
    hasSubcomp: function () {
      return this.type === "subcompetency";
    },
  },
};
</script>
