require('dotenv').config();
import Vue from 'vue';
import App from './App.vue';
import './assets/tailwind.css';
import router from './router';
import store from './store';
import Button from './components/Button/Button';
import Answer from './components/Answer/Answer';
import Modal from './components/Modal/Modal';
import SmallInfoCard from './components/miscellaneous/SmallInfoCard';
import RichText from './components/RichText/TipTap.vue';
import LineBarChart from './components/Charts/LineBarChart.vue';
import MultiChart from './components/Charts/MultiChart.vue';
import InputField from './components/InputField/InputField.vue';
import Checkbox from './components/Checkbox';
import Select from './components/Select/NewSelect.vue';
import TabContainer from './components/TabContainer/TabContainer.vue';
import Tab from './components/TabContainer/Tab.vue';
import VuePapaParse from 'vue-papa-parse';
import Vuelidate from 'vuelidate';
import FusionCharts from 'fusioncharts';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import '../src/plugins/index';
import { discardTooltip, showTooltip } from '../src/plugins/clampTooltip';

FusionCharts.options.license({
  key: process.env.VUE_APP_FUSION_CHART_KEY,
  creditLabel: false,
});

Vue.config.productionTip = false;

Vue.use(VuePapaParse);
Vue.use(Vuelidate);

Vue.component('date-picker', DatePicker);
Vue.component('Button', Button);
Vue.component('Answer', Answer);
Vue.component('RTF', RichText);
Vue.component('Modal', Modal);
Vue.component('Select', Select);
Vue.component('LineBarChart', LineBarChart);
Vue.component('Tab', Tab);
Vue.component('TabContainer', TabContainer);
Vue.component('InputField', InputField);
Vue.component('Checkbox', Checkbox);
Vue.component('SmallInfoCard', SmallInfoCard);
Vue.component('MultiChart', MultiChart);
Vue.directive('click-outside', {
  priority: 700,
  bind(el, _, vnode) {
    vnode.event = () => {
      if (vnode.context.visibleDropdown) vnode.context.closeDropdown();
    };
    el.addEventListener('click', function(e) {
      e.stopPropagation();
    });
    document.body.addEventListener('click', vnode.event);
  },

  unbind(el, vnode) {
    el.removeEventListener('click', function(e) {
      e.stopPropagation();
    });
    document.body.removeEventListener('click', vnode.event);
  },
});
Vue.directive('clamp', {
  // THIS DIRECTIVE USES PARENT ELEMENT AS HOVER ZONE, MAKE SURE WE USE APPROPRIATE HEIGHT/PADDING
  bind(el, binding, vnode) {
    el.style.overflow = 'hidden';
    el.style.position = 'relative';
    el.innerHTML = el.innerText;
    el.innerHTML = el.innerHTML.replace(/(\s)([\S])[\s]+/g, '$1$2&nbsp;');
    const originalText = el.innerHTML;
    if (el.innerHTML.length >= (binding.value || 20)) {
      vnode.context.$nextTick(() => {
        const parent = el.parentElement;
        parent.addEventListener('mouseenter', () =>
          showTooltip(el, vnode, originalText)
        );
        parent.addEventListener('mouseleave', () => discardTooltip(vnode));
      });
    }
  },
  inserted(el, binding) {
    el['originalText-attribute'] = el.innerHTML;
    let threshold = binding.value || 20;
    setTimeout(() => {
      if (el.innerHTML.length >= threshold) {
        el.innerHTML = el.innerHTML.slice(0, threshold);
        el.innerHTML += '\u2026';
      }
    }, 0);
  },
  componentUpdated(el, binding) {
    let threshold = binding.value || 20;
    setTimeout(() => {
      if (el['originalText-attribute'].length >= threshold) {
        el.innerHTML = el['originalText-attribute'].slice(0, threshold);
        el.innerHTML += '\u2026';
      }
    }, 0);
  },
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
