import { downloadTeamSurvey, exportSurveyData } from "@/services/api/teamResults";
import { exportUsers } from "@/services/api/companies";
import { exportPeople } from '../../../services/api/surveys';
import {
  fetchCompanyAssessments,
  fetchTeamsForAssessment,
  fetchAssessmentResults,
  fetchSurveyStats,
  fetchJobRoles,
  fetchMembersBySurvey,
  fetchProgress,
} from "@/services/api/companyResults";
import downloadFileFromBlob from '../../../services/utils/downloadFileFromBlob';
import {
  fetchSurvey,
  fetchCompetencyLevels,
  releaseResults
} from "@/services/api/surveys";
import invited from "@/assets/img/stats/invited-colored.svg";
import completed from "@/assets/img/stats/completed-colored.svg";
import inProgress from "@/assets/img/stats/inProgress-colored.svg";
import notStarted from "@/assets/img/stats/notStarted-colored.svg";
import completionRate from "@/assets/img/stats/completionRate-colored.svg";

import invitedGrey from "@/assets/img/stats/invited.svg";
import completedGrey from "@/assets/img/stats/completed.svg";
import inProgressGrey from "@/assets/img/stats/inProgress.svg";
import notStartedGrey from "@/assets/img/stats/notStarted.svg";
import completionRateGrey from "@/assets/img/stats/completionRate.svg";

import forEach from "lodash/forEach";
import moment from "moment";

const getDefaultState = () => ({
  assessments: [],
  teams: [],
  jobRoles: [],
  selectedAssessment: null,
  surveyStats: null,
  assessment: null,
  competencyResults: null,
  subcompetencyResults: null,
  subcompetencyLegend: null,
  teamProgress: [],
  teamProgressTotalCount: 0,
  surveyMembers: [],
});

const actions = {
  resetCompanyResults({ commit }) {
    return commit("resetState");
  },
  getCompanyAssessments({ commit }) {
    return fetchCompanyAssessments().then((rsp) => {
      const response = rsp.data.data;
      commit("SET_ASSESSMENTS", response);
    });
  },
  getTeamsForAssessment({ commit }, { id }) {
    return fetchTeamsForAssessment(id).then((rsp) => {
      const response = rsp.data.data;
      const sortedArray = response.sort((a, b) => {
        const aValue = a.name.toLowerCase();
        const bValue = b.name.toLowerCase();
        if (aValue > bValue) return 1;
        if (aValue < bValue) return -1;
      });
      commit("SET_TEAMS", sortedArray);
    });
  },
  getMembersOfSurvey({ commit }, { teamId, surveyId, jobRoleId }) {
    const jobId = jobRoleId === 0 ? null : jobRoleId;
    const team = teamId === -1 ? null : teamId;
    return fetchMembersBySurvey(team, surveyId, jobId).then((rsp) => {
      const response = rsp.data.data;
      const sortedArray = response.sort((a, b) => {
        const aValue = a.firstName.toLowerCase();
        const bValue = b.firstName.toLowerCase();
        if (aValue > bValue) return 1;
        if (aValue < bValue) return -1;
      });
      commit("SET_SURVEY_MEMBERS", sortedArray);
    });
  },
  downloadTeamPdf(
    _,
    { id, teamId, jobId, userId, competencyImg, subcompetencyImg, competencySorting, subcompetencySorting }
  ) {
    downloadTeamSurvey(
      id,
      teamId,
      jobId,
      userId,
      competencyImg,
      subcompetencyImg,
      competencySorting,
      subcompetencySorting,
    ).then((rsp) => {
      const file = new Blob([rsp.data], { type: "application/pdf" });
      const filename = "Results.pdf";
      const data = URL.createObjectURL(file);
      const a = document.createElement("a");
      a.href = data;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    });
  },
  exportTeamXLS(
    _,
    { id, teamId, jobId, userId }
  ) {
    exportSurveyData(
      id,
      teamId,
      jobId,
      userId
    ).then((rsp) => {
      const file = new Blob([rsp.data], { type: "application/xlsx" });
      const filename = "Results.xlsx";
      const data = URL.createObjectURL(file);
      const a = document.createElement("a");
      a.href = data;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    });
  },
  exportUsers(
    _,
    { organizationId, status }
  ) {
    exportUsers(
      organizationId,
      status
    ).then((rsp) => {
      const file = new Blob([rsp.data], { type: "application/csv" });
      downloadFileFromBlob(file, "Users.csv");
    });
  },
  exportSurveyPeople(
    _,
    { surveyId, status }
  ) {
    exportPeople(
      surveyId,
      status
    ).then((rsp) => {
      const file = new Blob([rsp.data], { type: "application/xlsx" });
      downloadFileFromBlob(file, "People.csv");
    });
  },
  async getAssessment({ commit }, { id }) {
    const rspAssessment = await fetchSurvey(id);
    const assessment = rspAssessment.data.data;
    const closingTime = moment(assessment.closingTime);
    const now = moment();
    let resultsPublished = null;
    if (assessment.areResultsReleased !== null) {
      resultsPublished = assessment.areResultsReleased;
    }
    const mappedAssessment = {
      currentQuestion: assessment.answeredQuestionsCount + 1,
      inProgress: assessment.answeredQuestionsCount !== 0,
      title: assessment.name,
      launchedOn:
        assessment.openingTime &&
        moment(assessment.openingTime).format("ddd, MMM D, YYYY"),
      dueOn:
        assessment.closingTime &&
        moment(assessment.closingTime).format("ddd, D MMM YYYY hh:mm A") + " UTC",
      completedOn:
        assessment.completionDate &&
        moment(assessment.completionDate).format("ddd, MMM D, YYYY"),
      id: assessment.id,
      totalQuestions: assessment.questionsCount,
      introText: assessment.surveyMessages.find(
        (el) => el.surveyMessageType === 10
      ).message,
      outroText: assessment.surveyMessages.find(
        (el) => el.surveyMessageType === 11
      ).message,
      inProgress: now < closingTime,
      resultsPublished,
      surveyType: assessment.surveyType,
      productVersion: assessment.productVersion
    };

    const rspCompetencyLevels = await fetchCompetencyLevels(id);
    mappedAssessment.competencyLevels = rspCompetencyLevels.data.data.map((item) => {
      return {
        score: item.score,
        name: item.name
      }
    });
    commit("SET_ASSESSMENT", mappedAssessment);
  },
  getSurveyStats({ commit, getters }, { surveyId, teamId, jobRoleId }) {
    const jobId = jobRoleId === 0 ? null : jobRoleId;
    fetchSurveyStats(surveyId, teamId, jobId).then((rsp) => {
      const { data } = rsp.data;
      const stats = [
        {
          text: "Invited",
          number: data.invitedCount,
          icon: getters.isAssessmentInProgress ? invited : invitedGrey,
          color: !getters.isAssessmentInProgress ? "E0EEF9" : "#E0EEF9",
        },
        {
          text: "Completed",
          number: data.completedCount,
          icon: getters.isAssessmentInProgress ? completed : completedGrey,
          color: !getters.isAssessmentInProgress ? "E0EEF9" : "#E7F7DB",
        },
        {
          text: "In Progress",
          number: data.inProgressCount,
          icon: getters.isAssessmentInProgress ? inProgress : inProgressGrey,
          color: !getters.isAssessmentInProgress ? "E0EEF9" : "#DCEBFD",
        },
        {
          text: "Not Started",
          number: data.notStartedCount,
          icon: getters.isAssessmentInProgress ? notStarted : notStartedGrey,
          color: !getters.isAssessmentInProgress ? "E0EEF9" : "#F8DBDC",
        },
        {
          text: "Completion Rate",
          number: `${parseInt(data.completionRate, 10)} %`,
          icon: getters.isAssessmentInProgress
            ? completionRate
            : completionRateGrey,
          color: !getters.isAssessmentInProgress ? "E0EEF9" : "#EAD8F9",
        },
      ];
      commit("SET_SURVEY_STATS", stats);
    });
  },
  getAssessmentCompetencyResults({ commit }, { id, teamId, jobId, userId }) {
    const jobRoleId = jobId === 0 ? null : jobId;
    const employeeId = userId === 0 ? null : userId;
    fetchAssessmentResults(
      id,
      teamId,
      "competency",
      jobRoleId,
      employeeId
    ).then((rsp) => {
      const results = rsp.data.data;
      const subCompLegend = [];
      const categories = [];
      const targetData = [];
      const avgTargetData = [];
      const scoreData = [];
      const tableData = [];
      forEach(results, (result) => {
        subCompLegend.push({
          label: result.name,
          color: result.color,
        });
        categories.push({
          label: result.name,
        });
        targetData.push({
          value: result.target,
        });
        avgTargetData.push({
          value: result.averageScore,
        });
        scoreData.push({
          value: result.score,
          color: result.color,
        });
        tableData.push({
          competency: result.name,
          actual: result.score,
          target: result.target,
        });
      });
      const lineData = {
        seriesName: "Target per Year",
        renderAs: "line",
        color: "#F04E23",
        data: targetData,
      };
      const lineData2 = {
        seriesName: "Average Team Assessment Score",
        renderAs: "line",
        color: "#D0DAE3",
        data: avgTargetData,
      };
      const barData = {
        renderAs: "bar",
        seriesName: "Assessment Results",
        data: scoreData,
      };
      commit("SET_COMPETENCY_RESULTS", {
        categories,
        lineData,
        lineData2,
        barData,
        tableData,
      });
      commit("SET_SUBCOMPETENCY_LEGEND", subCompLegend);
    });
  },
  getAssessmentSubcompetencyResults({ commit }, { id, teamId, jobId, userId }) {
    const jobRoleId = jobId === 0 ? null : jobId;
    const employeeId = userId === 0 ? null : userId;
    fetchAssessmentResults(
      id,
      teamId,
      "subcompetency",
      jobRoleId,
      employeeId
    ).then((rsp) => {
      const results = rsp.data.data;
      const categories = [];
      const targetData = [];
      const avgTargetData = [];
      const scoreData = [];
      const tableData = [];
      forEach(results, (result) => {
        categories.push({
          label: result.name,
          category: result.parentCompetencyName,
        });
        targetData.push({
          value: result.target,
        });
        avgTargetData.push({
          value: result.averageScore,
        });
        scoreData.push({
          value: result.score,
          color: result.color,
        });
        tableData.push({
          subcompetency: result.name,
          competency: result.parentCompetencyName,
          actual: result.score,
          target: result.target,
        });
      });
      const lineData = {
        seriesName: "Target per Year",
        renderAs: "line",
        color: "#F04E23",
        data: targetData,
      };
      const lineData2 = {
        seriesName: "Average Team Assessment Score",
        renderAs: "line",
        color: "#D0DAE3",
        data: avgTargetData,
      };
      const barData = {
        renderAs: "bar",
        seriesName: "Assessment Results",
        data: scoreData,
      };
      commit("SET_SUBCOMPETENCY_RESULTS", {
        categories,
        lineData,
        lineData2,
        barData,
        tableData,
      });
    });
  },
  getJobRoles({ commit }, { surveyId, teamId }) {
    fetchJobRoles(surveyId, teamId).then((rsp) => {
      const response = rsp.data.data;
      const sortedArray = response.sort((a, b) => {
        const aValue = a.name.toLowerCase();
        const bValue = b.name.toLowerCase();
        if (aValue > bValue) return 1;
        if (aValue < bValue) return -1;
      });
      commit("SET_JOBROLES", sortedArray);
    });
  },
  publishResults({ dispatch }, { surveyId }) {
    return releaseResults(surveyId).then(() => {
      dispatch("getAssessment", { id: surveyId });
    });
  },
  getProgress({ commit }, { surveyId, pageSz, pageNr, keyword, sorting, status }) {
    return fetchProgress(surveyId, {
      pageNumber: pageNr,
      pageSize: pageSz,
      keyword: keyword,
      status,
      sorting,
    }).then((rsp) => {
      const surveyStatus = (startDate, completionDate) => {
        if (completionDate) return "COMPLETED";
        if (startDate) return "IN_PROGRESS";
        return "NOT_STARTED";
      };
      const data = rsp.data.data.map((person) => ({
        name: `${person.firstName} ${person.lastName}`,
        id: person.id,
        status: surveyStatus(person.startedAt, person.completedAt),
        userStatus: person.userStatus,
        jobRoleName: person.jobRoleName ? person.jobRoleName : "",
        teams: person.teams,
      }));
      commit("SET_TEAM_PROGRESS", data);
      commit("SET_TEAM_PROGRESS_TOTAL_COUNT",  rsp.data.totalCount);
    });
  },
};
const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  SET_SURVEY_STATS(state, stats) {
    state.surveyStats = stats;
  },
  SET_JOBROLES(state, roles) {
    state.jobRoles = roles;
  },
  SET_ASSESSMENTS(state, assessments) {
    state.assessments = assessments;
  },
  SET_TEAMS(state, teams) {
    state.teams = teams;
  },
  SET_COMPETENCY_RESULTS(state, results) {
    state.competencyResults = results;
  },
  SET_SUBCOMPETENCY_RESULTS(state, results) {
    state.subcompetencyResults = results;
  },
  SET_SUBCOMPETENCY_LEGEND(state, legend) {
    state.subcompetencyLegend = legend;
  },
  SET_ASSESSMENT(state, assessment) {
    state.assessment = assessment;
  },
  SET_TEAM_PROGRESS(state, teamProgress) {
    state.teamProgress = teamProgress;
  },
  SET_TEAM_PROGRESS_TOTAL_COUNT(state, teamProgressTotalCount) {
    state.teamProgressTotalCount = teamProgressTotalCount;
  },
  SET_SURVEY_MEMBERS(state, payload) {
    state.surveyMembers = payload;
  },
};

const getters = {
  isAssessmentInProgress: (state) =>
    state.assessment && state.assessment.inProgress,
  filteredTeam: (state) => (filter, keyword) => {

    keyword = keyword.trim().toLowerCase()

    const filterByKeyword = (el) => (!keyword || 
      el.name.trim().toLowerCase().includes(keyword) || 
      el.teams?.map(x => x.name).join(' ').trim().toLowerCase().includes(keyword) || 
      el.jobRoleName?.trim().toLowerCase().includes(keyword));
      
    if (filter === "ALL") return state.teamProgress.filter(filterByKeyword);
    return state.teamProgress.filter((el) => el.status === filter && filterByKeyword(el));
  },
  getMemberDetails: (state) => (id) =>
    state.surveyMembers.find((el) => el.id === id),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
