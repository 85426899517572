<template>
  <div>
    <Modal :data="$data" :isVisible="visible">
      <div class="flex flex-row flex-grow justify-end mt-4 space-x-2">
        <Button
          type="tertiary"
          size="medium"
          text="Cancel"
          @click.native="closeModal"
        />
        <Button
          :text="buttonText"
          type="primary"
          size="medium"
          @click.native="launch"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "CompleteSetupModal",
  data: () => ({
    title: "Complete Set-up",
    content:
      "Are you sure you want to Complete Set-up? The assessment can't be edited after this step.",
  }),
  props: ["visible", "id"],
  computed: {
    ...mapState({
      mode: (state) => state.assessmentSetup.mode,
    }),
    ...mapGetters({
      isSeller: "auth/isSeller",
    }),
    buttonText() {
      return this.mode === "set-up" ? "Complete Set-up" : "Update";
    },
  },
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      launchSurvey: "assessmentSetup/launchSurvey",
    }),
    async launch() {
      const res = await this.launchSurvey({ id: this.id });
      if (res) {
        if (!this.isSeller) this.$router.push("/assessments");
        else this.$router.push(`/company/${this.isSeller}/assessments`);
      }
      this.closeModal();
    },
  },
  mounted() {
    if (this.mode !== "set-up") {
      this.title = "Update Assessment";
      this.content = "Are you sure you want to update the assessment?";
    }
  },
};
</script>
