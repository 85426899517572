import questions from "./mocked";
import moment from "moment";
import {
  fetchSurveys,
  fetchSurvey,
  beginSurvey,
  answerQuestion,
  prevQuestion,
} from "@/services/api/surveys";
import { canViewResults } from "@/services/utils/assessments";
import { getCompletedAssessments } from "@/services/api/dashboard";
import router from "@/router";

const getDefaultState = () => ({
  assessments: [],
  selectedAssessment: null,
  results: [],
  question: null,
  questions,
  step: 0,
});

const actions = {
  resetAssessmentsState({ commit }) {
    commit("resetState");
  },
  getSurvey({ commit }, { id }) {
    return fetchSurvey(id).then((rsp) => {
      const assessment = rsp.data.data;
      const mappedAssessment = {
        currentQuestion: assessment.answeredQuestionsCount + 1,
        inProgress: assessment.answeredQuestionsCount !== 0 && !assessment.isClosed,
        title: assessment.name,
        launchedOn: moment(assessment.openingTime).format("ddd, MMM D, YYYY"),
        dueOn: moment(assessment.closingTime).format("ddd, MMM D, YYYY"),
        id: assessment.id,
        introImageId: assessment.introImageId,
        outroImageId: assessment.outroImageId,
        closedImageId: assessment.closedImageId,
        isClosed: assessment.isClosed,
        totalQuestions: assessment.questionsCount,
        timeAllocation: assessment.maxTimeAllocation,
        allowingGoingBack: assessment.allowGoingBack,
        allowInMultipleSessions: assessment.allowInMultipleSessions,
        introText: assessment.surveyMessages.find(
          (el) => el.surveyMessageType === 10
        ).message,
        outroText: assessment.surveyMessages.find(
          (el) => el.surveyMessageType === 11
        ).message,
        closedText: assessment.surveyMessages.find(
          (el) => el.surveyMessageType === 12
        )?.message,
      };
      commit("SET_SELETED_ASSESSMENT", mappedAssessment);
    });
  },
  getSurveys({ commit }) {
    fetchSurveys().then((rsp) => {
      const assessments = rsp.data.data.map((assessment) => ({
        currentQuestion: assessment.answeredQuestionsCount + 1,
        inProgress: assessment.answeredQuestionsCount !== 0 && !assessment.isClosed,
        title: assessment.name,
        launchedOn: moment(assessment.openingTime).format("ddd, MMM D, YYYY"),
        dueOn: moment(assessment.closingTime).format("llll") + " UTC",
        id: assessment.id,
        totalQuestions: assessment.questionsCount,
        parentSurveyId: assessment.parentSurveyId,
        surveyType: assessment.surveyType,
        backgroundColor: assessment.backgroundColor,
        isClosed: assessment.isClosed,
        hasExpired: assessment.hasExpired
      }));
      commit("SET_ASSESSMENTS", assessments);
    });
  },
  startSurvey({ commit }, { id }) {
    let step = 0;
    return beginSurvey(id).then((rsp) => {
      const { data } = rsp.data;
      const question = {
        question: data.question.text,
        id: data.question.id,
        description: data.question.description,
        answers: [...data.question.responses],
        currentQuestion: data.questionIndex,
        totalQuestions: data.questionsCount,
        startedAt: data.startedAt,
      };
      commit("SET_QUESTION", question);
      commit("SET_STEP", step);
    });
  },
  surveyForward({ commit }, { id, questionId, answerId }) {
    return answerQuestion(id, questionId, answerId).then((rsp) => {
      if (rsp.data.data.isSurveyCompleted) {
        return router.push(`/assessment/${id}/survey/end`);
      }
      const { data } = rsp.data;
      const question = {
        question: data.question.text,
        id: data.question.id,
        description: data.question.description,
        answers: [...data.question.responses],
        currentQuestion: data.questionIndex,
        totalQuestions: data.questionsCount,
      };
      commit("SET_QUESTION", question);
      return question;
    });
  },
  surveyBackwards({ commit }, { id, currentQuestionId }) {
    return prevQuestion(id, currentQuestionId).then((rsp) => {
      if (rsp.data.data.isSurveyCompleted) {
        return router.push(`/assessment/${id}/survey/end`);
      }
      const { data } = rsp.data;
      const question = {
        question: data.question.text,
        id: data.question.id,
        description: data.question.description,
        answers: [...data.question.responses],
        currentQuestion: data.questionIndex,
        totalQuestions: data.questionsCount,
      };
      commit("SET_QUESTION", question);
      return question;
    });
  },
  getDashboardResults({ commit }) {
    return getCompletedAssessments().then((rsp) => {
      const { data } = rsp.data;
      const results = data.map((result) => ({
        title: result.name,
        launchedOn: moment(result.openingTime).format("MMM D, YYYY"),
        completedOn: moment(result.completedAt).format("MMM D, YYYY"),
        id: result.id,
        canViewResults: canViewResults(
          result.resultsPermission,
          result.surveyPermission,
          result.closingTime
        ),
      }));
      commit("SET_RESULTS", results);
    });
  },
  surveyBack({ commit, state }) {
    if (state.step > 0) {
      commit("DECREMENT_STEP");
    }
  },
};

const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  SET_STEP(state, step) {
    state.step = step;
  },
  INCREMENT_STEP(state) {
    state.step += 1;
  },
  SET_ASSESSMENTS(state, assessments) {
    state.assessments = assessments;
  },
  SET_SELETED_ASSESSMENT(state, assessment) {
    state.selectedAssessment = assessment;
  },
  SET_QUESTION(state, question) {
    state.question = question;
  },
  SET_IN_PROGRESS(state, { id }) {
    state.assessments.find(
      (assessment) => assessment.id === parseInt(id, 10)
    ).inProgress = true;
  },
  SET_LAUNCH_DATE(state, { id }) {
    state.assessments.find(
      (assessment) => assessment.id === parseInt(id, 10)
    ).launchedOn = moment().format("ddd, MMM Do YYYY");
  },
  SET_COMPLETION_DATE(state, { id }) {
    state.assessments.find(
      (assessment) => assessment.id === parseInt(id, 10)
    ).completedOn = moment().format("MMM Do YYYY");
  },
  SET_DONE(state, { id }) {
    state.assessments.find(
      (assessment) => assessment.id === parseInt(id, 10)
    ).done = true;
  },
  DECREMENT_STEP(state) {
    state.step -= 1;
  },
  SET_RESULTS(state, results) {
    state.results = results;
  },
  ADD_ASSESSMENT_ON_RESULTS(state, { id }) {
    const assessment = state.assessments.find(
      (assessment) => assessment.id === parseInt(id, 10)
    );
    state.results.unshift(assessment);
  },
};

const generatePercentages = (assessment) => {
  if (assessment.currentQuestion !== 0) {
    const remainingTotal =
      assessment.totalQuestions - assessment.currentQuestion;
    const percentageTotal = parseInt(
      100 - (remainingTotal / assessment.totalQuestions) * 100,
      10
    );
    const remaining =
      assessment.totalQuestions - assessment.currentQuestion + 1;
    const percentage = parseInt(
      100 - (remaining / assessment.totalQuestions) * 100,
      10
    );
    const results = {
      total: `${percentageTotal}%`,
      remaining: `${percentage}%`,
    };
    return results;
  } else {
    return {
      total: "0%",
      remaining: "100%",
    };
  }
};

const getters = {
  getAssessmentById: (state) => (id) =>
    state.assessments.find((assessment) => assessment.id === parseInt(id, 10)),
  getAssessmentTitle: (state) => state.selectedAssessment.title,
  getAssessmentIntro: (state) => state.selectedAssessment.introText,
  getAssessmentOutro: (state) => state.selectedAssessment.outroText,
  getAssessmentClosed: (state) => state.selectedAssessment.closedText,
  getAssessmentCompletion: (_, getters) => (id) => {
    const assessment = getters.getAssessmentById(id);
    return generatePercentages(assessment);
  },
  getSelectedAssessmentCompletion: (state) => (flag) => {
    const data = !flag ? state.selectedAssessment : state.question ? state.question : state.selectedAssessment;
    return generatePercentages(data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
