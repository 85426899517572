const getDefaultState = () => ({
  modalVisible: false,
  modalComponent: null,
  props: null,
});

const actions = {
  resetCallsState({ commit }) {
    commit("resetState");
  },
  openModal({ commit }, { modal, props }) {
    commit("SET_MODAL_COMPONENT", modal);
    commit("SET_PROPS", props);
    commit("SET_MODAL_VISIBLE");
  },
  closeModal({ commit }) {
    commit("SET_MODAL_COMPONENT", null);
    commit("SET_PROPS", null);
    commit("SET_MODAL_INVISIBLE");
  },
};

const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  SET_MODAL_COMPONENT(state, modal) {
    state.modalComponent = modal;
  },
  SET_MODAL_VISIBLE(state) {
    state.modalVisible = true;
  },
  SET_PROPS(state, props) {
    state.props = props;
  },
  SET_MODAL_INVISIBLE(state) {
    state.modalVisible = false;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
