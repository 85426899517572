var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inputContainer"},[_c('p',{staticClass:"text-sm-2 text-grey-light font-medium mb-0.4 relative",class:[
      _vm.isTextOk !== null && 'text-red' ]},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{staticClass:"flex items-center flex-grow relative"},[(_vm.leftIcon)?_c('img',{staticClass:"absolute left-1.5",attrs:{"src":require("../../assets/img/icons/search.svg")}}):_vm._e(),((_vm.fieldType)==='checkbox')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],staticClass:"w-full outline-none placeholder-grey-medium-2 font-medium text-md text-grey-dark-1 px-2 py-1 border rounded focus:border-blue-dark-1 disabled:text-grey-light disabled:placeholder-grey-light-1 disabled:border-grey-light-1 disabled:bg-white",class:[
        _vm.colorClass,
        _vm.leftIcon ? 'pl-5' : null,
        !_vm.disabled ? 'inputField' : null ],attrs:{"placeholder":_vm.placeholder,"maxlength":_vm.maxlength,"disabled":_vm.disabled,"readonly":_vm.readonly,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.value)?_vm._i(_vm.value,null)>-1:(_vm.value)},on:{"input":function($event){return _vm.onChange(_vm.value)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit.apply(null, arguments)},"change":function($event){var $$a=_vm.value,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.value=$$a.concat([$$v]))}else{$$i>-1&&(_vm.value=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.value=$$c}}}},'input',_vm.$attrs,false)):((_vm.fieldType)==='radio')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],staticClass:"w-full outline-none placeholder-grey-medium-2 font-medium text-md text-grey-dark-1 px-2 py-1 border rounded focus:border-blue-dark-1 disabled:text-grey-light disabled:placeholder-grey-light-1 disabled:border-grey-light-1 disabled:bg-white",class:[
        _vm.colorClass,
        _vm.leftIcon ? 'pl-5' : null,
        !_vm.disabled ? 'inputField' : null ],attrs:{"placeholder":_vm.placeholder,"maxlength":_vm.maxlength,"disabled":_vm.disabled,"readonly":_vm.readonly,"type":"radio"},domProps:{"checked":_vm._q(_vm.value,null)},on:{"input":function($event){return _vm.onChange(_vm.value)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit.apply(null, arguments)},"change":function($event){_vm.value=null}}},'input',_vm.$attrs,false)):_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],staticClass:"w-full outline-none placeholder-grey-medium-2 font-medium text-md text-grey-dark-1 px-2 py-1 border rounded focus:border-blue-dark-1 disabled:text-grey-light disabled:placeholder-grey-light-1 disabled:border-grey-light-1 disabled:bg-white",class:[
        _vm.colorClass,
        _vm.leftIcon ? 'pl-5' : null,
        !_vm.disabled ? 'inputField' : null ],attrs:{"placeholder":_vm.placeholder,"maxlength":_vm.maxlength,"disabled":_vm.disabled,"readonly":_vm.readonly,"type":_vm.fieldType},domProps:{"value":(_vm.value)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.value=$event.target.value},function($event){return _vm.onChange(_vm.value)}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit.apply(null, arguments)}}},'input',_vm.$attrs,false)),(_vm.type === 'password')?_c('button',{staticClass:"absolute right-2 focus:outline-none",on:{"click":_vm.onClickIcon}},[_c('img',{attrs:{"src":_vm.visible}})]):_vm._e()]),(_vm.isTextOk !== null)?_c('p',{staticClass:"text-sm mt-0.4 absolute",class:!_vm.isTextOk ? 'text-red' : 'text-green'},[_vm._v(" "+_vm._s(_vm.errorText)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }