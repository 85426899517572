<template>
  <div class="xl:min-w-250 min-w-200 border-r border-grey-nav">
    <div class="border-b border-grey-nav p-3" v-if="options.isDeep">
      <router-link
        v-if="globalCompetency"
        :to="'/competencies'"
        class="flex text-blue-dark-1 text-md font-medium"
      >
        <img
          src="../../assets/img/menu/arrow-left.svg"
          width="10"
          class="mr-1"
        />
        {{ 'Competencies' }}
      </router-link>
      <router-link
        v-if="!globalCompetency"
        :to="itemLink({ route: deepPathRoute }) || '/'"
        class="flex text-blue-dark-1 text-md font-medium"
      >
        <img
          src="../../assets/img/menu/arrow-left.svg"
          width="10"
          class="mr-1"
        />
        {{ deepNameRoute || 'Dashboard' }}
      </router-link>
    </div>
    <div
      class="pt-1 px-4"
      v-if="
        options.title &&
          !shouldRenderActiveAssessments &&
          !shouldRenderActiveCompanyAssessments
      "
    >
      <p class="text-md text-grey-medium-2">{{ options.title }}</p>
    </div>
    <div
      class="flex flex-col mt-1"
      v-if="
        !shouldRenderActiveAssessments &&
          !shouldRenderClosedAssessments &&
          !shouldRenderActiveCompanyAssessments &&
          !shouldRenderNewAssessmentLinks
      "
    >
      <router-link
        v-for="link in options.links"
        :key="link.name"
        :to="itemLink(link)"
        class="py-2 text-grey-light text-md font-semibold relative flex items-center cursor-pointer"
        :class="[
          options.isDeep ? 'pl-6' : 'pl-3',
          link.route === '#' ? 'pointer-events-none' : '',
          isNested(link.route) ? 'router-link-exact-active' : '',
        ]"
      >
        <div
          v-if="link.icon"
          class="w-32 h-32 bg-grey-light-1 bg-opacity-20 rounded mr-1.5 icon flex justify-center items-center"
        >
          <IconBase
            :iconColor="isLinkActive(link) ? '#00A1D5' : '#748FA8'"
            :width="
              link.icon === 'team' || link.icon === 'competencies' ? 18 : 16
            "
            :height="link.icon === 'competencies' ? 18 : 16"
            ><component :is="linkIcon(link.icon)" />
          </IconBase>
        </div>
        {{ link.name }}
      </router-link>
    </div>
    <div
      v-else-if="
        shouldRenderActiveAssessments || shouldRenderActiveCompanyAssessments
      "
    >
      <ActiveAssessmentsSidenav :data="activeAssessments" :type="this.role" />
    </div>
    <div v-else-if="shouldRenderClosedAssessments">
      <router-link
        :to="this.$route.path"
        class="py-2 mt-1 text-grey-light text-md font-semibold relative flex items-center cursor-pointer pl-6"
      >
        By Assessment
      </router-link>
    </div>
    <div v-else-if="shouldRenderNewAssessmentLinks">
      <NewAssessmentSidenav />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import ActiveAssessmentsSidenav from './components/ActiveAssessmentsSidenav';
import NewAssessmentSidenav from './components/NewAssessmentSidenav';
import _replace from 'lodash/replace';
import IconBase from '@/components/miscellaneous/IconBase';
import IconAssessment from '@/components/miscellaneous/icons/IconAssessment';
import IconCompany from '@/components/miscellaneous/icons/IconCompany';
import IconHome from '@/components/miscellaneous/icons/IconHome';
import IconInsights from '@/components/miscellaneous/icons/IconInsights';
import IconPeople from '@/components/miscellaneous/icons/IconPeople';
import IconResults from '@/components/miscellaneous/icons/IconResults';
import IconTeam from '@/components/miscellaneous/icons/IconTeam';
import IconJob from '@/components/miscellaneous/icons/IconJob';
import IconCompetencies from '@/components/miscellaneous/icons/IconCompetencies';
import IconInvites from '@/components/miscellaneous/icons/IconInvites';
import IconProducts from '../miscellaneous/icons/IconProducts.vue';

export default {
  name: 'Sidenav',
  data: () => ({ activeLink: [] }),
  components: {
    ActiveAssessmentsSidenav,
    NewAssessmentSidenav,
    IconBase,
    IconAssessment,
    IconCompany,
    IconHome,
    IconInsights,
    IconPeople,
    IconResults,
    IconTeam,
    IconJob,
    IconCompetencies,
    IconInvites,
  },
  computed: {
    ...mapState({
      role: (state) => state.home.renderAs,
      assessments: (state) => state.assessments.results,
      closedAssessments: (state) => state.home.closedAssessments,
      teams: (state) => state.teams.teams,
      activeAssessments: (state) => state.home.activeAssessments,
      currentCompany: (state) => state.companies.currentCompany,
      selectedUser: (state) => state.companies.selectedUser,
      selectedAssessment: (state) => state.companies.selectedAssessment,
      currentProduct: (state) => state.products.productDetails,
    }),
    ...mapGetters({
      inProgressAssessment: 'teamResults/isAssessmentInProgress',
      inProgressCompanyAssessment: 'companyResults/isAssessmentInProgress',
      getRole: 'home/getRole',
    }),
    globalCompetency() {
      if (this.$route.params.productId === 'global') return true;
      return false;
    },
    firstTeam() {
      if (this.teams) return this.teams[0]?.id;
    },
    deepPathRoute() {
      const dynamicDeepPath = this.$route.meta.sidenav.deepPath;

      if (typeof dynamicDeepPath === 'function') {
        return dynamicDeepPath();
      }
      else {
        if (!this.$route.meta.sidenav.secondaryDeepPath)
          return this.$route.meta.sidenav.deepPath;
        else {
          if (this.getRole === 'seller')
            return this.$route.meta.sidenav.secondaryDeepPath;
          else return this.$route.meta.sidenav.deepPath;
        }
      }
    },
    deepNameRoute() {
      const dynamicDeepName = this.$route.meta.sidenav.deepName;
      if (typeof dynamicDeepName === 'function') {
        return dynamicDeepName();
      } else {
        return this.$route.meta.sidenav.deepName;
      }
    },
    options() {
      if (this.$route.name === 'Home') {
        return this.$route.meta.sidenav[this.role];
      }
      return this.$route.meta.sidenav;
    },
    survey() {
      return this.role === 'manager' || this.role === 'admin'
        ? this.closedAssessments?.length > 0 && this.closedAssessments[0]
        : this.assessments?.length > 0 &&
        this.assessments.filter((el) => el.canViewResults).pop();
    },
    shouldRenderNewAssessmentLinks() {
      return this.$route.meta.newAssessmentFlow;
    },
    shouldRenderActiveAssessments() {
      return (
        (this.$route.name === 'TeamAssessmentResult' ||
          this.$route.name === 'CompanyAssessmentResult') &&
        this.inProgressAssessment
      );
    },
    shouldRenderActiveCompanyAssessments() {
      return (
        this.$route.name === 'CompanyAssessmentResult' &&
        this.inProgressCompanyAssessment
      );
    },
    shouldRenderClosedAssessments() {
      return (
        (this.$route.name === 'TeamAssessmentResult' ||
          this.$route.name === 'CompanyAssessmentResult') &&
        !this.inProgressAssessment
      );
    },
  },
  methods: {
    ...mapActions({
      getResults: 'assessments/getDashboardResults',
    }),
    linkIcon(icon) {
      switch (icon) {
        case 'home':
          return IconHome;
        case 'assessment':
          return IconAssessment;
        case 'team':
          return IconTeam;
        case 'results':
          return IconResults;
        case 'company':
          return IconCompany;
        case 'people':
          return IconPeople;
        case 'insights':
          return IconInsights;
        case 'job':
          return IconJob;
        case 'competencies':
          return IconCompetencies;
        case 'invites':
          return IconInvites;
        case 'products':
          return IconProducts;
        default:
          break;
      }
    },
    isNested(link) {
      if (link !== '/') {
        return this.$route.matched[0].path.indexOf(link) === 0;
      }
    },
    itemLink(item) {
      let newRoute = item.route;
      if (newRoute.indexOf(':id') > -1) {
        newRoute = _replace(
          newRoute,
          ':id',
          this.survey.id ? this.survey.id : this.survey.surveyId
        );
      }
      if (newRoute.indexOf(':companyId') > -1) {
        newRoute = _replace(newRoute, ':companyId', this.currentCompany.id);
      }
      if (newRoute.indexOf(':userId') > -1) {
        newRoute = _replace(newRoute, ':userId', this.selectedUser.id);
      }
      if (newRoute.indexOf(':teamId') > -1) {
        newRoute = _replace(newRoute, ':teamId', -1);
      }
      if (newRoute.indexOf(':assessmentId') > -1) {
        newRoute = _replace(
          newRoute,
          ':assessmentId',
          this.selectedAssessment.id
        );
      }
      if (newRoute.indexOf(":productId") > -1) {
        const productId =
          this.currentProduct.productId || this.$route.params.productId;
        newRoute = _replace(newRoute, ":productId", productId);
      }
      return newRoute;
    },
    isLinkActive(link) {
      return this.$route.path === link.route;
    },
  },
  async mounted() {
    if (this.options.title === 'Results') {
      this.getResults();
    }
  },
};
</script>

<style lang="scss" scoped>
.router-link-exact-active {
  background-color: #fff;
  color: $blue;
  &:after {
    content: '';
    width: 3px;
    height: 28px;
    top: 25%;
    background-color: $blue;
    position: absolute;
    right: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .icon {
    background-color: $sidenav-icon-active 20%;
  }
}
</style>
