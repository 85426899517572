<template>
  <Modal :data="$data" :isVisible="true">
    <div class="flex flex-row ">
      <div class="flex flex-row flex-grow justify-end">
        <Button
          text="Go to DashBoard"
          type="primary"
          size="medium"
          @click.native="close"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "FinishTimeModal",
  props: ["visible"],
  data: () => ({
    title: "Survey allocated time expired",
    content:
      "Please click Go to DashBoard to return to the main page.",
    confirmationText: "",
  }),
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
    }),
    close() {
      this.closeModal();
      this.$router.push("/");
    },
  },
};
</script>
