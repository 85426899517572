import {
  fetchAdminTeams,
  createTeam,
  fetchManagers,
  assignManager,
  getTeamById,
  getMembersByTeamId,
  fetchUsersOutsideTeam,
  assignUser,
  removeUser,
  removeManager,
  updateTeam,
  deactivateTeam,
  activateTeam
} from "@/services/api/teams";
import router from "@/router";

const getDefaultState = () => ({
  teams: [{}],
  team: {},
  managers: [],
  members: [],
  teamMembers: [],
  users: [],
  membersCount: 0,
  allUsers: 0,
  totalCount: 0,
  fetchMembers: false,
  fetchUsers: false,
  teamStatus: false,
});

const actions = {
  resetCallsState({ commit }) {
    commit("resetState");
  },
  getTeams({ commit }, { onlyActive, pageNr, pageSz, sorting = null, keyword = '' }) {
    return fetchAdminTeams(onlyActive, pageNr, pageSz, keyword, sorting)
      .then((rsp) => {
        commit("SET_TEAMS", rsp.data.data);
        commit("SET_TEAMS_COUNT", rsp.data.totalCount)
      })
      .catch((err) => {
        throw err;
      });
  },

  getTeamDetails({ commit }, { teamId }) {
    return getTeamById(teamId).then((rsp) => {
      commit("SET_TEAM", rsp.data.data);
      commit("SET_TEAM_STATUS", rsp.data.data.isActive);
    });
  },
  getTeamMembers({ commit }, { teamId, payload }) {
    return getMembersByTeamId(teamId, payload).then((rsp) => {
      commit("SET_FETCH_MEMBERS", false);
      commit("SET_TEAM_MEMBERS", rsp.data.totalCount);
      commit("SET_MEMBERS", rsp.data.data);
      return rsp.data.data;
    });
  },

  getUsersOutsideTeam({ commit }, { payload }) {
    return fetchUsersOutsideTeam(payload).then((rsp) => {
      commit("SET_FETCH_USERS", false);
      commit("SET_USERS", rsp.data.data);
      commit("SET_FETCH_ALL_USERS", rsp.data.totalCount);
      return rsp.data.data;
    });
  },

  assignUsers({ commit }, { teamId, userId }) {
    return assignUser(teamId, userId).then(() => {
      commit("SET_FETCH_MEMBERS", true);
    });
  },

  clearManager(_, { teamId, managerId }) {
    return removeManager(teamId, managerId);
  },

  removeUsers({ dispatch, commit }, { teamId, userId }) {
    return removeUser(teamId, userId).then(() => {
      commit("SET_FETCH_USERS", true);
    });
  },

  deactivateTeamById(_, { id }) {
    return deactivateTeam(id);
  },

  activateTeamById(_, { id }) {
    return activateTeam(id);
  },

  editTeam({ dispatch, commit }, { teamId, payload, managerId }) {
    return updateTeam(teamId, payload)
      .then(async (rsp) => {
        if (managerId) {
          state.team.manager &&
            (await dispatch("clearManager", {
              teamId,
              managerId: state.team.manager.id,
            })); // Why are we removing the manager?
          return assignManager(rsp.data.data.id, managerId).then(() => {
            commit("SET_FETCH_MEMBERS", true);
            dispatch(
              "alerts/showSuccess",
              {
                messageHeader: "Success!",
                message: "The team was edited sucessfully.",
              },
              { root: true }
            );
          });
        } else {
          dispatch(
            "alerts/showSuccess",
            {
              messageHeader: "Success!",
              message: "The team was edited sucessfully.",
            },
            { root: true }
          );
        }
      })
      .catch((err) => {
        dispatch(
          "alerts/showError",
          {
            messageHeader: "Error!",
            message: err.message,
          },
          { root: true }
        );
      });
  },

  getManagers({ commit }) {
    return fetchManagers().then((rsp) => {
      commit("SET_MANAGERS", rsp.data.data);
    });
  },
  create({ dispatch }, { payload, managerId, navigate }) {
    return createTeam(payload)
      .then((rsp) => {
        if (managerId) {
          return assignManager(rsp.data.data.id, managerId).then(() => {
            if (navigate) {
              router.push(`/admin-teams/${rsp.data.data.id}`);
            }
            dispatch(
              "alerts/showSuccess",
              {
                messageHeader: "Success!",
                message: "The team was created sucessfully.",
              },
              { root: true }
            );
          });
        } else {
          dispatch(
            "alerts/showSuccess",
            {
              messageHeader: "Success!",
              message: "The team was created sucessfully.",
            },
            { root: true }
          );
        }
        return rsp;
      })
      .catch((err) => {
        dispatch(
          "alerts/showError",
          {
            messageHeader: "Error!",
            message: err.message,
          },
          { root: true }
        );
      });
  },
  resetTeam({ commit }) {
    commit("RESET_TEAM");
  },
};
const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TEAMS(state, teams) {
    state.teams = teams;
  },
  SET_TEAMS_COUNT(state, value) {
    state.totalCount = value;
  },
  SET_FETCH_MEMBERS(state, fetchMembers) {
    state.fetchMembers = fetchMembers;
  },
  SET_FETCH_ALL_USERS(state, allUsers) {
    state.allUsers = allUsers;
  },
  SET_FETCH_USERS(state, fetchUsers) {
    state.fetchUsers = fetchUsers;
  },
  SET_TEAM(state, team) {
    state.team = team;
  },
  RESET_TEAM(state) {
    state.team = {};
  },
  SET_MEMBERS(state, members) {
    state.members = members;
  },
  SET_MANAGERS(state, managers) {
    state.managers = managers;
  },
  SET_USERS(state, users) {
    state.users = users;
  },
  SET_TEAM_MEMBERS(state, count) {
    state.membersCount = count;
  },
  SET_TEAM_STATUS(state, teamStatus) {
    state.teamStatus = teamStatus;
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
