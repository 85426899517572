<template>
  <div>
    <fusioncharts
      :type="type"
      :width="width"
      :height="height"
      :dataFormat="dataFormat"
      :dataSource="dataSource"
    ></fusioncharts>
  </div>
</template>

<script>
import Vue from "vue";
import FusionCharts from "fusioncharts";
import VueFusionCharts from "vue-fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";

import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

Vue.use(VueFusionCharts, FusionCharts, FusionTheme);

// Resolves charts dependency
Charts(FusionCharts);

export default {
  name: "MultiChart",
  props: [
    "categories",
    "barData",
    "lineData",
    "plotSpace",
    "barTitle",
    "barColor",
    "yAxisMaxValue",
    "numDivLines",
  ],
  data: () => ({
    type: "mscombi2d",
    width: "100%",
    height: "400",
    dataFormat: "json",
  }),
  computed: {
    dataSource() {
      return {
        chart: {
          theme: "fusion",
          plotSpacePercent: this.plotSpace || 30,
          yAxisValueDecimals: "1",
          forceDecimals: true,
          yAxisMaxValue: this.yAxisMaxValue || "4",
          yAxisMinValue: "0",
          setAdaptiveYMin: "0",
          adjustDiv: "0",
          numDivLines: this.numDivLines || "7",
          divLineDashed: "1",
          divLineDashLen: "15",
          divLineDashGap: "6",
          divLineColor: "#D0DAE3",
          crosslinecolor: "#EFF7FD",
          labelFontSize: "12",
          labelFontColor: "#748FA8",
          canvasTopPadding: "15",
          interactiveLegend: "0",
          showBorder: 0,
        },
        categories: [
          {
            category: this.categories,
          },
        ],
        dataset: this.barData,
      };
    },
  },
};
</script>
