<template>
  <div>
    <div
      class="w-full h-full fixed z-40 bottom-0 flex justify-center items-center modal"
    >
      <div class="w-modal bg-white p-4 shadow flex-grow-0 rounded">
        <p class="font-headers font-bold text-grey-dark-2 text-l mb-1.5">
          Deactivate Customer User
        </p>
        <div>
          <p class="text-mdh text-grey-dark-2 mb-1">
            Deactivating a customer user means that past results will be stored.
            However, company admins will not be able to invite them to future
            assessments until they are re-activated.
          </p>
          <!--  <ul class="px-6">
            <li class="text-mdh text-grey-dark-2">
              As an employee, they will be removed as participant from draft
              assessments
            </li>
            <li class="text-mdh text-grey-dark-2">
              As a manager, they will be removed as a team manager and those
              teams will show "No Manager Assigned"
            </li>
          </ul> -->
          <p class="text-mdh text-grey-dark-2 mt-4">
            Are you sure you want to deactivate this customer user?
          </p>
        </div>
        <div class="flex flex-row flex-grow justify-end mt-4 space-x-2">
          <Button
            type="tertiary"
            size="medium"
            text="Close"
            @click.native="closeModal"
            data-testid="close-button"
          />
          <button
            class="bg-red px-2 py-1 font-semibold text-md text-white rounded"
            @click="disable"
            data-testid="disable-seller-button"
          >
            Deactivate User
          </button>
        </div>
      </div>
    </div>
    <div
      class="w-full h-full fixed z-30 bottom-0 bg-grey-dark-1 opacity-70 modal transition-all"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'DeactivateCustomerUserModal',
  props: {
    userId: { type: Number },
  },
  methods: {
    ...mapActions({
      closeModal: 'modals/closeModal',
      changeUserStatus: 'companies/changeUserStatus',
    }),
    async disable() {
      await this.changeUserStatus({ userId: this.userId, isActive: false });
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  top: 0;
}
ul {
  list-style-type: disc;
}
</style>
