<template>
  <div class="landing">
    <component :is="landingComponent" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Employee from "./components/Employee";
import Admin from "./components/Admin";
import Manager from "./components/Manager";
import Seller from "./components/Seller";

export default {
  name: "Home",
  components: {
    Employee,
    Manager,
    Admin,
    Seller,
  },
  methods: {
    ...mapActions({
      resetCompanyResults: "companyResults/resetCompanyResults",
    }),
  },
  async mounted() {
    await this.resetCompanyResults();
  },
  computed: {
    ...mapState({
      renderAs: (state) => state.home.renderAs,
    }),
    landingComponent() {
      switch (this.renderAs) {
        case "employee":
          return Employee;
        case "admin":
          return Admin;
        case "manager":
          return Manager;
        case "seller":
          return Seller;
        default:
          return Employee;
      }
    },
  },
};
</script>
