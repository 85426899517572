<template>
  <Modal :data="data" :isVisible="visible" hasCloseIcon />
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import TextArea from "../../../components/TextArea";

export default {
  name: "ViewReleaseNotesModal",
  props: ["visible"],
  components: {TextArea},
  computed: {
    ...mapState({
      releaseNotes: (state) => state.products.productDetails.releaseNotes, 
      currentVersion: (state) => state.products.productDetails.currentVersion,
    }),
    data() {
      return  ({
        title: `Version ${this.currentVersion} Release Notes`,
        content: this.releaseNotes || "No release notes added for this version.",
      })
    }
  },
};
</script>
