<template>
  <div class="mb-3">
    <div v-if="anySurveys">
      <StatusList
        v-for="teamAssessment in activeAssessments"
        :key="teamAssessment.teamId"
        :assessments="teamAssessment.surveys"
        :teamName="teamAssessment.teamName"
        :type="type"
      />
    </div>
    <div
      class="flex mb-3 justify-between mt-5 rounded bg-white shadow py-0 mt-2 p-3"
      v-else
    >
      <div>
        <p class="font-barlow text-mdl font-bold text-grey-light mb-2">
          Active Assessments
        </p>
        <p class="text-md text-grey-dark-1 w-5/6 ml-3">
          {{ emptyStateText }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import StatusList from "@/components/AssessmentStatusList/StatusList";

export default {
  name: "ActiveAssessments",
  props: {
    type: {
      type: String,
      default: "manager",
    },
  },
  components: {
    StatusList,
  },
  computed: {
    ...mapGetters({
      anySurveys: "home/anySurveys",
    }),
    ...mapState({
      activeAssessments: (state) => state.home.activeAssessments,
    }),
    emptyStateText() {
      return this.type === "manager"
        ? "No one from your team is taking any assessments yet, but this is where you will be able to monitor them at first glance."
        : "No one is taking any assessments in your company at the moment.";
    },
  },
};
</script>

<style></style>
