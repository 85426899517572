<template>
  <div class="toggle-invites-modal">
    <div
      class="w-full h-full fixed z-40 bottom-0 flex justify-center items-center modal"
    >
      <div class="w-1/3 bg-white p-4 shadow rounded flex-grow-0 rounded">
        <h1 class="font-headers font-bold text-grey-dark-2 text-l mb-1.5">
          Unlimited Invites
        </h1>
        <p class="text-grey-light text-sm-2 mb-4">
          {{
            isCurrentlyUnlimited
              ? "You are about to turn off the unlimited number of invites option for this company. Company admins will no longer be able to run assessments indefinetly for any number of participants. Once the company will run out of invites, then new invites need to be added to their account in order for them to run new assessments."
              : "You are about to turn on the unlimited number of invites option for this company. Company admins will be able to run assessments indefinitely for any number of participants."
          }}
        </p>
        <p class="text-grey-light text-sm-2">
          Are you sure you want to
          <span>{{ isCurrentlyUnlimited ? "disable" : "enable" }}</span>
          unlimited invites?
        </p>
        <div class="flex flex-row flex-grow justify-end mt-4 space-x-2">
          <Button
            type="tertiary"
            size="medium"
            text="Cancel"
            @click.native="closeModal"
            data-testid="cancel-button"
          />
          <Button
            :text="isCurrentlyUnlimited ? 'Disable' : 'Enable'"
            type="primary"
            size="medium"
            class="px-4"
            @click.native="toggle"
            data-testid="confirm-button"
          />
        </div>
      </div>
    </div>
    <div
      class="w-full h-full fixed z-30 bottom-0 bg-grey-dark-1 opacity-70 modal transition-all"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ToggleUnlimitedInvitesModal",
  props: { isCurrentlyUnlimited: { type: Boolean, default: false } },
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      allowUnlimitedInvites: "invites/allowUnlimitedInvites"
    }),
    async toggle() {
      await this.allowUnlimitedInvites({ orgId: parseInt(this.$route.params.companyId, 10), val: !this.isCurrentlyUnlimited });
      this.closeModal();
    },
  },
};
</script>

<style lang="scss">
.toggle-invites-modal {
  .modal {
    top: 0;
    .toggle-invites-btn {
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }
}
</style>
