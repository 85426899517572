<template>
  <div>
    <div v-if="anyClosedSurveys">
      <TabContainer>
        <Tab
          :name="team.name"
          :selected="index === 0"
          v-for="(team, index) in closedAssessmentsTeams"
          :key="team.id"
        >
          <div
            class="flex flex-row space-x-2 mt-2 mb-5"
            v-if="team.closedAssessments.length > 0"
          >
            <DeltaPreview type="gaps" :data="team" />
            <DeltaPreview type="strenghts" :data="team" />
          </div>
          <div v-else>
            <ClosedEmptyState />
          </div>
        </Tab>
      </TabContainer>
    </div>
    <div v-else>
      <ClosedEmptyState />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ClosedEmptyState from "./ClosedEmptyState";
import DeltaPreview from "@/components/DeltaTable/DeltaPreview";

export default {
  name: "ClosedAssessments",
  components: {
    DeltaPreview,
    ClosedEmptyState,
  },
  computed: {
    ...mapGetters({
      closedAssessmentsTeams: "home/getClosedAssessmentsGroupedByTeam",
      anyClosedSurveys: "home/anyClosedSurveys",
    }),
  },
};
</script>
