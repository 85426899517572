import { apiCall } from "./api.js";

export const fetchPeopleUsers = (
  keyword = "",
  pageNr,
  sorting = null,
  allowInviteInactiveUsers = null,
  pageSize = 20,
  status = -1
) => {
  const body = {
    keyword,
    pageNumber: pageNr,
    pageSize: pageSize,
    sorting,
    allowInviteInactiveUsers,
    status,
  };
  return apiCall("POST", `api/v1/User`, body);
};

export const fetchSellerAdmins = (keyword = "", pageNr, sorting = null) => {
  const body = { keyword, pageNumber: pageNr, pageSize: 20, sorting };
  return apiCall("POST", `api/v1/User/get_seller_admins`, { ...body });
};

export const editUser = (payload) => apiCall("PUT", "api/v1/User", payload);

export const sendInviteEmail = (sendDate, usersIds) =>
  apiCall("POST", `api/v1/User/send_email_platform_invite_users`, {
    sendDate,
    usersIds,
  });
