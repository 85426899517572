/**
 * Created with the purpose to replace $vuetify.breakpoint without too much pain:
 * $vueify.breakpoint.smAndUp => $screen.breakpoint.smAndUp
 *
 * It uses the same thresholds and returns the same obj as Vuetify
 * #copyPasteDrivenDevelopment :)
 * https://github.com/vuetifyjs/vuetify/blob/c9d72f98f8c54b41c27ae1f8b3a843f4770e4e4d/packages/vuetify/src/services/breakpoint/index.ts#L181
 *
 * Returns the "size" property if the exact window size is needed
 */

import Vue from "vue";

const thresholds = {
  xs: 600,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
  xxxl: 1920,
};

const getClientHeight = () => {
  if (typeof document === "undefined") return 0;
  return Math.max(
    document.documentElement?.clientHeight,
    window.innerHeight || 0
  );
};

const getClientWidth = () => {
  if (typeof document === "undefined") return 0;
  return Math.max(
    document.documentElement?.clientWidth,
    window.innerWidth || 0
  );
};

const getBreakPoints = (height, width) => {
  const xs = width < thresholds.xs;
  const sm = width < thresholds.sm && !xs;
  const md = width < thresholds.md && !(sm || xs);
  const lg = width < thresholds.lg && !(md || sm || xs);
  const xl = width >= thresholds.lg;
  const xxl = width >= thresholds.xl;
  const xxxl = width >= thresholds.xxl;

  const xsOnly = xs;
  const smOnly = sm;
  const mdOnly = md;
  const xlOnly = xl;
  const xxlOnly = xxl;
  const lgOnly = lg;
  const xxxlOnly = xxxl;

  const smAndDown = (xs || sm) && !(md || lg || xl || xxl || xxxl);
  const mdAndDown = (xs || sm || md) && !(lg || xl || xxl || xxxl);
  const lgAndDown = (xs || sm || md || lg) && !(xl || xxl || xxxl);
  const xlAndDown = (xs || sm || md || lg || xl) && !(xxl || xxxl);
  const xxlAndDown = (xs || sm || md || lg || xl || xxl) && !xxxl;

  const smAndUp = !xs && (sm || md || lg || xl || xxl || xxxl);
  const mdAndUp = !(xs || sm) && (md || lg || xl || xxl || xxxl);
  const lgAndUp = !(xs || sm || md) && (lg || xl || xxl || xxxl);
  const xlAndUp = !(xs || sm || md || lg) && (xl || xxl || xxxl);
  const xxlAndUp = !(xs || sm || md || lg || xl) && (xxl || xxxl);

  return {
    breakpoint: {
      xs,
      xsOnly,
      sm,
      smOnly,
      smAndDown,
      smAndUp,
      md,
      mdOnly,
      mdAndDown,
      mdAndUp,
      lg,
      lgOnly,
      lgAndDown,
      lgAndUp,
      xl,
      xlOnly,
      xxlOnly,
      xlAndDown,
      xlAndUp,
      xxxl,
      xxxlOnly,
      xxlAndDown,
      xxlAndUp,
    },
    size: {
      height,
      width,
    },
  };
};

const screen = getBreakPoints(getClientHeight(), getClientWidth());

window.addEventListener("resize", () =>
  Object.assign(screen, getBreakPoints(getClientHeight(), getClientWidth()))
);

Vue.prototype.$screen = Vue.observable(screen);
