<template>
  <div :data-testid="`companiestable-listitem-${index}`">
    <div class="flex flex-row w-full py-1 pl-2 items-center">
      <div class="flex flex-grow w-1/3">
        <p class="text-md text-grey-dark-1 font-semibold">
          {{ source.name }}
        </p>
      </div>
      <div class="flex flex-grow pl-2 w-1/2">
        <p class="text-md" :class="{ 'text-red': !this.source.admins.length }">
          {{ admins }}
        </p>
      </div>
      <div class="flex flex-grow w-1/6 pl-4">
        <p class="text-md" :class="subDetails.class">
          {{ subDetails.label }}
        </p>
      </div>
      <div class="flex flex-grow w-1/6 pl-4">
        <p class="text-md text-grey-dark-2">
          {{ source.usersCount }}
        </p>
      </div>
      <div class="flex flex-grow text-md w-1/6 ml-4">
        <p v-if="source.active" class="text-green-2">Active</p>
        <p v-else class="text-red">Deactivated</p>
      </div>
      <div
        class="flex flex-shrink justify-center text-md text-grey-dark-2 w-1/6"
      >
        <Button
          text="Manage"
          type="secondary"
          size="medium"
          @click.native="manage(source.id)"
        />
      </div>
    </div>
    <div class="border border-grey-light-1 border-top-none" />
  </div>
</template>
<script>
import xor from "lodash/xor";
export default {
  name: "ListItem",
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
    manage: { type: Function, default: () => null },
  },
  computed: {
    admins() {
      const shown = this.source.admins.slice(0, 2); //showing first 2 admins
      const unshown = xor(this.source.admins, shown);
      if (!shown.length) {
        return "No Admin Assigned";
      }
      if (unshown.length) {
        return this.adminNames(shown) + " and " + unshown.length + " more";
      }
      return this.adminNames(shown);
    },
    subDetails() {
      switch(parseInt(this.source.subscriptionStatus, 10)) {
        case 1:
          return ({
            label: 'Active',
            class: 'text-green'
          });
        case 2:
          return ({
            label: 'Deactivated',
            class: 'text-red'
          });
        case 3:
          return ({
            label: 'Suspended',
            class: 'text-grey-medium-2'
          });
        case 4:
          return ({
            label: 'Expired',
            class: 'text-red'
          });
        default:
          return ({
            label: 'No status',
            class: 'text-grey-medium-2'
          });
      }
    }
  },
  methods: {
    adminNames(list) {
      return list
        .map((admin) => admin.firstName + " " + admin.lastName)
        .join(", ");
    },
  },
};
</script>
