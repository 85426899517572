export const mockedCompanyDetails = {
  id: 1,
  name: "mocked company name",
  description: "mocked company description",
  activeUsersCount: 12,
  adminsUsersCount: 12,
  industryId: 12,
  industryName: "mocked industry name",
  active: true,
};

export const mockedJobRoles = [
  {
    name: "Analyst / Planner",
    mapping: ["Analyst", "Planner", "Production Planning"],
    active: true,
    id: Math.random(),
  },
  {
    name: "Analyst / Planner (Sr)",
    mapping: ["Senior Analyst"],
    active: true,
    id: Math.random(),
  },
  {
    name: "Buyer",
    mapping: ["Junior Buyer", "Mid-level Buyer"],
    active: true,
    id: Math.random(),
  },
  {
    name: "Buyer (Sr)",
    mapping: ["Senior Buyer"],
    active: true,
    id: Math.random(),
  },
  {
    name: "Category / Commodity Manager (Sr)",
    mapping: ["Senior Category and Commodity Manager"],
    active: true,
    id: Math.random(),
  },
];

export const mockedTargets = [
  { name: "Business Acumen", id: Math.random(), target: null },
  { name: "Category Management", id: Math.random(), target: null },
  { name: "CSR and Ethics", id: Math.random(), target: null },
  { name: "Cost and Price Management", id: Math.random(), target: null },
  { name: "Financial Analysis", id: Math.random(), target: null },
];

export const mockedCompetencies = [
  {
    name: "Business Acumen",
    mapping: [
      "Building Relationships",
      "Business Intelligence",
      "daw",
      "dwadwa",
      "wdadwa",
      "wdadwa",
    ],
    active: true,
    id: Math.random(),
  },
  {
    name: "Category Management (Commodity Management)",
    mapping: [
      "Market-Specific Knowledge",
      "Category Segmentation",
      "dwadwa",
      "dwaadwawd",
    ],
    active: true,
    id: Math.random(),
  },
  {
    name: "Corporate Social Responsibility (CSR) and Ethics",
    mapping: ["Business Conduct"],
    active: true,
    id: Math.random(),
  },
  {
    name: "Cost and Price Management",
    mapping: ["Cost", "Portofolio Analysis", "Price"],
    active: true,
    id: Math.random(),
  },
];
