<template>
  <nav class="border-b border-grey-light-1">
    <div
      class="items-center flex flex-row py-2 container mx-auto max-h-header h-header"
    >
      <p
        class="text-blue-dark-1 text-md font-medium flex-3 cursor-pointer"
        @click="open"
      >
        Exit
      </p>
      <div
        class="text-center text-grey-light font-semibold font-headers text-mdl flex-94"
      >
        <h3>
          {{ assessment.title }}
        </h3>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Header",
  computed: {
    ...mapState({
      assessment: (state) => state.assessments.selectedAssessment,
    }),
  },
  methods: {
    ...mapActions({
      openModal: "modals/openModal",
    }),
    open() {
      this.openModal({ modal: "quitAssessment", props: { allowInMultipleSessions: this.assessment.allowInMultipleSessions }});
    },
  },
};
</script>

<style lang="scss" scoped></style>
