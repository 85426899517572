<template>
  <div
    class="border-white w-2 h-2 flex justify-center items-center"
    :isActive="active"
    :class="[
      active ? 'bg-blue-dark-1' : 'bg-grey-light-1',
      isRound ? 'rounded-full' : 'rounded-small',
    ]"
  >
    <img v-if="active" src="../../assets/img/icons/check.svg" />
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    active: { type: Boolean, default: false },
    isRound: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
