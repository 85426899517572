<template>
  <div>
    <div
      class="w-full h-full fixed z-40 bottom-0 flex justify-center items-center modal"
    >
      <div class="w-modal bg-white p-4 shadow flex-grow-0 rounded">
        <h1 class="font-headers font-bold text-grey-dark-2 text-l mb-1.5">
          Remove Team Member
        </h1>
          <div class="ml-2">
            <p class="text-grey-dark text-mdh">
              If you remove this user from the team, we will store their past results for reporting purposes. They will not be able to take any future assessments as a part of this team unlsee you decide to add them back.
            </p>
            <p class="text-grey-dark text-mdh my-2">
              Are you sure you want to remove them?
            </p>
        </div>
        <div class="flex flex-row flex-grow justify-end mt-4 space-x-2">
          <Button
            type="tertiary"
            size="medium"
            text="Cancel"
            @click.native="closeModal"
          />
          <Button
            text="Remove"
            type="primary"
            size="medium"
            @click.native="removeMember"
          />
        </div>
      </div>
    </div>
    <div
      class="w-full h-full fixed z-30 bottom-0 bg-grey-dark-1 opacity-70 modal transition-all"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "RemoveMemberModal",
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
    }),
    removeMember() {
      this.$attrs.callback();
      this.closeModal();
    },
  },

};
</script>

<style lang="scss" scoped>
.modal {
  top: 0;
}
</style>
