<template>
  <div class="bg-white rounded shadow p-2 pt-3">
    <p class="font-headers font-bold text-mdl text-grey-light mb-2">
      {{ isAdmin ? 'Invites Usage' : 'Overview' }}
    </p>
    <div class="w-2/5 min-w-400 grid grid-cols-3 px-2 mb-2" v-if="invitesInfo">
      <div>
        <p class="text-grey-light text-sm-2">Purchased</p>
        <p class="text-grey-dark-1 font-medium text-mdlh mt-0.4">
          {{ allowUnlimitedInvites ? 'Unlimited' : invitesInfo.totalPurchased }}
        </p>
      </div>
      <div>
        <p class="text-grey-light text-sm-2">Redeemed</p>
        <p class="text-grey-dark-1 font-medium text-mdlh mt-0.4">{{ invitesInfo.totalRedeemed }}</p>
      </div>
      <div>
        <p class="text-grey-light text-sm-2">Remaining</p>
        <p class="text-grey-dark-1 font-medium text-mdlh mt-0.4">
          {{ allowUnlimitedInvites ? 'Unlimited' : invitesInfo.totalRemaining }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'InvitesInfoCard',
  props: {
    invitesInfo: {
      type: Object,
      default: () => ({}),
    },
    allowUnlimitedInvites: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
