<template>
  <Modal :data="$data" :isVisible="true">
    <div class="flex flex-row flex-grow justify-end">
      <Button
        type="tertiary"
        size="medium"
        text="Close"
        @click.native="closeModal"
        class="mr-2"
      />
      <Button
        text="Request"
        type="primary"
        size="medium"
        @click.native="request"
      />
    </div>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "RequestDeleteModal",
  props: ["visible"],
  data: () => ({
    title: "Request Account Removal",
    content:
      "In order to remove your account a request needs to be submitted to the platform administrator.",
    confirmationText: "Are you sure you want proceed with your request? ",
  }),
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      requestDelete: "account/requestDelete",
    }),
    request() {
      this.closeModal();
      this.requestDelete();
    },
  },
};
</script>
