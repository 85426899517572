import { apiCall, apiCallBlob } from "./api";

export const getGdprData = (userId) =>
  apiCallBlob(
    "GET",
    `api/Account/download-personal-data?userId=${userId}`
  );

export const reqeustDeletion = (userId, organizationId) =>
  apiCall(
    "POST",
    `api/Account/delete-personal-data-request?userId=${userId}&organizationId=${organizationId}`,
  );

export const getPersonalData = (userId) =>
  apiCall(
    "GET",
      `api/Account/get-personal-data?userId=${userId}`,
  );

export const approveRequest = (userId, organizationId) =>
  apiCall(
    "POST",
      `api/v1/Organization/delete-user-data?userId=${userId}&organizationId=${organizationId}`,
  );

export const declineRequest = (userId) =>
  apiCall(
    "POST",
      `api/v1/Organization/decline-delete-user-data?userId=${userId}`,
  );

export const getUsersWithRequest = (organizationId) =>
  apiCall(
    "POST",
    `api/v1/Organization/${organizationId}/get-users-with-delete-account-request`,
    {
      pageNumber: 1,
      pageSize: 100000,
    }
  );
