<template>
  <button
    class="px-2 text-center rounded border focus:outline-none flex flex-row items-center justify-center"
    :class="[type, size]"
    :disabled="disabled"
    @click="onClick"
  >
    <img
      v-if="iconLeft"
      :src="iconLeft"
      :class="{
        'transform rotate-180' : rotateArrow,
        'mr-2' :text
      }"
    />
    {{ text }}
    <img
      v-if="iconRight"
      :src="iconRight"
      class="ml-2"
      :class="rotateArrow ? 'transform rotate-180' : null"
    />
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    text: { default: "Button text", type: String },
    size: { default: "large", type: String },
    type: { default: "primary", type: String },
    iconLeft: { default: null, type: String },
    iconRight: { default: null, type: String },
    disabled: { default: false, type: Boolean },
    onClick: { default: () => {}, type: Function },
    rotateArrow: { default: false, type: Boolean },
  },
};
</script>

<style lang="scss" scoped>
button {
  font-family: Noto Sans, sans-serif;
  opacity: 1;
}
.large {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  height: 44px;
}
.medium {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  height: 38px;
}
.small {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  height: 30px;
}
.primary {
  background-color: #00a1d5;
  border-color: #00a1d5;
  color: white;

  &:hover {
    background-color: #0592be;
    border-color: #0592be;
  }
  &:active {
    background-color: #007ba3;
    border-color: #007ba3;
  }
  &:disabled {
    opacity: 0.5;
    background-color: #b1bfcd;
    border-color: #b1bfcd;
    pointer-events: none;
  }
}
.danger {
  background-color: #F04E23;
  border-color: #F04E23;
  color: white;

  &:hover {
    background-color: #F04E23;
    border-color: #F04E23;
  }
  &:active {
    background-color: #F04E23;
    border-color: #F04E23;
  }
  &:disabled {
    opacity: 0.5;
    background-color: #F04E23;
    border-color: #F04E23;
    pointer-events: none;
  }
}
.primary-white {
  background-color: white;
  border-color: #f4f7fa;
  color: #00a1d5;

  &:hover {
    background-color: #f4f7fa;
    border-color: #f4f7fa;
  }
  &:active {
    background-color: #e7ecf0;
    border-color: #e7ecf0;
  }
  &:disabled {
    opacity: 0.5;
    color: white;
    background-color: #f4f7fa;
    border-color: #f4f7fa;
    pointer-events: none;
  }
}
.secondary {
  background-color: #eff7fd;
  border-color: #eff7fd;
  color: #00a1d5;

  &:hover {
    background-color: #e4f0fa;
    border-color: #e4f0fa;
  }
  &:active {
    background-color: #d4e4f1;
    border-color: #d4e4f1;
  }
  &:disabled {
    opacity: 0.3;
    background-color: #f4f7fa;
    border-color: #f4f7fa;
    pointer-events: none;
  }
}
.tertiary {
  border: none;
  color: #00a1d5;

  &:hover {
    background-color: #f4f7fa;
    border-color: #f4f7fa;
  }
  &:active {
    background-color: #e6ecf0;
    border-color: #e6ecf0;
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
.tertiary-danger {
  border: none;
  color: #F04E23;

  &:hover {
    background-color: #f4f7fa;
    border-color: #f4f7fa;
  }
  &:active {
    background-color: #e6ecf0;
    border-color: #e6ecf0;
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
