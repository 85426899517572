<template>
  <div />
</template>
<script>
import Vue from "vue";
import Toasted from "vue-toasted";

Vue.use(Toasted);

let defaultOptions = {
  duration: 8000,
  keepOnHover: true,
  containerClass: "ism-toast",
  action: {
    text: "Close",
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
};

export default {
  name: "Toast",
  mounted() {
    Vue.toasted.register(
      "toasty_error",
      (payload) => {
        return `<div data-testid='toast'>
        <p class='font-bold text-sm-2 text-grey-dark-2' data-testid='toast-header'>${payload.messageHeader ||
          "Message header"}</p>
        <p class='text-sm text-grey-dark-2' data-testid='toast-message'>${payload.message ||
          "Message text"}</p>
      </div>`;
      },
      { ...defaultOptions, type: "error" }
    );
    Vue.toasted.register(
      "toasty_success",
      (payload) => {
        return `<div data-testid='toast'>
        <p class='font-bold text-sm-2 text-grey-dark-2' data-testid='toast-header'>${payload.messageHeader ||
          "Message header"}</p>
        <p class='text-sm text-grey-dark-2' data-testid='toast-message'>${payload.message ||
          "Message text"}</p>
      </div>`;
      },
      { ...defaultOptions, type: "success" }
    );
    Vue.toasted.register(
      "toasty_info",
      (payload) => {
        return `<div data-testid='toast'>
        <p class='font-bold text-sm-2 text-grey-dark-2' data-testid='toast-header'>${payload.messageHeader ||
          "Message header"}</p>
        <p class='text-sm text-grey-dark-2' data-testid='toast-message'>${payload.message ||
          "Message text"}</p>
      </div>`;
      },
      { ...defaultOptions, type: "info" }
    );
    Vue.toasted.register(
      "toasty",
      (payload) => {
        return `<div data-testid='toast'>
        <p class='font-bold text-sm-2 text-grey-dark-2' data-testid='toast-header'>${payload.messageHeader ||
          "Message header"}</p>
        <p class='text-sm text-grey-dark-2' data-testid='toast-message'>${payload.message ||
          "Message text"}</p>
      </div>`;
      },
      { ...defaultOptions, type: "default" }
    );
  },
};
</script>
<style lang="scss">
.ism-toast {
  width: 424px;

  .toasted.toasted-primary {
    box-shadow: 0px 4px 20px 10px rgba(22, 121, 237, 0.04);
    padding-left: 48px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 4px;
    background-color: white;
    &:before {
      content: url("../../assets/img/toast/warning.svg");
      height: 32px;
      width: 32px;
      position: absolute;
      left: 0;
    }
  }
  .toasted.toasted-primary .action.ripple {
    font-weight: 600;
    font-family: "Noto Sans";
    font-size: 14px;
    line-height: 19px;
    color: #00a1d5;
    text-transform: capitalize;
    padding: 8px 16px;
    border-radius: 4px;
    text-decoration: none;
    outline: none;
    &:hover {
      background: #f4f7fa;
    }
    &:active {
      background: #e6ecf0;
    }
  }

  .toasted.toasted-primary.error {
    display: relative;
    background-color: white;
    &:before {
      content: url("../../assets/img/toast/error.svg");
      height: 32px;
      width: 32px;
      position: absolute;
      left: 0;
    }
  }
  .toasted.toasted-primary.success {
    display: relative;
    background-color: white;
    &:before {
      content: url("../../assets/img/toast/success.svg");
      height: 32px;
      width: 32px;
      position: absolute;
      left: 0;
    }
  }
  .toasted.toasted-primary.info {
    display: relative;
    background-color: white;
    &:before {
      content: url("../../assets/img/toast/info.svg");
      height: 32px;
      width: 32px;
      position: absolute;
      left: 0;
    }
  }
}
</style>
