<template>
  <div>
    <div class="flex flex-row w-full items-center justify-between">
      <div class="flex-20">
        <p class="font-medium text-mdl text-grey-dark-1">
          {{ assessment.name }}
          {{ displayVersionForSeller(assessment.productVersion)}}
        </p>
        <p class="text-sm-2 text-grey-light">
          Launche{{ hasStarted ? "d" : "s" }} on
          {{ this.formatDate(assessment.openingTime) }}
        </p>
      </div>
      <div class="flex flex-row items-center">
        <img src="../../assets/img/assessment-status-list/due-img.svg" />
        <div class="ml-2">
          <div class="flex flex-row">
            <p class="font-normal text-sm-2 text-grey-dark-2">
              This assessment is due to close on
            </p>
            <p class="font-bold text-sm-2 text-grey-dark-2 ml-0.4">
              {{ this.formatDate(assessment.closingTime) }}.
            </p>
          </div>
          <p class="font-normal text-sm-2 text-grey-dark-2">
            Please complete all work by this date.
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div>
          <p class="text-center font-semibold text-l text-grey-dark-3">
            {{ assessment.invitedCount }}
          </p>
          <p class="text-center text-sm-2 text-grey-light">Invited</p>
        </div>
        <div class="ml-3">
          <p class="text-center font-semibold text-l text-grey-dark-3">
            {{ assessment.completedCount }}
          </p>
          <p class="text-center text-sm-2 text-grey-light">Complete to date</p>
        </div>
        <div class="ml-3">
          <p class="text-center font-semibold text-l text-grey-dark-3">
            {{ assessment.inProgressCount }}
          </p>
          <p class="text-center text-sm-2 text-grey-light">In progress</p>
        </div>
        <div class="ml-3">
          <p class="text-center font-semibold text-l text-grey-dark-3">
            {{ assessment.notStartedCount }}
          </p>
          <p class="text-center text-sm-2 text-grey-light">Not Started</p>
        </div>
      </div>
    </div>
    <div class="rounded h-6 mt-2 flex flex-row w-full">
      <div
        class="parent-1 rounded rounded-r-none bg-green-2"
        :style="`width:${completedWidth}%`"
      >
        <div class="hide-completed flex justify-center">
          <img
            src="../../assets/img/assessment-status-list/hover.svg"
            class="relative bottom-2 left-4"
          />
          <div class="relative right-4 bottom-1.5">
            <p class="font-bold text-sm-2 text-grey-dark-3 text-center">
              {{ Math.round(completedWidth) }}%
            </p>
            <p class="font-normal text-sm text-blue-dark-4 text-center">
              Completed
            </p>
          </div>
        </div>
      </div>
      <div class="parent-2 bg-blue-navy" :style="`width:${incompleteWidth}%`">
        <div class="hide-incomplete flex justify-center">
          <img
            src="../../assets/img/assessment-status-list/hover.svg"
            class="relative bottom-2 left-4"
          />
          <div class="relative right-4 bottom-1.5">
            <p class="font-bold text-sm-2 text-grey-dark-3 text-center">
              {{ Math.round(incompleteWidth) }}%
            </p>
            <p class="font-normal text-sm text-blue-dark-4 text-center">
              In progress
            </p>
          </div>
        </div>
      </div>
      <div
        class="parent-3 rounded rounded-l-none bg-grey-light-3"
        :style="`width:${notStartedWidth}%`"
      >
        <div class="hide-notstarted flex justify-center">
          <img
            src="../../assets/img/assessment-status-list/hover.svg"
            class="relative bottom-2 left-4"
          />
          <div class="relative right-4 bottom-1.5">
            <p class="font-bold text-sm-2 text-grey-dark-3 text-center">
              {{ Math.round(notStartedWidth) }}%
            </p>
            <p class="font-normal text-sm text-blue-dark-4 text-center">
              Not started
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row mt-2">
      <div class="flex flex-row mr-5 items-center">
        <div class="rounded h-1 w-3 bg-green-2 mr-0.4" />
        <p>Completed</p>
      </div>
      <div class="flex flex-row mr-5 items-center">
        <div class="rounded h-1 w-3 bg-blue-navy mr-0.4" />
        <p>In progress</p>
      </div>
      <div class="flex flex-row items-center">
        <div class="rounded h-1 w-3 bg-grey-light-3 mr-0.4" />
        <p>Not started</p>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  name: "StatusItem",
  props: { assessment: { type: Object, default: null } },
  computed: {
    ...mapGetters({
      isImpersonating: "auth/isImpersonating",
      isSeller: "auth/isSeller",
    }),
    completedWidth: function() {
      return `${(this.assessment.completedCount /
        this.assessment.invitedCount) *
        100}`;
    },
    incompleteWidth: function() {
      return `${(this.assessment.inProgressCount /
        this.assessment.invitedCount) *
        100}`;
    },
    notStartedWidth: function() {
      return `${(this.assessment.notStartedCount /
        this.assessment.invitedCount) *
        100}`;
    },
    hasStarted() {
      if (+new Date(this.assessment.openingTime) > +new Date()) return false;
      return true;
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format("ddd, D MMM YYYY hh:mm A") + " UTC";
    },
    displayVersionForSeller(version) {
      if (version &&  version !== "" && (this.isImpersonating || this.isSeller)) 
        return " (v" + version + ")";
      return "";
    }
  },
};
</script>
<style lang="scss" scoped>
.hide-completed {
  display: none;
}
.parent-1:hover .hide-completed {
  display: flex;
}
.hide-incomplete {
  display: none;
}
.parent-2:hover .hide-incomplete {
  display: flex;
}
.hide-notstarted {
  display: none;
}
.parent-3:hover .hide-notstarted {
  display: flex;
}
</style>
