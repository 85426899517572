<template>
  <div>
    <div
      class="w-full h-full fixed z-40 flex flex-col justify-center items-center modal"
    >
      <div class="w-userPreview bg-white p-4 shadow overflow-hidden rounded">
        <p class="font-barlow font-bold text-grey-dark-2 text-l mb-1.5">
          New Group
        </p>
        <p class="text-mdh text-grey-dark-2 mb-2">
          Give your group an appropriate name that represents a common
          charateristic for all participants in it. Then select the employees
          that should be apart of the new group.
        </p>
        <InputField
          label="Group Name"
          placeholder="e.g. New York Branch"
          class="mt-1 mb-3"
        />
        <p class="font-bold text-mdh text-grey-dark-1 mb-0.4">Select Members</p>
        <p class="text-sm-2 text-grey-dark-2 mb-2">
          Showing {{ mappedList.length }} team member{{
            mappedList.length > 1 ? "s" : null
          }}
        </p>
        <div>
          <div
            class="flex flex-row w-full  bg-grey-light-3 py-1 pl-2 rounded"
            :class="mappedList.length < 10 ? '' : 'pr-2'"
          >
            <button
              class="flex flex-row items-center focus:outline-none w-tableIndex mr-7"
            >
              <p class="text-sm-2 text-grey-light mr-0.4">
                NO.
              </p>
            </button>
            <div class="flex flex-grow max-w-1/2">
              <button class="flex flex-row items-center focus:outline-none">
                <p class="text-sm-2 text-grey-light mr-0.4">FIRST NAME</p>
                <img
                  src="../../../assets/img/delta-table/caret.svg"
                  class="transition-all"
                  :class="
                    rotationMatrix[0] !== 1 ? 'transform rotate-180' : null
                  "
                />
              </button>
            </div>
            <div class="flex flex-grow max-w-1/4">
              <button class="flex flex-row items-center focus:outline-none ">
                <p class="text-sm-2 text-grey-light mr-0.4">LAST NAME</p>
                <img
                  src="../../../assets/img/delta-table/caret.svg"
                  class="transition-all"
                  :class="
                    rotationMatrix[1] !== 1 ? 'transform rotate-180' : null
                  "
                />
              </button>
            </div>
            <div class="flex flex-grow max-w-1/4">
              <button class="flex flex-row items-center focus:outline-none">
                <p class="text-sm-2 text-grey-light mr-0.4">EMAIL</p>
                <img
                  src="../../../assets/img/delta-table/caret.svg"
                  class="transition-all"
                  :class="
                    rotationMatrix[2] !== 1 ? 'transform rotate-180' : null
                  "
                />
              </button>
            </div>
          </div>
          <div>
            <VirtualList
              style="max-height: 360px; overflow-y: auto;"
              :data-key="'FirstName'"
              :data-sources="mappedList"
              :data-component="itemComponent"
            />
          </div>
        </div>

        <div class="flex flex-row flex-grow justify-end mt-4">
          <Button
            type="tertiary"
            size="medium"
            text="Close"
            @click.native="closeModal"
            class="mr-2"
          />
          <Button
            text="Save"
            type="primary"
            size="medium"
            @click.native="close"
          />
        </div>
      </div>
    </div>
    <div
      class="w-full h-full fixed z-30 bottom-0 bg-grey-dark-1 opacity-70 modal transition-all"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ListItem from "./ListItem";
import VirtualList from "vue-virtual-scroll-list";
import InputField from "../../../components/InputField/InputField.vue";
export default {
  name: "CreateGroup",
  components: { VirtualList,InputField },
  data: () => ({
    mappedList: [],
    rotationMatrix: [1, 1, 1],
    itemComponent: ListItem,
  }),
  mounted() {
    let map = this.users.map((item, idx) => ({
      ...item,
      index: idx,
    }));
    this.mappedList = [...map];
  },
  computed: {
    ...mapState({
      users: (state) => state.assessmentSetup.userPreviewList,
    }),
  },
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
    }),
    close() {
      this.closeModal();
    },
  },
};
</script>
<style lang="scss" scoped>
.modal {
  top: 0;
}
</style>
