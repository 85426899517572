<template>
  <div
    v-show="assessments.length > 0"
    class="flex flex-row mt-4 overflow-y-scroll items-center"
    :id="`assessmentContainer${name}`"
  >
    <button
      class="buttonShadow w-6 h-6 rounded-full bg-white flex justify-center items-center transform -translate-x-1/2 absolute z-50 hover:bg-grey-light-3 active:bg-grey-faded-3"
      id="scrollLeft"
      v-if="canScrollLeft"
      @click="left"
    >
      <img
        src="@/assets/img/icons/arrow-right.svg"
        class="w-3 h-3 transform rotate-180 -translate-x-0.2"
      />
    </button>
    <div
      v-for="assessment in assessments"
      :key="assessment.id"
      class="flex"
      id="cards"
    >
      <AssessmentCard :assessment="assessment" />
    </div>
    <button
      class="buttonShadow w-6 h-6 rounded-full absolute bg-white flex justify-center items-center right-none z-50  hover:bg-grey-light-3 active:bg-grey-faded-3"
      :style="`transform: translateX(-${containerPositioning - 24}px);`"
      id="scrollRight"
      v-if="canScrollRight"
      @click="right"
    >
      <img
        src="@/assets/img/icons/arrow-right.svg"
        class="w-3 h-3 transform translate-x-0.2"
      />
    </button>
  </div>
</template>
<script>
import AssessmentCard from "./AssessmentCard";

export default {
  components: { AssessmentCard },
  props: {
    assessments: { default: [], type: Array },
    name: { default: "", type: String },
  },
  data: () => ({
    containerPositioning: 0,
    container: null,
    canScrollLeft: false,
    canScrollRight: false,
    isScrollingLeft: false,
    isScrollingRight: false,
  }),
  methods: {
    getContainerSize() {
      this.containerPositioning = this.container?.getBoundingClientRect().left;
      this.checkScroll();
    },
    checkScroll() {
      this.canScrollLeft = this.container?.scrollLeft !== 0;
      this.canScrollRight =
        274 * this.assessments.length -
          this.container.getBoundingClientRect().width >
        this.container?.scrollLeft;
    },
    left() {
      if (!this.isScrollingRight) {
        this.isScrollingLeft = true;
        let previous = this.container.scrollLeft;
        const i = setInterval(() => {
          this.container.scrollLeft -= 5;
          this.checkScroll();
          if (
            !this.canScrollLeft ||
            this.container.scrollLeft <= previous - 275
          ) {
            clearInterval(i);
            this.isScrollingLeft = false;
          }
        }, 1);
      }
    },
    right() {
      if (!this.isScrollingLeft) {
        this.isScrollingRight = true;
        let previous = this.container.scrollLeft;
        const i = setInterval(() => {
          this.container.scrollLeft += 5;
          this.checkScroll();
          if (
            !this.canScrollRight ||
            this.container.scrollLeft >= previous + 275
          ) {
            clearInterval(i);
            this.isScrollingRight = false;
          }
        }, 1);
      }
    },
  },
  async mounted() {
    this.container = document.getElementById(`assessmentContainer${this.name}`);
    window.addEventListener("resize", this.getContainerSize);
    window.addEventListener("click", this.getContainerSize);
    this.$nextTick(() => {
      this.getContainerSize();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getContainerSize);
  },
};
</script>
<style lang="scss" scoped>
.buttonShadow {
  filter: drop-shadow(0px 4px 16px rgba(0, 82, 149, 0.3));
}
</style>
