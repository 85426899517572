import {
  saveUsers,
  beginSetup,
  saveGeneralInfo,
  updateSurveyParticipants,
  updateJobRoles,
  updatePermissions,
  getIndividualTargets,
  getOrganizationalTargets,
  getCompetencyLevels,
  updateTargets,
  saveDates,
  deleteDraftAssessment,
  getJobRoles,
  getJobRolesForSurvey,
  getUsersFromOrganization,
  getParticipants,
  getGeneralInfo,
  fetchDraftSurveys,
  getSurveyInviteEmailsStatus,
  saveMessages,
  fetchMessages,
  resetTargetsToBenchmarks,
  fetchAvailableSurveys,
  launchSurvey,
  getAvailableLicenses,
  setLastCompletedStep,
  resetSurveyCommunicationMessage,
  deleteCustomMessage,
  getSurveyLaunchAvalabilityInfo,
  getCommunicationLog,
  getSurveyParticipantsWithoutJobRole,
} from "@/services/api/assessmentSetup";
import { invite } from "@/services/api/account";
import _flatten from "lodash/flatten";
import _forEach from "lodash/forEach";
import _uniq from "lodash/uniq";
import Vue from "vue";
import { mergeImportUsersSplitResult, splitArrayIntoBatches } from "../../Companies/store/importUsersUtils";

const getDefaultState = () => ({
  assessmentTitle: null,
  availableSurveys: [],
  cloneId: null,
  userPreviewList: [],
  allUsers: [],
  jobRolesDescriptions: null,
  jobRolesCount: 0,
  jobRolesForSurvey: null,
  targets: null,
  selectedJobRole: "",
  participants: [],
  participantsCount: [],
  possibleParticipants: [],
  possibleParticipantsCount: [],
  generalInfo: null,
  messages: [],
  draftSurveys: [],
  totalUserCount: 0,
  particpantsWithNoAssignedJobRole: 0,
  licenses: 0,
  areTargetsCompleted: false,
  areSettingsCompleted: false,
  isCommunicationCompleted: false,
  currentStep: null,
  publishAvalability: null,
  dirtyCheck: {
    generalInfo: {},
    targets: {},
    communication: {},
  },
  mode: "set-up", //edit-draft / edit-launched / edit-started
  shouldEdit: false,
  participantsDirty: false,
  newUsersAdded: [],
  communicationActivityLog: [],
  uploadUsersText: '',
  uploadedUsersCount: 0,
});

const jobRolesError = (participants) => {
  let errors = 0;
  if (participants.length === 0) return true;
  _forEach(participants, (p) => {
    if (!p.jobRoleId) errors += 1;
  });
  return errors > 0;
};

const actions = {
  resetAssessmentSetupState({ commit }) {
    return new Promise((resolve) => {
      commit("resetState");
      resolve();
    });
  },
  addValidationUsers({ commit, state }, users) {
    const newUsers = [...state.newUsersAdded, ...users];
    commit("SET_NEW_USERS", newUsers);
  },
  clearValidationUsers({ commit }) {
    commit("SET_NEW_USERS", []);
  },
  setFlowMode({ commit }, mode) {
    commit("SET_FLOW_MODE", mode);
  },
  setItemsCompleted({ commit }) {
    commit("SET_TARGETS_COMPLETED");
    commit("SET_SETTINGS_COMPLETED");
    commit("SET_COMMUNICATION_COMPLETED");
  },
  setUserPreviewList({ commit }, { users }) {
    commit("SET_USER_PREVIEW_LIST", users);
  },
  setSettingsCompleted({ commit }) {
    commit("SET_SETTINGS_COMPLETED");
  },
  fetchAvailableSurveys({ commit }) {
    return fetchAvailableSurveys().then((rsp) => {
      commit("SET_AVAILABLE_SURVEYS", rsp.data.data);
    });
  },
  getSurveyParticipantsWithoutJobRolesCount({commit}, id) {
    return getSurveyParticipantsWithoutJobRole(id).then(rsp => {
      commit('SET_PARTICIPANTS_WITH_NO_JOB_ROLE_COUNT', rsp.data.data)
      return rsp.data.data;
    });
  },
  launchSurvey({ dispatch }, { id }) {
    const organizationId = this.getters["auth/isSeller"];
    return launchSurvey(id, organizationId)
      .then(() => {
        dispatch(
          "alerts/showSuccess",
          {
            messageHeader: "Assessment Setup Complete",
            message: "You have successfully set up your assessment.",
          },
          { root: true }
        );

        const checkInviteEmailsStatus = () => {
          getSurveyInviteEmailsStatus(id).then(res => {
            if(res.data.data.finished) {
              if(res.data.data.errors.length) {
                dispatch(
                  "alerts/showError",
                  {
                    messageHeader: "Error sending assessment invites",
                    message: "Error encountered while sending the assessemnt invites.",
                  },
                  { root: true }
                );
              }
            } else {
              setTimeout(checkInviteEmailsStatus, 3000)
            }
          }).catch((err) => {
            console.log(err)
            setTimeout(checkInviteEmailsStatus, 3000)
          } )
        }
        checkInviteEmailsStatus();
        
        return true;
      })
      .catch((err) => {
        dispatch(
          "alerts/showError",
          {
            messageHeader: "Error",
            message: err.message || `Error launching assessment. Please try again in a few moments`,
          },
          { root: true }
        );
        return false;
      });
  },
  resetSurveyCommunicationMessage({ commit, dispatch, state }, { messageId }) {
    const organizationId =
      this.getters["home/getRole"] === "seller" &&
      this.getters["auth/getOrganizationId"];

    return resetSurveyCommunicationMessage(
      state.cloneId,
      messageId,
      organizationId
    )
      .then(async (rsp) => await dispatch("getMessages", { id: state.cloneId }))
  },
  deleteCustomMessage({ commit, state }, { messageId }) {
    const organizationId = this.getters["auth/isSeller"];

    return deleteCustomMessage(state.cloneId, messageId, organizationId)
      .then(() => {
        const filteredMessages = state.messages.filter(
          (item) => item.id !== messageId
        );
        commit("SET_MESSAGES", filteredMessages);
      })
  },
  setAssessmentTitle({ commit }, { title }) {
    commit("SET_ASSESSMENT_TITLE", title);
  },
  setMessage({ commit }, { index, key, value }) {
    commit("SET_MESSAGE", { index, key, value });
  },
  addNewMessage({ commit }, message) {
    commit("ADD_NEW_MESSAGE", message);
  },
  getMessages({ commit }, { id }) {
    const organizationId = this.getters["auth/isSeller"];
    return fetchMessages(id, organizationId).then((rsp) => {
      let trimmed = rsp.data.data.filter((el) => el.surveyMessageType < 5);

      commit("SET_MESSAGES", trimmed);
      let values = trimmed.map((item) => ({
        message: item.message,
        audience: item.messageAudience,
        frequency: item.messageFrequency,
        sendDate: item.sendDate,
        subject: item.subject,
        nrDays: item.nrDays,
      }));
      commit("SET_DIRTY_MESSAGES", values);
    });
  },
  setMessages({ commit }, { messages }) {
    commit("SET_MESSAGES", messages);
  },
  saveMessages({ commit, dispatch }, { id, messages, updateStep, surveyType }) {
    let currentStep = 6;
    if (surveyType === 0) currentStep = 6;
    if (surveyType === 1) currentStep = 5;
    const organizationId = this.getters["auth/isSeller"];
    return saveMessages(id, messages, organizationId).then(async () => {
      commit("SET_MESSAGES", messages);
      if (updateStep) {
        const organizationId = this.getters["auth/isSeller"];
        await setLastCompletedStep(id, currentStep, false, organizationId);
        commit("SET_COMMUNICATION_COMPLETED");
        dispatch("getGeneralInfo", { id });
      }
    });
  },
  getGeneralInfo({ commit }, { id }) {
    return getGeneralInfo(id).then((rsp) => {
      // Getting last updated step in order
      // to be able to enable steps in sidenav
      // 1 = General Info; 2 = Partcipants; 3 = Job roles;
      // 4 = Targets; 5 = Settings; 6 = Communication
      // if 6 is the last step it means summary is also enabled

      commit("SET_CURRENT_STEP", rsp.data.data.lastUpdatedStep);
      commit("SET_GENERAL_INFO", rsp.data.data);
      commit("SET_DIRTY_GENERAL_INFO", rsp.data.data);
      commit("SET_ASSESMENT_TITLE", rsp.data.data.name);
    });
  },
  getSurveyLaunchAvalabilityInfo({ commit }, { id }) {
    return getSurveyLaunchAvalabilityInfo(id).then(rsp => {
      commit("SET_PUBLISH_AVALABILITY", rsp.data.data);
    } );
  },
  setCurrentStep({ commit }, step) {
    commit("SET_CURRENT_STEP", step);
  },
  inviteUser({ dispatch }, { payload }) {
    return invite(payload)
      .then(() => {
        dispatch(
          "alerts/showSuccess",
          {
            messageHeader: "User Added",
            message: "The new user has been successfully added.",
          },
          { root: true }
        );
      })
      .catch(() => {
        dispatch(
          "alerts/showError",
          {
            messageHeader: "User not invited",
            message: "The user is already registered",
          },
          { root: true }
        );
      });
  },
  resetUsersUploadText({commit}) {
    commit('RESET_UPLOAD_USERS_TEXT')
  },
  saveUsers({ dispatch, commit }, { users }) {
    const organizationId = this.getters["auth/isSeller"];
    commit('RESET_UPLOAD_USERS_TEXT')
    const batches = splitArrayIntoBatches(users, Math.ceil(Math.min(30, users.length / 10)))
    commit('UPDATE_UPLOAD_USERS_USERS_TEXT', {total: users.length, count: 0})
    
  return new Promise(async (resolve) => {
        const results = await Promise.all(
          batches.map(x => saveUsers(x, organizationId).then((res) =>   {
            commit('UPDATE_UPLOAD_USERS_USERS_TEXT', {total: users.length, count: x.length})
            return res;
          }))
        )
        const mergeResult = mergeImportUsersSplitResult(results)
        resolve(mergeResult)
      }).then((responseData) => {
        if (responseData.failedUsersCount > 0) {
          var failedUsersMessages = responseData.failedUsersMessages;
          const allreadyTakenEmailCount = failedUsersMessages.filter(x => x.includes("is already taken")).length;
          const wrongFormatCount = failedUsersMessages.filter(x => x.includes("Email cannot contain")).length;
            if(allreadyTakenEmailCount) {
              dispatch(
                'alerts/showInfo',
                {
                  messageHeader: 'Some Users Were Not Imported',
                  message:
                    `There were ${allreadyTakenEmailCount} email addresses in the file that already exist in our data base. Those users have not been imported.`,
                },
                { root: true }
              );
          }
          if(wrongFormatCount) {
            dispatch(
              "alerts/showInfo",
              {
                messageHeader: "Some Users Were Not Allowed",
                message:
                  `There were ${wrongFormatCount} email addresses that contain characters that are not allowed. Those users have not been imported.`,
              },
              { root: true }
            );
          }
        }
        if (responseData.importedUsersCount > 0) {
          dispatch(
            "alerts/showSuccess",
            {
              messageHeader: "Users Added",
              message: "The new users have been successfully added.",
            },
            { root: true }
          );
        }
        commit('RESET_UPLOAD_USERS_TEXT')
      })
      .catch((err) => {
        dispatch(
          "alerts/showError",
          {
            messageHeader: "Users Upload Error",
            message: err.message,
          },
          { root: true }
        );
        commit('RESET_UPLOAD_USERS_TEXT')
      });
  },

  getJobRolesDescription({ commit }, payload) {
    return getJobRoles(payload).then((rsp) => {
      commit("SET_JOB_ROLES_DESCRIPTION", rsp.data.data);
      commit("SET_JOB_ROLES_COUNT", rsp.data.totalCount);
    });
  },
  getJobRolesForSurvey({ commit }, id) {
    return getJobRolesForSurvey(id).then((rsp) => {
      commit("SET_JOB_ROLES_FOR_SURVEY", rsp.data.data);
    });
  },
  getJobRolesForSurvey({ commit }, id) {
    return getJobRolesForSurvey(id).then((rsp) => {
      commit("SET_JOB_ROLES_FOR_SURVEY", rsp.data.data);
    });
  },
  continueDraft({ commit, dispatch }, { id }) {
    dispatch("setItemsCompleted");
    return commit("SET_CLONE_ID", id);
  },
  beginSetup({ commit }, { id }) {
    return beginSetup(id).then((rsp) => {
      const info = rsp.data.data;
      commit("SET_ASSESSMENT_TITLE", info.name);
      commit("SET_CLONE_ID", info.id);
    });
  },
  saveGeneralInfo(
    { commit },
    {
      id,
      name,
      hasRandomisedQuestions,
      allowInMultipleSessions,
      allowGoingBack,
      resultsPermission,
      restrictManagersResults,
      maxTimeAllocation,
    }
  ) {
    const organizationId = this.getters["auth/isSeller"];
    return saveGeneralInfo(
      id,
      name,
      hasRandomisedQuestions,
      allowInMultipleSessions,
      allowGoingBack,
      resultsPermission,
      organizationId,
      restrictManagersResults,
      maxTimeAllocation,
    )
      .then(async (rsp) => {
        const organizationId = this.getters["auth/isSeller"];
        await setLastCompletedStep(id, 1, false, organizationId);
        commit("SET_ASSESSMENT_TITLE", rsp.data.data.name);
        return rsp;
      })
      .catch((err) => {
        console.log(err)
        throw err;
      });
  },
  saveDates({ commit }, { id, openingTime, closingTime, timezone }) {
    const organizationId = this.getters["auth/isSeller"];
    return saveDates(
      id,
      openingTime,
      closingTime,
      timezone,
      organizationId
    ).then((rsp) => commit("SET_GENERAL_INFO", rsp.data.data));
  },

  getAvailableLicenses({ commit }) {
    const organizationId = this.getters["auth/isSeller"];
    return getAvailableLicenses(organizationId).then((rsp) => {
      commit("SET_LICENSES", rsp.data.data);
    });
  },
  getUsersFromOrganizationCount(
    { commit },
    { id, pageNr, pageSz, sorting }
  ) {
    return getUsersFromOrganization(id, pageNr, pageSz, '', sorting).then(
      (rsp) => {
          commit("SET_TOTAL_USER_COUNT", rsp.data.totalCount);
      }
    );
  },
  getPossibleSurveyParticipants(
    { commit },
    { id, pageNr, pageSz, keyword, sorting }
  ) {
    commit("SET_POSSIBLE_PARTICIPANTS", []);
    return getUsersFromOrganization(id, pageNr, pageSz, keyword, sorting, true).then(
      (rsp) => {
        commit("SET_POSSIBLE_PARTICIPANTS", rsp.data.data);
        commit("SET_POSSIBLE_PARTICIPANTS_COUNT", rsp.data.totalCount);
      });
  },
  resetTargetsToBenchmarks({ dispatch }, { id, surveyType }) {
    return resetTargetsToBenchmarks(id).then((rsp) => {
      if (surveyType === 0) dispatch("getIndividualTargets", { id });
      if (surveyType === 1) dispatch("getOrganizationalTargets", { id });
    });
  },
  getParticipants({ commit }, { id, pageNr, pageSz, keyword, sorting }) {
    const organizationId = this.getters["auth/isSeller"];
    return getParticipants(
      id,
      pageNr,
      pageSz,
      keyword,
      sorting,
      organizationId
    ).then((rsp) => {
      commit("SET_PARTICIPANTS", rsp.data.data);
      commit("SET_PARTICIPANTS_COUNT", rsp.data.totalCount)
    });
  },
  setDirty({ commit }, { key, value }) {
    commit("CHANGE_DIRTY", { key, value });
  },
  updateSurveyParticipants({ commit, dispatch }, { id, users, type }) {
    const organizationId = this.getters["auth/isSeller"];
    return updateSurveyParticipants(id, users, organizationId, type).then(async () => {
      commit("CHANGE_DIRTY", { key: "participantsDirty", value: true });
      await setLastCompletedStep(id, 2, true, organizationId);
      dispatch("getGeneralInfo", { id });
    }).catch((err) => {
      dispatch(
        "alerts/showError",
        {
          messageHeader: "Error",
          message: err.message || 'An error occured while adding the participants. Pleae try again in a few moments',
        },
        { root: true }
      );
      return false;
    });;
  },
  updateJobRoles({ dispatch, commit }, { id, jobRoles }) {
    return updateJobRoles(id, jobRoles).then(async () => {
      const organizationId = this.getters["auth/isSeller"];
      await setLastCompletedStep(id, 3, false, organizationId);
      commit('UPDATE_PARTICIPANTS_JOB_ROLES', jobRoles)
      dispatch("getGeneralInfo", { id });
    });
  },
  updatePermissions({ dispatch }, { id, permissions, surveyType }) {
    let currentStep = 5;
    if (surveyType === 0) currentStep = 5;
    if (surveyType === 1) currentStep = 4;
    return updatePermissions(id, permissions).then(async () => {
      const organizationId = this.getters["auth/isSeller"];
      await setLastCompletedStep(id, currentStep, false, organizationId);
      dispatch("getGeneralInfo", { id });
    });
  },
  getIndividualTargets({ commit }, { id }) {
    return getIndividualTargets(id).then((rsp) => {
      commit("SET_TARGETS", rsp.data.data);
      let values = rsp.data.data.map((item) =>
        item.targets.map((i) => i.value)
      );
      commit("SET_DIRTY_TARGETS", [...values]);
    });
  },
  getCompetencyLevels({ commit }, { id }) {
    return getCompetencyLevels(id).then((rsp) => {
      let values = rsp.data.data.map((item) => { return { score: item.score,  name: item.name }});
      commit("SET_COMPETENCY_LEVELS", values);
    });
  },
  getOrganizationalTargets({ commit }, { id }) {
    return getOrganizationalTargets(id).then((rsp) => {
      commit("SET_TARGETS", rsp.data.data);
      let values = rsp.data.data.map((item) =>
        item.targets.map((i) => i.value)
      );
      commit("SET_DIRTY_TARGETS", [...values]);
    });
  },
  updateTargets({ commit, dispatch }, { id, targets, surveyType }) {
    let currentStep = 4;
    if (surveyType === 0) currentStep = 4;
    if (surveyType === 1) currentStep = 3;
    let values = targets.map((item) => item.targets.map((i) => i.value));
    commit("SET_DIRTY_TARGETS", [...values]);
    return updateTargets(id, targets).then(async () => {
      const organizationId = this.getters["auth/isSeller"];
      await setLastCompletedStep(id, currentStep, false, organizationId);
      commit("SET_TARGETS_COMPLETED");
      dispatch("getGeneralInfo", { id });
    });
  },
  getDrafts({ commit }) {
    return fetchDraftSurveys().then((rsp) => {
      commit("SET_DRAFT_SURVEYS", rsp.data.data);
    });
  },
  selectJobRole({ commit }, { jobRole }) {
    commit("SET_SELECTED_JOB_ROLE", jobRole);
  },
  setShouldEdit({ commit }, shouldEdit) {
    commit("SET_SHOULD_EDIT", shouldEdit);
  },
  deleteDraftAssessment({ dispatch }, { id }) {
    return deleteDraftAssessment(id)
      .then(() => {
        dispatch(
          "alerts/showSuccess",
          {
            messageHeader: "Draft deleted",
            message: "You have successfully deleted the draft assessment.",
          },
          { root: true }
        );
      })
      .catch((err) => {
        let displayedErrorMessage = "Something went wrong. Please try again."
        try {
          const errorMessage = JSON.parse(err.message);
          displayedErrorMessage = errorMessage[0];
        } catch (err) { }

        dispatch(
          "alerts/showError",
          {
            messageHeader: 'Deleting draft Error',
            message: displayedErrorMessage
          },
          { root: true }
        );
      });
  },
  getCommunicationActivityLog({ commit, dispatch }, { id, keyword }) {
    return getCommunicationLog(id, keyword)
      .then((rps) => {
        commit("SET_COMMUNICATION_ACTIVITY_LOG", rps.data.data.map(x => ({
          date: x.sentOn,
          email: x.userEmail,
          subject: x.messageSubject,
        })))
      })
      .catch((err) => {
        dispatch(
          "alerts/showError",
          {
            messageHeader: 'Error occured while getting communication history',
            message: err.message
          },
          { root: true }
        );
      })
  }
};

const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  SET_NEW_USERS(state, users) {
    Vue.set(state, "newUsersAdded", users);
  },
  SET_LICENSES(state, payload) {
    state.licenses = payload;
  },
  SET_SHOULD_EDIT(state, payload) {
    state.shouldEdit = payload;
  },
  SET_AVAILABLE_SURVEYS(state, payload) {
    state.availableSurveys = payload;
  },
  SET_TOTAL_USER_COUNT(state, payload) {
    state.totalUserCount = payload;
  },
  SET_MESSAGES(state, payload) {
    state.messages = payload;
  },
  SET_MESSAGE(state, payload) {
    state.messages[payload.index][payload.key] = payload.value;
  },
  ADD_NEW_MESSAGE(state, payload) {
    state.messages.push(payload);
    state.dirtyCheck.communication.push({
      message: payload.message,
      audience: payload.messageAudience,
      frequency: payload.messageFrequency,
      sendDate: payload.sendDate,
      subject: payload.subject,
    });
  },
  SET_SELECTED_JOB_ROLE(state, payload) {
    state.selectedJobRole = payload;
  },
  SET_GENERAL_INFO(state, payload) {
    state.generalInfo = payload;
  },
  SET_ASSESMENT_TITLE(state, payload) {
    state.assessmentTitle = payload;
  },
  SET_PARTICIPANTS(state, payload) {
    state.participants = payload;
  },
  UPDATE_PARTICIPANTS_JOB_ROLES(state, payload) {
    state.participants.forEach(participant => {
      let newJobRoleId = payload.filter(x => x.userId === participant.id)[0]?.jobRoleId;
      let newJobRole = state.jobRolesForSurvey.filter(x => x.id === newJobRoleId)[0];
      if(!newJobRole) {
        return;
      }
      participant.jobRoleId = newJobRole.id;
      participant.jobRoleName = newJobRole.name;
      participant.jobRoleDescription = newJobRole.description;
    })
  },
  SET_PARTICIPANTS_COUNT(state, payload) {
    state.participantsCount = payload;
  },
  SET_POSSIBLE_PARTICIPANTS(state, payload) {
    state.possibleParticipants = payload;
  },
  SET_POSSIBLE_PARTICIPANTS_COUNT(state, payload) {
    state.possibleParticipantsCount = payload;
  },
  SET_ALL_USERS(state, payload) {
    state.allUsers = payload;
  },
  SET_CLONE_ID(state, payload) {
    state.cloneId = payload;
  },
  SET_FLOW_MODE(state, mode) {
    state.mode = mode;
  },
  SET_DRAFT_SURVEYS(state, drafts) {
    state.draftSurveys = drafts;
  },
  CHANGE_DIRTY(state, { key, value }) {
    state[key] = value;
  },
  SET_ASSESSMENT_TITLE(state, payload) {
    state.assessmentTitle = payload;
  },
  SET_USER_PREVIEW_LIST(state, payload) {
    state.userPreviewList = payload;
  },
  SET_TARGETS(state, payload) {
    state.targets = payload;
  },
  SET_TARGETS_COMPLETED(state) {
    state.areTargetsCompleted = true;
  },
  SET_COMPETENCY_LEVELS(state, payload) {
    state.competencyLevels = payload;
  },
  SET_SETTINGS_COMPLETED(state) {
    state.areSettingsCompleted = true;
  },
  SET_COMMUNICATION_COMPLETED(state) {
    state.isCommunicationCompleted = true;
  },
  SET_JOB_ROLES_DESCRIPTION(state, payload) {
    state.jobRolesDescriptions = payload;
  },
  SET_JOB_ROLES_COUNT(state, payload) {
    state.jobRolesCount = payload;
  },
  SET_JOB_ROLES_FOR_SURVEY(state, payload) {
    state.jobRolesForSurvey = payload;
  },
  SET_CURRENT_STEP(state, step) {
    state.currentStep = step;
  },
  SET_PUBLISH_AVALABILITY(state, payload) {
    state.publishAvalability = payload;
  },
  SET_DIRTY_GENERAL_INFO(state, payload) {
    state.dirtyCheck.generalInfo = payload;
  },
  SET_DIRTY_TARGETS(state, payload) {
    state.dirtyCheck.targets = [...payload];
  },
  SET_DIRTY_MESSAGES(state, payload) {
    state.dirtyCheck.communication = payload;
  },
  SET_COMMUNICATION_ACTIVITY_LOG(state, payload) {
    state.communicationActivityLog = payload;
  },
  RESET_UPLOAD_USERS_TEXT(state) {
    state.uploadedUsersCount = 0
    state.uploadUsersText = ``;
  },
  UPDATE_UPLOAD_USERS_USERS_TEXT(state, payload) {
    state.uploadedUsersCount += payload.count
    state.uploadUsersText = `Uploading users...(${state.uploadedUsersCount}/${payload.total})`;
  },
  SET_PARTICIPANTS_WITH_NO_JOB_ROLE_COUNT(state, payload) {
    state.particpantsWithNoAssignedJobRole = payload;
  }
};

const getters = {
  getAssessmentTitle: (state) => state.assessmentTitle,
  getAssignedJobRolesIds: (state) => {
    const roles = state.participants.map((el) => el.jobRoleId);
    return _uniq(roles);
  },
  hasGeneralInfoError: (state) => state.assessmentTitle === "",
  hasParticipantsError: (state) => state.participants.length === 0,
  hasJobRolesError: (state) => jobRolesError(state.participants),
  isEdit: (state) => !!state.currentStep,
  areNewParticipants: (state) => {
    const participantsIds = state.participants.map(
      (participant) => participant.id
    );
    const thereAreNew = participantsIds.some(
      (participantId) => state.newUsersAdded.indexOf(participantId) !== -1
    );
    return thereAreNew;
  },
  disabledItems: (state, getters) => {
    return {
      generalInformation: getters.hasGeneralInfoError,
      participants: getters.hasParticipantsError,
      jobRoles: getters.hasJobRolesError,
      targets: getters.hasJobRolesError,
      settings: getters.hasJobRolesError || !state.areTargetsCompleted,
      communication:
        getters.hasJobRolesError ||
        !state.areTargetsCompleted ||
        !state.areSettingsCompleted,
      summary:
        getters.hasGeneralInfoError ||
        getters.hasParticipantsError ||
        getters.hasJobRolesError ||
        !state.areTargetsCompleted ||
        !state.isCommunicationCompleted ||
        !state.areSettingsCompleted,
    };
  },
  getDirtyGeneralInfo: (state) => (generalInfo) => {
    var changes = 0;
    Object.keys(generalInfo).map((key) => {
      const found = Object.keys(state.dirtyCheck.generalInfo).find(
        (item) => item === key
      );
      if (generalInfo[key] !== state.dirtyCheck.generalInfo[found]) changes++;
    });
    return changes !== 0;
  },
  getDirtyTargets: (state) => (mappedList) => {
    let changes = 0;
    mappedList.map((item, index) =>
      item.map((i, idx) => {
        if (i != state.dirtyCheck.targets[index][idx]) {
          changes++;
        }
      })
    );
    return changes !== 0;
  },
  getDirtyCommunication: (state) => (mappedList) => {
    let changes = 0;
    if (mappedList.length !== state.dirtyCheck.communication.length)
      return true;
    else {
      for (let i = 0; i < mappedList.length; i++) {
        if (mappedList[i].message !== state.dirtyCheck.communication[i].message)
          changes++;
        if (
          mappedList[i].messageAudience !==
          state.dirtyCheck.communication[i].audience
        )
          changes++;
        if (
          mappedList[i].messageFrequency !==
          state.dirtyCheck.communication[i].frequency
        )
          changes++;
        if (
          mappedList[i].sendDate.toString().slice(0, 10) !==
          state.dirtyCheck.communication[i].sendDate.toString().slice(0, 10)
        )
          changes++;
        if (mappedList[i].subject !== state.dirtyCheck.communication[i].subject)
          changes++;
      }
    }
    return changes !== 0;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
