<template>
  <Modal :data="$data" :isVisible="true">
    <p class="font-main text-md text-blue-dark-4 mb-5.5">
      Are you sure you want to delete this draft assessment?
    </p>
    <div class="flex flex-row flex-grow justify-end">
      <Button type="tertiary" size="medium" text="Close" @click.native="closeModal" class="mr-2" />
      <Button type="danger" text="Delete" size="medium" @click.native="deleteDraft" class="px-5.5" />
    </div>
  </Modal>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "DeleteDraftAssessmentModal",
  props: ["id"],
  data: () => ({
    title: "Delete Draft",
    content:
      "Deleting this draft assessment means it will be permanently removed from the platform.",
  }),
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      getDrafts: "assessmentSetup/getDrafts",
      deleteDraftAssessment: "assessmentSetup/deleteDraftAssessment"
    }),
    deleteDraft() {
      this.deleteDraftAssessment({ id: this.id }).then(() =>
        this.getDrafts()
      );
      this.closeModal();
    },
  },
};
</script>
