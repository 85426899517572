<template>
  <div>
    <div
      class="w-full h-full fixed z-40 flex flex-col justify-center items-center modal"
    >
      <div class="w-jobRolesModal bg-white p-4 shadow overflow-hidden rounded">
        <p class="font-barlow font-bold text-grey-dark-2 text-l mb-1.5">
          Assign Job Role
        </p>
        <p class="text-mdh text-grey-dark-2 mb-2 max-w-3/4">
          Based on the Job Title or the employee responsibilities, please assign
          a Job Role from the list below. If you do not see an appropriate Job
          Role, please contact ISM at membersvcs@ismworld.org.
        </p>
        <VirtualList
          style="max-height: 360px; overflow-y: auto;"
          :data-key="'id'"
          :data-sources="mappedList"
          :data-component="itemComponent"
          :extra-props="{ toggle: toggleItem }"
          data-testid="job-descriptions-table"
        />
        <div class="flex flex-row flex-grow justify-end mt-4">
          <Button
            type="tertiary"
            size="medium"
            text="Close"
            @click.native="closeModal"
            class="mr-2"
            data-testid="close-button"
          />
          <Button
            text="Accept"
            type="primary"
            size="medium"
            :disabled="disableAccept"
            @click.native="close"
            data-testid="accept-button"
          />
        </div>
      </div>
    </div>
    <div
      class="w-full h-full fixed z-30 bottom-0 bg-grey-dark-1 opacity-70 modal transition-all"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import VirtualList from 'vue-virtual-scroll-list';
import JobRoleItem from './JobRoleItem';
export default {
  name: 'JobRoleModal',
  components: { VirtualList },
  props: ["jobRole"],
  data: () => ({
    mappedList: [],
    itemComponent: JobRoleItem,
  }),
  computed: {
    ...mapState({
      jobRoles: (state) => state.assessmentSetup.jobRolesForSurvey,
    }),
    disableAccept() {
      return this.mappedList.filter((item) => item.isSelected).length === 0;
    },
  },
  mounted() {
    this.mappedList = this.jobRoles
      .filter((item) => item.isActive)
      .map((item) => ({
        ...item,
        isSelected: this.jobRole === item.name,
      }));
  },
  methods: {
    ...mapActions({
      closeModal: 'modals/closeModal',
      selectJobRole: 'assessmentSetup/selectJobRole',
    }),
    toggleItem(index) {
      this.mappedList.map(
        (item, idx) =>
          (item.isSelected = idx !== index ? false : !item.isSelected)
      );
    },
    close() {
      const selected = this.mappedList.filter((item) => item.isSelected);
      this.closeModal();
      this.selectJobRole({ jobRole: selected[0] });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  top: 0;
}
</style>
