<template>
  <div>
    <h1
      class="text-xl text-grey-light font-medium font-headers mb-3 font-bold text-grey-dark-3"
      data-testid="title"
    >
      Companies
    </h1>
    <div class="bg-white shadow p-3 rounded">
      <CompaniesTable />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CompaniesTable from "../components/CompaniesTable/index";
export default {
  name: "CompaniesView",
  components: { CompaniesTable },
  methods: {
    ...mapActions({
      getIndustries: "companies/getIndustries",
    }),
  },
  mounted() {
    this.getIndustries();
  },
};
</script>

<style></style>
