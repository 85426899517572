<template>
  <div>
    <div v-for="team in sortedData" :key="team.teamId">
      <div class="py-1 px-4">
        <p class="text-wrap text-md text-grey-light-2">{{ title(team) }}</p>
      </div>
      <router-link
        v-for="survey in team.surveys"
        :key="`${survey.id} + ${team.teamId}`"
        :to="link(survey)"
        class="py-2 relative flex items-center cursor-pointer pl-6 pr-2"
      >
        <p class="max-w-200 text-grey-light text-md font-semibold ">
          {{ survey.name }}
        </p>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActiveAssessmentsSidenav",
  props: {
    data: {
      type: Array,
      default: [],
    },
    type: {
      type: String,
      default: "manager",
    },
  },
  computed: {
    sortedData() {
      const sortedArray = (this.data || []).map(item => {
        const newItem = { ...item };
        newItem.surveys.sort(this.sortSurveys);
        return newItem;
      });
      return sortedArray;
    }
  },
  methods: {
    link(assessment) {
      return this.type === "manager"
        ? `/team-results/${assessment.id}/${assessment.teamId}`
        : `/company-results/${assessment.id}`;
    },
    title(data) {
      return this.type === "manager" ? data.teamName : "Active Assessments";
    },
    sortSurveys(a, b) {
      if (a.openingTime !== b.openingTime) {
        return new Date(b.openingTime) - new Date(a.openingTime);
      } else if (a.closingTime !== b.closingTime) {
        return new Date(a.closingTime) - new Date(b.closingTime);
      } else {
        return a.name.localeCompare(b.name);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.router-link-exact-active {
  background-color: #fff;
  color: $blue;
  &:after {
    content: "";
    width: 3px;
    height: 28px;
    top: 25%;
    background-color: $blue;
    position: absolute;
    right: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}
</style>
