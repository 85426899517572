<template>
  <div>
    <component :is="whichHeader" />
    <div
      class="h-impersonateBar bg-orange-light w-full flex flex-row items-center justify-center"
      v-if="isImpersonating"
    >
      <div class="flex-row flex items-center mx-auto space-x-4">
        <p class="text-sm-2 text-orange-dark">
          You are logged in as an <span class="font-semibold">Admin</span> of
          <span class="font-semibold"> {{ getOrganizationName }}.</span>
        </p>
        <p class="text-blue-dark-1 font-semibold text-md pb-0.4 cursor-pointer" @click="goToCompany">
          Exit account
        </p>
      </div>
    </div>
    <div class="h-impersonateBar bg-orange-light w-full flex flex-row items-center justify-center" v-if="isExpiring">
      <div class="flex-row flex items-center mx-auto space-x-4">
        <p class="text-sm-2 text-orange-dark">
          Your subscription ends in <span class="font-semibold">{{ daysRemaining }} days</span>, on
          <span class="font-semibold">{{ formatDate(subDetails.endDate) }}. </span>
          Please contact ISM to activate your subscription.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';
import AssessmentHeader from './AssessmentHeader.vue';
import moment from 'moment';
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  name: 'HeaderWrapper',
  componets: { Header },
  methods: {
    ...mapActions({
      cleanImpersonation: 'auth/cleanImpersonation',
      closeModal: "modals/closeModal",
    }),
    async goToCompany() {
      await this.cleanImpersonation({ toCompany: true });
      if (this.modalVisible && this.modalComponent === "exitAssessmentModal") {
        this.modalProps.save();
        const next = this.modalProps.next;
        this.closeModal();
        next();
      }
    },
    formatDate(date) {
      return moment(date).format('MMM D, YYYY');
    },
  },
  computed: {
    ...mapGetters({
      isImpersonating: 'auth/isImpersonating',
      getOrganizationName: 'auth/getOrganizationName',
      getRole: 'home/getRole',
    }),
    ...mapState({
      subDetails: (state) => state.auth.subDetails,
      modalComponent: (state) => state.modals.modalComponent,
      modalVisible: (state) => state.modals.modalVisible,
      modalProps: (state) => state.modals.props,
    }),
    daysRemaining() {
      const today = moment();
      const endDate = moment(this.subDetails.endDate);
      return endDate.diff(today, 'days');
    },
    isExpiring() {
      if (this.getRole === 'admin' && !this.isImpersonating) {
        return (
          this.subDetails?.subscriptionStatus === 1 && this.daysRemaining <= 14
        );
      }
      return false;
    },
    whichHeader() {
      if (this.$route.meta.assessmentHeader) {
        return AssessmentHeader;
      }
      if (this.$route.meta.noHeader) {
        return null;
      }
      return Header;
    },
  },
};
</script>
