<template>
  <div
    class="w-full border rounded border-grey-light-3 p-2 flex flex-row items-center hover:border-blue-dark-1 cursor-pointer"
    :class="isActive ? 'border-blue-light-5 bg-blue-light-1' : null"
  >
    <div
      v-if="isActive"
      class="w-14 h-14 min-w-14 min-h-14 bg-blue-dark-1 rounded-full flex justify-center items-center"
    >
      <img src="../../assets/img/icons/check.svg" />
    </div>
    <div
      v-else
      class="w-14 h-14 bg-grey-light-1 rounded-full min-w-14 min-h-14"
    />
    <p class="text-md font-normal text-grey-dark-2 ml-2 ow">{{ answer }}</p>
  </div>
</template>

<script>
export default {
  name: "Answer",
  model: { prop: "selectedAnswer", event: "onUpdateAnswer" },
  props: {
    group: { default: null, type: String },
    answer: { default: null, type: String },
    isActive: { default: null, type: Boolean },
  },
};
</script>

<style scoped lang="scss">
.ow {
  overflow-wrap: break-word;
  overflow: hidden;
}
</style>
