<template>
  <div class="pb-4">
    <CompaniesView/>
  </div>
</template>

<script>
import CompaniesView from "../../Companies/views/index";

export default {
  name: "Seller",
  components: { CompaniesView },
};
</script>
