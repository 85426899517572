<template>
  <div>
    <div
      class="w-full h-full fixed z-40 bottom-0 flex justify-center items-center modal"
    >
      <div class="w-modal bg-white p-4 shadow flex-grow-0 rounded">
        <h1 class="font-headers font-bold text-grey-dark-2 text-l mb-1.5">
          Platform User Details
        </h1>
        <div class="flex flex-row w-full justify-between">
          <div class="w-1/2 pr-2">
            <InputField
              placeholder="First name"
              label="First name"
              type="text"
              :propValue="admin.firstName"
              :onChange="(val) => setValue('firstName', val)"
              class="mb-2 mt-3"
              data-testid="first-name-field"
            />
          </div>
          <div class="w-1/2">
            <InputField
              placeholder="Last name"
              label="Last name"
              type="text"
              :propValue="admin.lastName"
              :onChange="(val) => setValue('lastName', val)"
              class="mb-2 mt-3"
              data-testid="last-name-field"
            />
          </div>
        </div>
        <InputField
          placeholder="Email"
          label="Email"
          type="text"
          :disabled="email !== ''"
          :propValue="admin.email"
          :onChange="(val) => setValue('email', val)"
          class="mb-2 mt-2"
          data-testid="email-field"
        />
        <div class="flex flex-row flex-grow justify-end mt-4 space-x-2">
          <Button
            type="tertiary"
            size="medium"
            text="Cancel"
            @click.native="closeModal"
            data-testid="cancel-button"
          />
          <Button
            text="Save"
            type="primary"
            size="medium"
            :disabled="disableSave"
            @click.native="save()"
            data-testid="save-button"
          />
        </div>
      </div>
    </div>
    <div
      class="w-full h-full fixed z-30 bottom-0 bg-grey-dark-1 opacity-70 modal transition-all"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "AddSellersModal",
  props: {
    firstName: {
      type: String,
      default: "",
    },
    lastName: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    admin: {
      firstName: "",
      lastName: "",
      email: "",
    },
    permisions: {
      permisionCheck: false,
      roleCheck: false,
    },
  }),
  mounted() {
    this.admin.firstName = this.firstName;
    this.admin.lastName = this.lastName;
    this.admin.email = this.email;
  },
  computed: {
    disableSave() {
      return !this.admin.firstName || !this.admin.lastName || !this.admin.email;
    },
  },
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      addSellerAdmin: "companies/addSellerAdmin",
    }),
    setValue(field, val) {
      this.admin[field] = val;
    },
    check(permision) {
      this.permisions[permision] = !this.permisions[permision];
    },
    async save() {
      const payload = {
        firstName: this.admin.firstName,
        lastName: this.admin.lastName,
        email: this.admin.email,
        roles: [1],
      };
      await this.addSellerAdmin({ payload });
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  top: 0;
}
</style>
