import { apiCall } from "./api";

export const fetchAssessmentResults = (id, group) => {
  const comp = group === "competency";
  return apiCall(
    "GET",
    `api/v1/Competency/results?SurveyId=${id}&GroupUnderParent=${comp}`
  );
};

export const fetchAssessmentsHistoryResults = (id) =>
  apiCall(
    "GET",
    `api/v1/Competency/history_results?SurveyId=${id}`
  );
