<template>
  <div class="pb-4">
    <p class="font-barlow font-bold text-xl text-grey-dark-2 mb-3">Dashboard</p>
    <InitialTeamCard :teamToReview="teamToReview" />
    <ActiveAssessments type="manager" />
    <div class="flex flex-row">
      <h1 class="text-mdxl font-bold text-grey-light mb-2 w-1/6">
        Closed Assessments
      </h1>
      <div class="w-full h-line bg-grey-light-1 mt-2"></div>
    </div>
    <ClosedAssessments />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import InitialTeamCard from "./InitialTeamCard.vue";
import ActiveAssessments from "./ActiveAssessments.vue";
import ClosedAssessments from "./ClosedAssessments.vue";

export default {
  name: "Manager",
  components: { InitialTeamCard, ActiveAssessments, ClosedAssessments },
  data: () => ({ teamToReview: null }),
  computed: {
    ...mapState({
      assessments: (state) => state.assessments.assessments,
      results: (state) => state.assessments.results,
      position: (state) => state.home.position,
      workplace: (state) => state.home.workplace,
      teams: (state) => state.teams.teams,
    }),
  },
  methods: {
    ...mapActions({
      getTeams: "teams/getTeams",
      getTeamsIds: "teams/getTeamsIds",
      getActiveAssessments: "home/getActiveAssessments",
      getClosedAssessments: "home/getClosedAssessments",
    }),
  },
  async mounted() {
    await this.getTeams();
    await this.getTeamsIds();
    await this.getActiveAssessments();
    await this.getClosedAssessments();
    const found = this.teams.find((item) => item.isReviewed == false);
    this.teamToReview = found ? found.id : null;
  },
};
</script>
