<template>
  <div class="mt-1">
    <router-link
      to="/assessments/general-information"
      class="py-2 relative flex items-center cursor-pointer pl-6 pr-2"
    >
      <p class="max-w-200 text-blue-dark-1 text-md font-semibold">
        {{ stepNumber("generalInfo") }}. General Information
      </p>
    </router-link>
    <router-link
      to="/assessments/participants"
      class="py-2 relative flex items-center cursor-pointer pl-6 pr-2"
      :class="
        this.isDisabled('participants')
          ? 'text-grey-medium-2 pointer-events-none'
          : 'text-blue-dark-1'
      "
    >
      <p class="max-w-200 text-md font-semibold">
        {{ stepNumber("participants") }}. Participants
      </p>
    </router-link>
    <router-link
      v-if="this.assessmentType === 0"
      to="/assessments/job-roles"
      class="py-2 relative flex items-center cursor-pointer pl-6 pr-2"
      :class="
        this.isDisabled('jobRoles')
          ? 'text-grey-medium-2 pointer-events-none'
          : 'text-blue-dark-1'
      "
    >
      <p class="max-w-200 text-md font-semibold">
        {{ stepNumber("jobRoles") }}. Job Roles
      </p>
    </router-link>
    <router-link
      to="/assessments/targets"
      class="py-2 relative flex items-center cursor-pointer pl-6 pr-2"
      :class="
        this.isDisabled('targets')
          ? 'text-grey-medium-2 pointer-events-none'
          : 'text-blue-dark-1'
      "
    >
      <p class="max-w-200 text-md font-semibold">
        {{ stepNumber("targets") }}. Targets
      </p>
    </router-link>
    <router-link
      to="/assessments/settings"
      class="py-2 relative flex items-center cursor-pointer pl-6 pr-2"
      :class="
        this.isDisabled('settings')
          ? 'text-grey-medium-2 pointer-events-none'
          : 'text-blue-dark-1'
      "
    >
      <p class="max-w-200 text-md font-semibold">
        {{ stepNumber("settings") }}. Settings
      </p>
    </router-link>
    <router-link
      to="/assessments/communication"
      class="py-2 relative flex items-center cursor-pointer pl-6 pr-2"
      :class="
        this.isDisabled('communication')
          ? 'text-grey-medium-2 pointer-events-none'
          : 'text-blue-dark-1'
      "
    >
      <p class="max-w-200 text-md font-semibold">
        {{ stepNumber("communication") }}. Communication
      </p>
    </router-link>
    <router-link
      to="/assessments/summary"
      class="py-2 relative flex items-center cursor-pointer pl-6 pr-2"
      :class="
        this.isDisabled('summary')
          ? 'text-grey-medium-2 pointer-events-none'
          : 'text-blue-dark-1'
      "
    >
      <p class="max-w-200 text-md font-semibold">
        {{ stepNumber("summary") }}. Summary
      </p>
    </router-link>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "NewAssessmentSidenav",
  data: () => ({
    individualSteps: [
      "generalInfo",
      "participants",
      "jobRoles",
      "targets",
      "settings",
      "communication",
      "summary",
    ],
    organizationalSteps: [
      "generalInfo",
      "participants",
      "targets",
      "settings",
      "communication",
      "summary",
    ],
  }),
  computed: {
    ...mapState({
      currentStep: (state) => state.assessmentSetup.currentStep,
      generalInfo: (state) => state.assessmentSetup.generalInfo,
    }),
    ...mapGetters({
      disabledItems: "assessmentSetup/disabledItems",
      isEdit: "assessmentSetup/isEdit",
    }),
    assessmentType() {
      return this.generalInfo?.surveyType;
    },
    steps() {
      if (this.assessmentType === 0) return this.individualSteps;
      if (this.assessmentType === 1) return this.organizationalSteps;
    },
  },
  methods: {
    isDisabled(item) {
      // check data steps for items
      if (this.isEdit) {
        return !this.steps.slice(0, this.currentStep + 1).includes(item);
      }
      return this.disabledItems[item];
    },
    stepNumber(key) {
      return this.steps?.indexOf(key) + 1;
    },
  },
};
</script>
<style lang="scss" scoped>
.router-link-exact-active {
  background-color: #fff;
  p {
    color: $blue;
  }
  &:after {
    content: "";
    width: 3px;
    height: 28px;
    top: 25%;
    background-color: $blue;
    position: absolute;
    right: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .icon {
    background-color: $sidenav-icon-active 20%;
  }
}
</style>
