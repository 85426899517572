/* eslint-disable */
import axios from "axios";
import store from "@/store";
import { tokenHandler } from "../utils/tokenHandler.js";

export const apiCall = (method, url, data, hideLoader = false) => {
  const token = tokenHandler();
  if (!token) return null;

  if(!hideLoader) {
    store.dispatch("calls/beginAjaxCall");
  }

  return axios({
    method,
    url: `/${url}`,
    headers: { Authorization: `bearer ${token}` },
    data,
  })
    .then((data) => {
      if(!hideLoader) {
        store.dispatch("calls/endAjaxCall");
      }
      return data;
    })
    .catch((err) => {
      if(!hideLoader) {
        store.dispatch("calls/endAjaxCall");
      }
      if (err.response?.data.Errors) {
        const errors = JSON.stringify(err.response?.data.Errors);
        throw new Error(errors);
      } else {
        throw new Error(err.response.data.Message);
      }
    });
};

export const apiCallBlob = (method, url, data) => {
  const token = tokenHandler();
  if (!token) return null;

  store.dispatch("calls/beginAjaxCall");

  return axios({
    method,
    url: `/${url}`,
    headers: { Authorization: `bearer ${token}` },
    responseType: "blob",
    data,
  })
    .then((data) => {
      store.dispatch("calls/endAjaxCall");
      return data;
    })
    .catch((err) => {
      store.dispatch("calls/endAjaxCall");
      return err;
    });
};

export const genericApiCall = (method, url, data) => {
  store.dispatch("calls/beginAjaxCall");

  return axios({
    method,
    url: `/${url}`,
    data,
  })
    .then((data) => {
      store.dispatch("calls/endAjaxCall");
      return data;
    })
    .catch((err) => {
      store.dispatch("calls/endAjaxCall");
      if (err.response?.data.Errors) {
        const errors = JSON.stringify(err.response?.data.Errors);
        throw new Error(errors);
      } else {
        throw new Error(err.response.data.Message);
      }
    });
};

// USAGE

// Simple GET request
// const getElements = () => apiCall("GET", "/elements");

// Simple POST request
// const postElement = payload => apiCall("POST", "/element", payload);
