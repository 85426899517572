<template>
  <Modal :data="$data" :isVisible="visible">
    <div class="flex flex-row flex-grow justify-end">
      <Button type="tertiary" size="medium" text="No" @click.native="closeModal" class="mr-2" />
      <Button type="primary" text="Yes" size="medium" @click.native="confirm" class="px-3" />
    </div>
  </Modal>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "DeleteScoreLevelModal",
  props: ["visible", "index"],
  data: () => ({
    title: "Delete score level",
    confirmationText: "Are you sure you want to delete this score level?",
    content: `Deleting this score level will result in the deletion of the question answers associated to this level.`,
  }),
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      openModal: "modals/openModal",
      setScores: "products/setScores",
    }),
    async confirm() {
      this.setScores(this.scores.filter((_, index) => index !== this.index))
      this.closeModal();
    },
  },
  computed: {
    ...mapState({
      scores: (state) => state.products.scores,
    }),
  }
};
</script>
