import * as subscriptionsApi from '../../services/api/subscriptions';
import router from '@/router';
import moment from "moment";

const getDefaultState = () => ({
  subscriptionDetails: undefined,
  renewalDetails: undefined,
  form: {
    startDate: moment(new Date(Date.now())).format("YYYY-MM-DDTHH:mm:ss"),
    endDate: moment(new Date(new Date(new Date().setDate(new Date().getDate() + 364)).setHours(23, 59, 0, 0))).format("YYYY-MM-DDTHH:mm:ss"),
    timezone: { label: "(UTC) Coordinated Universal Time", id: "UTC" },
    lead: false,
    pastResults: false,
    reminders: [],
    selectedProducts: [],
    exportPermission: false
  },
  renewalForm: {
    startDate: moment(new Date(Date.now())).format("YYYY-MM-DDTHH:mm:ss"),
    endDate: moment(new Date(new Date(new Date().setDate(new Date().getDate() + 364)).setHours(23, 59, 0, 0))).format("YYYY-MM-DDTHH:mm:ss"),
    timezone: { label: "(UTC) Coordinated Universal Time", id: "UTC" },
    lead: false,
    pastResults: false,
    reminders: [],
    selectedProducts: [],
    exportPermission: false,
    minStartDate: moment(new Date(Date.now())).format("YYYY-MM-DDTHH:mm:ss"),
    isDefault: true,
  },
});

const actions = {
  resetSubscriptionsState({ commit }) {
    commit('resetState');
  },
  async getSubscriptionDetails({ commit, dispatch }, organizationId) {
    const details = await subscriptionsApi.getSubscriptionDetails(organizationId);
    const { data } = details.data;
    commit('SET_SUBSCRIPTION_DETAILS', data);
    commit('SET_SUBSCRIPTION_PRODUCTS', data.products);
    if (data.subscriptionStatus !== 0) {
      if(data.subscriptionStatus === 4) {
        dispatch('setFormValue', { key: 'startDate', value: new Date().toISOString() });
        dispatch('setFormValue', { key: 'endDate', value: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString() });
      
      } else {
        dispatch('setFormValue', { key: 'startDate', value: data.startDate });
        dispatch('setFormValue', { key: 'endDate', value: data.endDate });
      }
      dispatch('setFormValue', { key: 'lead', value: data.isUseForLeadGeneration });
      dispatch('setFormValue', { key: 'pastResults', value: data.isAccessForPastResults });
      dispatch('setFormValue', { key: 'reminders', value: data.reminderNrDaysBeforeEndDate });
      dispatch('setFormValue', { key: 'exportPermission', value: data.allowAdminExportPermission });
    }
    if (data.timezone) dispatch('setFormValue', { key: 'timezone', value: data.timezone });
  },
  setFormValue({ commit }, { key, value }) {
    commit('SET_FORM_VALUE', { key, value });
  },
  async getSubscriptionRenewalDetails({ commit, dispatch }, organizationId) {
    const details = await subscriptionsApi.getSubscriptionRenewalDetails(organizationId);
    const { data } = details.data;
    commit('SET_RENEW_SUBSCRIPTION_DETAILS', data);
    commit('SET_RENEW_SUBSCRIPTION_PRODUCTS', data.products);
    if(data.startDate) {
      dispatch('setRenewFormValue', { key: 'startDate', value: data.startDate });
    } else {
      dispatch('setRenewFormValue', { key: 'startDate', value: data.minStartDate });
    }
    if(data.endDate) {
      dispatch('setRenewFormValue', { key: 'endDate', value: data.endDate });
    } else {
      dispatch('setRenewFormValue', { key: 'endDate', value: new Date(new Date(data.minStartDate).setFullYear(new Date(data.minStartDate).getFullYear() + 1)).toISOString() });
    }
    dispatch('setRenewFormValue', { key: 'isDefault', value: data.isDefault });
    dispatch('setRenewFormValue', { key: 'minStartDate', value: data.minStartDate });
    dispatch('setRenewFormValue', { key: 'lead', value: data.isUseForLeadGeneration });
    dispatch('setRenewFormValue', { key: 'pastResults', value: data.isAccessForPastResults });
    dispatch('setRenewFormValue', { key: 'reminders', value: data.reminderNrDaysBeforeEndDate });
    dispatch('setRenewFormValue', { key: 'exportPermission', value: data.allowAdminExportPermission });
    if (data.timezone) dispatch('setRenewFormValue', { key: 'timezone', value: data.timezone });
  },
  setRenewFormValue({ commit }, { key, value }) {
    commit('SET_RENEW_FORM_VALUE', { key, value });
  },
  async updateSubscription({ state, dispatch }, { organizationId, action }) {
    // Action enum:
    // Activate = 1,
    // Deactivate = 2,
    // Suspend = 3,
    // Update = 4,
    const payload = {
      organizationId,
      startDate: state.form.startDate,
      endDate: state.form.endDate,
      timezone: state.form.timezone,
      reminderNrDaysBeforeEndDate: state.form.reminders.map((el) => parseInt(el, 10)),
      isUseForLeadGeneration: state.form.lead,
      isAccessForPastResults: state.form.pastResults,
      updateOrganizationProductsRequest: state.form.selectedProducts.map((product) => ({
        productId: product.id,
        isSelected: product.isSelected,
      })),
      allowAdminExportPermission: state.form.exportPermission,
      action,
    };
    try {
      await subscriptionsApi.updateSubscription(payload);
      router.push(`/company/${organizationId}/invites`);
      if (action === 1) {
        dispatch(
          'alerts/showSuccess',
          {
            messageHeader: 'New Subscription Activated',
            message: 'Set the number of desired invites or check the exiting ones.',
          },
          { root: true }
        );
      }
    }
    catch (e) {
      const parsedError = JSON.parse(e.toString().substr(7, e.length));
      dispatch(
        'alerts/showError',
        {
          messageHeader: 'Error updating subscription!',
          message: parsedError[0]
        },
        { root: true }
      );
    }
  },
  async updateSubscriptionRenewal({ state, dispatch }, { organizationId }) {
    // Action enum:
    // Activate = 1,
    // Deactivate = 2,
    // Suspend = 3,
    // Update = 4,
    const payload = {
        organizationId,
        startDate: state.renewalForm.startDate,
        endDate: state.renewalForm.endDate,
        reminderNrDaysBeforeEndDate: state.renewalForm.reminders.map((el) => parseInt(el, 10)),
        isUseForLeadGeneration: state.renewalForm.lead,
        isAccessForPastResults: state.renewalForm.pastResults,
        allowAdminExportResults: state.renewalForm.exportPermission,
        productIds: state.renewalForm.selectedProducts.filter(x => x.isSelected).map(x => x.id)
    }
    try {
      await subscriptionsApi.updateSubscriptionRenewal(payload);
      router.push(`/company/${organizationId}/invites`);
        dispatch(
          'alerts/showSuccess',
          {
            messageHeader: 'Subscription Renewal Activated',
            message: 'Current subscription will be renewed with the new settings on the set date'
          },
          { root: true }
        );
    } 
    catch(e) {
      const parsedError = JSON.parse(e.toString().substr(7, e.length));
      dispatch(
        'alerts/showError',
        {
          messageHeader: 'Error scheduling renewal!',
          message: parsedError[0]
        },
        { root: true }
      );
    }
  },
  async cancelSubscriptionRenewal({ dispatch }, { organizationId }) {
    await subscriptionsApi.cancelSubscriptionRenewal(organizationId);
    router.push(`/company/${organizationId}/invites`);
      dispatch(
        'alerts/showSuccess',
        {
          messageHeader: 'Subscription Renewal Canceled',
          message: 'Current subscription renewal was canceled and will not take place!'
        },
        { root: true }
      );
  },
  async updateSubscriptionStatus({ state, dispatch }, { organizationId, action }) {
    // Action enum:
    // Activate = 1,
    // Deactivate = 2,
    // Suspend = 3,
    // Update = 4,
    const payload = {
      organizationId,
      startDate: state.form.startDate,
      endDate: state.form.endDate,
      timezone: state.form.timezone,
      reminderNrDaysBeforeEndDate: state.form.reminders.map((el) => parseInt(el, 10)),
      isUseForLeadGeneration: state.form.lead,
      isAccessForPastResults: state.form.pastResults,
      action,
      allowAdminExportPermission: state.form.exportPermission,
    };
    await subscriptionsApi.updateSubscriptionStatus(payload);
    router.push(`/company/${organizationId}/invites`);
    if (action === 2) {
      dispatch(
        'alerts/showSuccess',
        {
          messageHeader: 'Subscription Deactivated',
          message: 'This customer can longer access the platform’s functionality.',
        },
        { root: true }
      );
    }
  },
};
const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  SET_SUBSCRIPTION_DETAILS(state, details) {
    state.subscriptionDetails = details;
  },
  SET_RENEW_SUBSCRIPTION_DETAILS(state, details) {
    state.renewalDetails = details;
  },
  SET_FORM_VALUE(state, { key, value }) {
    state.form[key] = value;
  },
  SET_RENEW_FORM_VALUE(state, { key, value }) {
    state.renewalForm[key] = value;
  },
  SET_SUBSCRIPTION_PRODUCTS(state, prods) {
    state.form.selectedProducts = prods.map((prod) => ({
      name: prod.name,
      isSelected: prod.alreadyBelongsToOrganization,
      id: prod.productId,
      canBeEdited: !prod.alreadyBelongsToOrganization,
      canBeDisabled: prod.canBeDisabled,
      backgroundColor: prod.backgroundColor,
    }));
  },
  SET_RENEW_SUBSCRIPTION_PRODUCTS(state, prods) {
    state.renewalForm.selectedProducts = prods.map((prod) => ({
      name: prod.name,
      isSelected: prod.alreadyBelongsToOrganization,
      id: prod.productId,
      canBeEdited: !prod.alreadyBelongsToOrganization,
      canBeDisabled: prod.canBeDisabled,
      backgroundColor: prod.backgroundColor,
    }));
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
