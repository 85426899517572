export default (
  message
) => `<div style="width: 600px;background-color: white;margin: 0 auto; border-radius: 10px;"
>
<div
  style="background-color: #F4F7FA;border-top-left-radius: 10px;border-top-right-radius: 10px;padding: 35px 0px 35px 32px"
>
  <div style="display: flex;flex-direction: row;align-items: center;">
    <div
      style="display: flex;flex-grow: 1;border: 1px solid #D0DAE3;border-bottom-width: 0px;border-right-width: 0px;border-left-width: 0px;height: 1px;"
    ></div>
  </div>
  <p
    style="font-family: Verdana, sans-serif;font-weight: 700;font-size: 28px;line-height: 34px;color:#3B5E7F"
  >
    {subject}
  </p>
</div>
<div style="padding: 24px 32px 32px 32px;">
  <p
    style="font-family: Verdana, sans-serif;font-weight: 400;font-size: 14px;line-height: 24px;color:#022F59;margin-bottom: 32px;"
  >
    Hello {firstName},
  </p>
 ${message}
  <a
    href='{actionLink}'
    style="margin-top: 32px;background-color: #00A1D5;border-radius: 4px;padding: 8px 16px; color: white;font-family: 'Noto Sans', sans-serif;font-weight: 600;font-size: 14px;line-height: 19px;"
    >Take Assessment</a
  >
</div>
</div>
`;

/*
<p
    style="font-family: Verdana, sans-serif;font-weight: 400;font-size: 14px;line-height: 24px;color:#748FA8;margin-top:58px;margin-bottom: 0px;"
  >
    King regards,
  </p>
  <p
    style="font-family: Verdana, sans-serif;font-weight: 400;font-size: 14px;line-height: 24px;color:#748FA8;margin-top: 0px;"
  >
    Roger Walters
</p>
*/
