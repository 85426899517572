<template>
  <Modal :data="$data" :isVisible="visible">
    <p class="font-main text-md text-blue-dark-4 mb-5.5">
      Are you sure you want to copy this Competencies?
    </p>
    <div class="flex flex-row flex-grow justify-end">
      <Button
        type="tertiary"
        size="medium"
        text="Close"
        @click.native="closeModal"
        class="mr-2"
      />
      <Button
        type="primary"
        text="Copy Competencies"
        size="medium"
        @click.native="copyCompetencies"
        class="px-3"
      />
    </div>
  </Modal>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "CopyCompetenciesModal",
  props: ["visible", "payload"],
  data: () => ({
    title: "Copying Competencies",
    content:
      "Copying an existing Competencies made you will create an new instance of them including its structure, and you will be able to edit all the information after continuing.",
  }),
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      cloneCompetencies: "products/cloneCompetencies",
    }),
    async copyCompetencies() {
      await this.cloneCompetencies(this.payload).then(() => {
        const id = this.$route.params.productId;
        this.$router.push(`/products/${id}/dashboard`);
      });
      this.closeModal();
    },
  },
};
</script>
