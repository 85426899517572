<template>
  <Modal :data="$data" :isVisible="true">
    <div class="flex flex-row ">
      <div class="flex flex-row flex-grow justify-end">
        <Button
          type="tertiary"
          size="medium"
          text="Exit and Keep Participants"
          @click.native="close"
          class="mr-2"
        />
        <Button
          text="Stay and continue Editing"
          type="primary"
          size="medium"
          @click.native="closeModal"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "LeaveParticipantsModal",
  props: ["visible", "next"],
  data: () => ({
    title: "Exit Assessment Setup?",
    content:
      "You have made changes to the Participants list. If there are new participants added, they will only recieve their email invitation to take the assessment after the remaning steps are completed.",
    confirmationText: "If you need to take out these participants, please remove them from the assessment before you exit.",
  }),
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
    }),
    close() {
      this.closeModal();
      this.next();
    },
  },
};
</script>
