<template>
  <div @click="onClick" class="flex items-start mb-3 cursor-pointer">
    <div class="mt-0.4">
      <Checkbox :active="active" />
    </div>
    <div class="ml-2">
      <p class="text-mdh text-grey-dark-1">
        {{ title }}
      </p>
      <p class="text-sm-2-h text-grey-light">
        {{ description }}
      </p>
    </div>
  </div>
</template>
<script>
import Checkbox from "../Checkbox";

export default {
  name: "CustomCheckbox",
  components: { Checkbox },
  props: {
    onClick: { type: Function, default: () => {} },
    active: { type: Boolean, default: false },
    title: { type: String, default: "" },
    description: { type: String, default: "" },
  },
};
</script>
