<template>
  <div>
    <p v-if="label" class="font-medium text-sm-2 text-grey-light mb-0.5">
      {{ label }}
    </p>
    <div
      class="cl-richtext editor"
      :class="[
        haveMinHeight && editable && 'have-min-height',
        editable &&
          'border border-grey-light-1 rounded bg-white max-w-onboarding',
        error && 'border-red',
      ]"
    >
      <div
        v-if="editor && editable"
        class="p-1.5 bg-grey-light-3 border border-grey-light-1 border-t-0 border-r-0 border-l-0 items-center flex"
        :class="error && 'border-red'"
      >
        <div v-if="headerMode === 'standard'">
          <button
            @click="
              editor
                .chain()
                .focus()
                .toggleBold()
                .run()
            "
            :class="{ 'is-active': editor.isActive('bold') }"
          >
            <img :src="editor.isActive('bold') ? boldBlue : bold" />
          </button>
          <button
            @click="
              editor
                .chain()
                .focus()
                .toggleItalic()
                .run()
            "
            :class="{ 'is-active': editor.isActive('italic') }"
          >
            <img :src="editor.isActive('italic') ? italicBlue : italic" />
          </button>
          <button
            @click="
              editor
                .chain()
                .focus()
                .toggleUnderline()
                .run()
            "
            class="mr-6"
            :class="{ 'is-active': editor.isActive('underline') }"
          >
            <img
              :src="editor.isActive('underline') ? underlineBlue : underline"
            />
          </button>
          <button
            @click="
              editor
                .chain()
                .focus()
                .setTextAlign('left')
                .run()
            "
            :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
          >
            <img
              :src="editor.isActive({ textAlign: 'left' }) ? leftBlue : left"
            />
          </button>
          <button
            @click="
              editor
                .chain()
                .focus()
                .setTextAlign('center')
                .run()
            "
            :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
          >
            <img
              :src="
                editor.isActive({ textAlign: 'center' }) ? centerBlue : center
              "
            />
          </button>
          <button
            @click="
              editor
                .chain()
                .focus()
                .setTextAlign('right')
                .run()
            "
            :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
          >
            <img
              :src="editor.isActive({ textAlign: 'right' }) ? rightBlue : right"
            />
          </button>
          <button
            @click="
              editor
                .chain()
                .focus()
                .setTextAlign('justify')
                .run()
            "
            class="mr-6"
            :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }"
          >
            <img
              :src="
                editor.isActive({ textAlign: 'justify' })
                  ? justifyBlue
                  : justify
              "
            />
          </button>
          <button
            @click="toggleLink"
            class="mr-6"
            :class="{ 'is-active': editor.isActive('link') }"
          >
            <img :src="editor.isActive('link') ? linkBlue : link" />
          </button>

          <button
            @click="
              editor
                .chain()
                .focus()
                .toggleBulletList()
                .run()
            "
            :class="{ 'is-active': editor.isActive('bulletList') }"
          >
            <img
              :src="editor.isActive('bulletList') ? bulletedBlue : bulleted"
            />
          </button>
          <button
            @click="
              editor
                .chain()
                .focus()
                .toggleOrderedList()
                .run()
            "
            :class="{ 'is-active': editor.isActive('orderedList') }"
          >
            <img
              :src="editor.isActive('orderedList') ? numberedBlue : numbered"
            />
          </button>

          <button
            @click="
              editor
                .chain()
                .focus()
                .undo()
                .run()
            "
          >
            <img :src="undo" />
          </button>
          <button
            @click="
              editor
                .chain()
                .focus()
                .redo()
                .run()
            "
          >
            <img :src="redo" />
          </button>
        </div>
        <div v-if="headerMode === 'link'" class="flex flex-grow items-center">
          <InputField
            placeholder="Enter link here"
            :onChange="setURL"
            class="w-full"
          />
          <Button
            type="tertiary"
            size="medium"
            text="Cancel"
            class="mx-3"
            @click.native="cancel"
          />
          <Button
            type="primary"
            size="medium"
            text="Save"
            @click.native="saveLink"
          />
        </div>
      </div>
      <editor-content
        class="editor__content"
        :class="editable ? 'p-2' : nonEditableTextStyle"
        :editor="editor"
      />
      <p v-if="error" class="text-sm mt-0.4 absolute text-red">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Link from '@tiptap/extension-link';
import bold from '../../assets/img/richtext/bold.svg';
import boldBlue from '../../assets/img/richtext/bold-blue.svg';
import italic from '../../assets/img/richtext/italic.svg';
import italicBlue from '../../assets/img/richtext/italic-blue.svg';
import underline from '../../assets/img/richtext/underline.svg';
import underlineBlue from '../../assets/img/richtext/underline-blue.svg';
import link from '../../assets/img/richtext/link.svg';
import linkBlue from '../../assets/img/richtext/link-blue.svg';
import bulleted from '../../assets/img/richtext/bulleted-list.svg';
import bulletedBlue from '../../assets/img/richtext/bulleted-list-blue.svg';
import numbered from '../../assets/img/richtext/numbered-list.svg';
import numberedBlue from '../../assets/img/richtext/numbered-list-blue.svg';
import undo from '../../assets/img/richtext/undo.svg';
import redo from '../../assets/img/richtext/redo.svg';
import left from '../../assets/img/richtext/align-left.svg';
import right from '../../assets/img/richtext/align-right.svg';
import center from '../../assets/img/richtext/align-center.svg';
import justify from '../../assets/img/richtext/justify.svg';
import leftBlue from '../../assets/img/richtext/align-left-blue.svg';
import rightBlue from '../../assets/img/richtext/align-right-blue.svg';
import centerBlue from '../../assets/img/richtext/align-center-blue.svg';
import justifyBlue from '../../assets/img/richtext/justify-blue.svg';
import InputField from '../InputField/InputField.vue';
import Button from '../Button/Button.vue';

export default {
  components: {
    EditorContent,
    InputField,
    Button,
  },
  props: {
    label: { type: String },
    content: {},
    editable: { type: Boolean, default: true },
    haveMinHeight: { type: Boolean },
    updateHtml: {
      type: Function,
    },
    hasGreyText: { default: true, type: Boolean },
    error: {
      type: String,
      default: null,
    },
  },
  computed: {
    nonEditableTextStyle() {
      return this.hasGreyText ? 'greyText' : '';
    },
  },
  data() {
    return {
      editor: null,
      headerMode: 'standard',
      html: '',
      url: '',
      bold,
      boldBlue,
      italic,
      italicBlue,
      underline,
      underlineBlue,
      link,
      linkBlue,
      bulleted,
      bulletedBlue,
      numbered,
      numberedBlue,
      undo,
      redo,
      left,
      right,
      center,
      justify,
      leftBlue,
      rightBlue,
      centerBlue,
      justifyBlue,
    };
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
          defaultAlignment: 'left',
        }),
        Link,
        Underline,
        BulletList,
        OrderedList,
        ListItem,
        Document,
        Paragraph,
        Text,
      ],
      editable: this.editable,
      content: this.content,
      onUpdate: () => {
        this.html = this.editor.getHTML();
        this.updateHtml(this.html);
      },
    });
  },
  methods: {
    setURL(value) {
      this.url = value;
    },
    toggleLink() {
      if (this.editor.isActive('link')) {
        this.editor
          .chain()
          .focus()
          .unsetLink()
          .run();
      } else {
        this.headerMode = 'link';
      }
    },
    saveLink() {
      if (!this.url.includes('http')) {
        this.url = 'https://'.concat(this.url);
      }
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: this.url })
        .run();
      this.headerMode = 'standard';
    },
    cancel() {
      this.headerMode = 'standard';
    },
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
button {
  border-radius: 4px;
  padding: 6px;
  &:hover {
    background-color: #e0eef9;
  }
  &:active {
    background-color: white;
  }
}

.cl-richtext {
  margin-bottom: 12px;
  p.is-editor-empty:first-child::after {
    content: attr(data-empty-text);
    display: block;
    font-size: 14px;
    position: absolute;
    top: 0;
    pointer-events: none;
  }
  &.have-min-height {
    .ProseMirror {
      min-height: 105px;
    }
  }
  .ProseMirror {
    &:focus {
      outline: none;
    }

    font-family: Noto Sans, sans-serif;
    a {
      color: #00a1d5;
    }
    p {
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 0px;
      font-weight: 500;
      color: #022f59;
    }
    ul {
      list-style-type: circle;
      list-style-position: initial;
      margin: 0 0 0 20px !important;
    }
    ol {
      list-style-type: decimal;
      list-style-position: initial;
      margin: 0 0 0 20px !important;
    }
    h3 {
      font-weight: 400;
    }
  }
}
.greyText {
  p {
    color: #748fa8 !important;
  }
  strong {
    color: #3b5e7f !important;
  }
}
</style>
