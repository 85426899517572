<template>
  <div
    :ref="`assessment-card-${assessment.id}`"
    class="rounded assess-card bg-blue-light-4 bg-opacity-30 flex flex-col bg-no-repeat mr-2 relative"
    :style="{ 'background-image': url(bgImage) }"
    :class="
      scaleDown ? 'transform scale-90 animate-scale-down' : 'animate-scale-up'
    "
  >
    <div
      v-if="showCompletionInfo"
      class="absolute bg-black inset-0 opacity-20 z-10"
    ></div>
    <div
      class="rounded-l bg-grey-dark-1 bg-opacity-70 flex items-center label p-1 ml-auto mt-2.5 z-20"
    >
      <p class="text-sm font-semibold text-white">
        {{ assessmentLabel }}
      </p>
    </div>
    <div class="title-container z-20">
      <p
        class="font-headers text-mdl text-white font-bold ml-2 mb-0.5 mr-2"
        id="title"
      >
        {{ assessment.title }}
      </p>
      <p class="text-sm-2 text-blue-light-5 ml-2 my-0.5" id="launched">
        Launched on {{ assessment.launchedOn }}
      </p>
      <p class="text-sm-2 text-blue-light-5 ml-2" id="due">
        Due on {{ assessment.dueOn }}
      </p>
    </div>
    <div
      class="mt-auto mx-2 flex flex-row justify-between items-end z-20"
      :class="!showCompletionInfo && 'mb-2.5'"
    >
      <Button
        :text="buttonText"
        type="secondary"
        size="medium"
        @click.native="goToAssessment"
      />
      <div v-if="showCompletionInfo">
        <div v-if="!assessment.isClosed" class="flex flex-row justify-end">
          <p class="text-spacing font-bold text-white text-sm">
            {{ daysLeft }}
          </p>
          <p class="font-normal text-white text-sm">
            {{ `Day${this.daysLeft === 1 ? "" : "s"} left` }}
          </p>
        </div>
        <div class="flex flex-row justify-end completed-spacing">
          <p class="text-spacing font-bold text-white text-sm">
            {{ this.completed(assessment.id) }}
          </p>
          <p class="font-normal text-white text-sm">Complete</p>
        </div>
      </div>
    </div>
    <div
      v-if="showCompletionInfo"
      class="h-4 bg-grey-progress-bar rounded mx-2 my-1.5 z-20"
    >
      <div
        class="h-4 bg-white rounded"
        v-bind:style="{ width: this.completed(assessment.id) }"
      ></div>
    </div>
  </div>
</template>

<script>
import Button from "../../../components/Button/Button";
import getImageByColor from "../../../services/utils/getImageByColor";
import moment from "moment";

import { mapGetters, mapActions } from "vuex";
export default {
  components: { Button },
  name: "AssessmentCard",
  data: () => ({ scaleDown: false }),
  props: {
    assessment: { default: null, type: Object },
    isCompany: { default: false, type: Boolean },
  },
  mounted() {
    var observer = new IntersectionObserver(this.toggleScale, {
      threshold: [0.45, 0.55],
    });

    observer.observe(this.thisOne);
  },
  computed: {
    ...mapGetters({
      getCompletion: "assessments/getAssessmentCompletion",
    }),
    assessmentLabel() {
      if (this.assessment.hasExpired) return "EXPIRED";
      if (this.assessment.inProgress) return "IN PROGRESS";
      if (this.assessment.isClosed) return "EXPIRED";
      return "NEW";
    },
    buttonText() {
      if (this.assessment.hasExpired) return "Expired";
      if (this.assessment.inProgress) return "Continue";
      if (this.assessment.isClosed) return "Closed";
      return "Start now";
    },
    showCompletionInfo() {
      return this.assessment.inProgress || this.assessment.isClosed;
    },
    daysLeft() {
      const due = moment(Date.parse(this.assessment.dueOn));
      const today = moment();
      return due.diff(today, "days") + 1;
    },
    bgImage() {
      const color = this.assessment.backgroundColor;
      return getImageByColor(color);
    },
    thisOne() {
      return this.$refs[`assessment-card-${this.assessment.id}`];
    },
  },
  methods: {
    ...mapActions({
      getSurvey: "assessments/getSurvey",
      setShouldEdit: "assessmentSetup/setShouldEdit",
    }),
    toggleScale(entries) {
      const ratio = entries[0].intersectionRatio;
      if (ratio < 0.45) {
        this.scaleDown = true;
      } else if (ratio > 0.55) this.scaleDown = false;
    },
    url(value) {
      return "url(" + value + ")";
    },
    async goToAssessment() {
      this.setShouldEdit(false);
      await this.getSurvey({ id: this.assessment.id });
      this.$router.push(`/assessment/${this.assessment.id}`);
    },
    completed(id) {
      return this.getCompletion(id).remaining;
    },
  },
};
</script>

<style lang="scss" scoped>
.assess-card {
  width: 258px;
  height: 258px;
}
.title-container {
  margin-top: 34px;
}
.completed-spacing {
  margin-top: 4px;
}
.text-spacing {
  margin-right: 2px;
}
.label {
  width: 105px;
  height: 32px;
}
</style>
