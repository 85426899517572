export const splitArrayIntoBatches = (array, batchElementsCount) => {
    if (!Array.isArray(array) || !Number.isInteger(batchElementsCount) || batchElementsCount <= 0) {
        throw new Error('Invalid input. Please provide a valid array and a positive integer for batchElementsCount.');
    }

    const batches = [];
    for (let i = 0; i < array.length; i += batchElementsCount) {
        batches.push(array.slice(i, i + batchElementsCount));
    }

    return batches;
}

export const mergeImportUsersSplitResult = (results) => {
    const mergeResult = {
        failedUsersCount: results.map(x => x.data.data.failedUsersCount).reduce((a, b) => a+b, 0),
        failedUsersMessages: results.map(x => (x.data.data.failedUsers || []).map(x => x.errorMessage)).flat(),
        importedUsersCount: results.map(x => x.data.data.importedUsersCount).reduce((a, b) => a+b, 0),
        skippedUsersCount: results.map(x => x.data.data.skippedUsersCount).reduce((a, b) => a+b, 0),
        totalUsersCount: results.map(x => x.data.data.totalUsersCount).reduce((a, b) => a+b, 0),
    }
    return mergeResult;
}