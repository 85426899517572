import { apiCall } from "./api";

const endpoint = "api/v1/Organization";

export const getOrgInvitesInfo = (orgId) =>
  apiCall("GET", `${endpoint}/available-invites-info?organizationId=${orgId}`);

export const getInvitesHistory = (
  orgId,
  responsible,
  pageNumber = 1,
  pageSize = 100000,
  sorting = {date: 2}
) => {
  return apiCall("POST", `${endpoint}/all-invites-history`, {
    pageNumber: pageNumber,
    pageSize: pageSize,
    organizationId: orgId,
    responsableName: responsible,
    sorting: sorting,
  });
};

export const addInvites = (orgId, invitesNumber) =>
  apiCall("POST", `${endpoint}/add-invites`, {
    organizationId: orgId,
    numberOfInvites: invitesNumber,
  });

export const removeInvites = (orgId, invitesNumber) =>
  apiCall("POST", `${endpoint}/remove-invites`, {
    organizationId: orgId,
    numberOfInvites: invitesNumber,
  });

export const setUnlimitedInvites = (orgId, isUnlimited) =>
  apiCall("POST", `${endpoint}/update-unlimited-invites-option`, {
    organizationId: orgId,
    isUnlimitedTrue: isUnlimited,
  });

export const getAllInvitesInfo = (type) =>
  apiCall("GET", `${endpoint}/invites-usage-overview?assessmentType=${type}`);

export const getAllProductsInvitesInfo = () =>
  apiCall("GET", `${endpoint}/get-all-products-invites-info`);

export const getAllOrgsInfo = (payload) =>
  apiCall("POST", `${endpoint}/get-all-organizations-invites-info`, payload);

export const getAllProductsInfo = (payload) =>
  apiCall(
    "POST",
    `${endpoint}/get-organization-products-invites-info`,
    payload
  );
