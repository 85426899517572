import sustainabilityBg from "../../assets/img/home/sustainability.png";
import leadershipBg from "../../assets/img/home/leadership.png";
import strategicBg from "../../assets/img/home/strategic-sourcing.png";
import dataBg from "../../assets/img/home/data-analytics.png";
import softBg from "../../assets/img/home/soft-skills.png";
import supplyBg from "../../assets/img/home/supply-chain.png";

const getImageByColor = (color) => {
  if (color === "blue") return supplyBg;
  if (color === "purple") return dataBg;
  if (color === "turquoise") return leadershipBg;
  if (color === "orange") return softBg;
  if (color === "green") return strategicBg;
  if (color === "lime") return sustainabilityBg;
  return supplyBg;
};

export default getImageByColor;
