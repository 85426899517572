<template>
  <Modal :data="$data" :isVisible="visible">
    <div class="flex flex-row flex-grow justify-end">
      <Button
        text="Choose another date"
        type="danger"
        size="medium"
        @click.native="close"
      />
    </div>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "AssesmentDateModal",
  props: ["visible"],
  data: () => ({
    title: "Subscription Will Expire By Close Date",
    content:
      "Your subscription will expire before this assessment's close date. This means you cannot setup an assessment to close after this date. Please select another date to close out this assessment.",
    confirmationText: "",
  }),
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
    }),
    close() {
      this.closeModal();
    },
  },
};
</script>
