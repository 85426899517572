import { apiCall } from "./api";

export const getCompletedAssessments = () =>
  apiCall("GET", "api/v1/Survey/user_surveys_history");

export const fetchOngoingAssessments = (teamId) => {
  const date = new Date(Date.now());
  let offset = (-1 * date.getTimezoneOffset()) / 60;
  return apiCall(
    "GET",
    `api/v1/Survey/progress_for_active?TeamId=${teamId}&timezoneOffset=${offset}`
  );
};

export const fetchOngoingAdminAssessments = () => {
  const date = new Date(Date.now());
  let offset = (-1 * date.getTimezoneOffset()) / 60;
  return apiCall(
    "GET",
    `api/v1/Survey/progress_for_active?timezoneOffset=${offset}`
  );
};

export const fetchClosedAssessments = (teamId) =>
  apiCall(
    "GET",
    `api/v1/Competency/closed_surveys_results?TeamId=${teamId}&showOnlyLatestAssesment=true`
  );
