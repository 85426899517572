<template>
  <div>
    <div
      class="w-full h-full fixed z-40 bottom-0 flex justify-center items-center modal"
    >
      <div class="w-modal bg-white p-4 shadow flex-grow-0 rounded">
        <h1 class="font-headers font-bold text-grey-dark-2 text-l mb-1.5">
          {{ title }}
        </h1>
        <InputField
          placeholder="Type a team name"
          label="Team Name"
          :propValue="teamName"
          :onChange="setValue"
          class="mb-2 mt-3"
        />
        <Select
          label="Team Manager (optional)"
          :options="options"
          :searchable="true"
          :onSelect="selectManager"
          :value="selectedManager"
          :preselectFirst="false"
          :key="selectKey"
        />
        <div class="flex flex-col mt-2" v-if="this.isEdit">
          <p class="text-grey-dark-1 text-md mb-1 font-semibold">
            Remove Manager
          </p>
          <div class="ml-2">
            <p class="text-grey-light text-sm-2">
              By removing the manager no one will be able to monitor the team’s
              progress and results except for admins.
            </p>
            <div class="flex flex-row mt-1.5">
              <div class="pt-0.5">
                <Checkbox
                  class="cursor-pointer"
                  :active="removeManager"
                  @click.native="clickRemove"
                />
              </div>
              <p
                class="text-mdh pl-1 cursor-pointer text-grey-dark-2"
                @click="clickRemove"
              >
                By clicking this box, I understand that this team will no longer
                have a manager until another manager will be reassigned.
              </p>
            </div>
          </div>
        </div>
        <div
          class="flex flex-row flex-grow justify-end mt-4 space-x-2"
          v-if="!this.isEdit"
        >
          <Button
            type="tertiary"
            size="medium"
            text="Cancel"
            @click.native="closeModal"
          />
          <Button
            text="Save"
            type="secondary"
            size="medium"
            @click.native="save(true)"
          />
          <Button
            text="Save and Create Another"
            type="primary"
            size="medium"
            @click.native="save(false)"
          />
        </div>
        <div class="flex flex-row flex-grow justify-end mt-4 space-x-2" v-else>
          <Button
            type="tertiary"
            size="medium"
            text="Cancel"
            @click.native="closeModal"
          />
          <Button
            text="Save"
            type="primary"
            size="medium"
            @click.native="update()"
          />
        </div>
      </div>
    </div>
    <div
      class="w-full h-full fixed z-30 bottom-0 bg-grey-dark-1 opacity-70 modal transition-all"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "CreateTeamModal",
  data: () => ({
    teamName: "",
    selectedManager: null,
    selectKey: 1,
    removeManager: false,
  }),
  computed: {
    ...mapState({
      managers: (state) => state.adminTeams.managers,
      team: (state) => state.adminTeams.team,
    }),
    ...mapGetters({
      getOrg: "auth/getOrganizationId",
    }),
    options() {
      const sortedManagers = this.managers.sort((x, y) =>
        x.firstName.localeCompare(y.firstName)
      );
      return sortedManagers.map((el) => ({
        label: el.firstName + " " + el.lastName,
        id: el.id,
      }));
    },
    isEdit() {
      return !!this.$attrs.team;
    },
    team() {
      return this.$attrs.team;
    },
    title() {
      return this.isEdit ? "Edit Team" : "Create New Team";
    },
  },
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      create: "adminTeams/create",
      clearManager: "adminTeams/clearManager",
      edit: "adminTeams/editTeam",
    }),
    clickRemove() {
      this.removeManager = !this.removeManager;
      this.selectManager(null);
    },
    selectManager(val) {
      if (val !== null) {
        this.removeManager = false;
      }
      this.selectedManager = val;
    },
    async update() {
      const payload = {
        name: this.teamName,
        id: this.team.id,
        description: "",
        organizationId: this.getOrg,
        // usersIds: this.team.users.map((el) => el.id).length === 0 ? null : this.team.users.map((el) => el.id),
        usersIds: null,
      };
      if (this.removeManager) {
        await this.clearManager({
          teamId: this.team.id,
          managerId: this.team.manager.id,
        });
      }
      await this.edit({
        teamId: this.team.id,
        payload,
        managerId: this.selectedManager?.id,
      });
      await this.$attrs.callback();
      this.closeModal();
    },
    async save(close) {
      const payload = {
        name: this.teamName,
        description: "",
        organizationId: this.getOrg,
        usersIds: [],
      };
      await this.create({
        payload,
        managerId: this.selectedManager?.id,
        navigate: true,
      });
      await this.$attrs.callback(true);
      if (close) {
        this.closeModal();
      }
      this.selectManager(null);
      this.selectKey += 1;
      this.teamName = "";
    },
    setValue(val) {
      this.teamName = val;
    },
    populateTeam() {
      this.teamName = this.team.name;
      if (this.team.manager) {
        this.selectManager({
          id: this.team.manager.id,
          label: this.team.manager.firstName + " " + this.team.manager.lastName,
        });
      }
    },
  },
  mounted() {
    if (this.isEdit) this.populateTeam();
  },
};
</script>

<style lang="scss" scoped>
.modal {
  top: 0;
}
</style>
