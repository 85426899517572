<template>
  <Modal :data="$data" :isVisible="true">
    <div class="flex flex-row ">
      <div class="flex flex-row flex-grow justify-end">
        <Button
          type="tertiary"
          size="medium"
          text="Cancel"
          @click.native="closeModal"
          class="mr-2"
        />
        <Button
          text="Continue"
          type="primary"
          size="medium"
          @click.native="close"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "RemoveParticipantModal",
  props: ["visible", "remove"],
  data: () => ({
    title: "Remove Participant",
    content: "",
    confirmationText: "Are you sure you want to remove them?",
  }),
  watch: {
    modalContent(value) {
      this.content = value;
    },
  },
  computed: {
    ...mapState({
      generalInfo: (state) => state.assessmentSetup.generalInfo,
    }),
    modalContent() {
      return `Removing a participant will also remove the settings made for them in this assessment (${
        this.isIndividual ? "job role and " : ""
      }result persmissions).`;
    },
    isIndividual() {
      return this.generalInfo?.surveyType === 0;
    },
  },
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
    }),
    async close() {
      this.closeModal();
      await this.remove();
    },
  },
  mounted() {
    this.content = this.modalContent;
  },
};
</script>
