export function showTooltip(el, vnode, originalText) {
  const found = document.getElementById(vnode.context._uid);
  if (!!found) return;
  var p = document.createElement("p");
  p.textContent = originalText.replace("&nbsp;", "");
  p.setAttribute("id", vnode.context._uid);
  p.setAttribute(
    "class",
    "text-white text-sm bg-grey-dark-1 rounded shadow p-0.5 animate-fade-in"
  );
  p.style.position = "absolute";
  p.style.zIndex = "1000";
  var position = el.getBoundingClientRect();
  var offset = document.body.getBoundingClientRect().y;
  p.style.top = position.y - offset + 18 + "px";
  p.style.left = position.x + 15 + "px";
  el.insertAdjacentElement("afterend", p);
}

export function discardTooltip(vnode) {
  var p = document.getElementById(vnode.context._uid);
  if (!!p) {
    p.setAttribute("class", p.getAttribute("class") + " " + "animate-fade-out");
    setTimeout(() => {
      p.remove();
    }, 500);
  }
}
