var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-1"},[_c('router-link',{staticClass:"py-2 relative flex items-center cursor-pointer pl-6 pr-2",attrs:{"to":"/assessments/general-information"}},[_c('p',{staticClass:"max-w-200 text-blue-dark-1 text-md font-semibold"},[_vm._v(" "+_vm._s(_vm.stepNumber("generalInfo"))+". General Information ")])]),_c('router-link',{staticClass:"py-2 relative flex items-center cursor-pointer pl-6 pr-2",class:this.isDisabled('participants')
        ? 'text-grey-medium-2 pointer-events-none'
        : 'text-blue-dark-1',attrs:{"to":"/assessments/participants"}},[_c('p',{staticClass:"max-w-200 text-md font-semibold"},[_vm._v(" "+_vm._s(_vm.stepNumber("participants"))+". Participants ")])]),(this.assessmentType === 0)?_c('router-link',{staticClass:"py-2 relative flex items-center cursor-pointer pl-6 pr-2",class:this.isDisabled('jobRoles')
        ? 'text-grey-medium-2 pointer-events-none'
        : 'text-blue-dark-1',attrs:{"to":"/assessments/job-roles"}},[_c('p',{staticClass:"max-w-200 text-md font-semibold"},[_vm._v(" "+_vm._s(_vm.stepNumber("jobRoles"))+". Job Roles ")])]):_vm._e(),_c('router-link',{staticClass:"py-2 relative flex items-center cursor-pointer pl-6 pr-2",class:this.isDisabled('targets')
        ? 'text-grey-medium-2 pointer-events-none'
        : 'text-blue-dark-1',attrs:{"to":"/assessments/targets"}},[_c('p',{staticClass:"max-w-200 text-md font-semibold"},[_vm._v(" "+_vm._s(_vm.stepNumber("targets"))+". Targets ")])]),_c('router-link',{staticClass:"py-2 relative flex items-center cursor-pointer pl-6 pr-2",class:this.isDisabled('settings')
        ? 'text-grey-medium-2 pointer-events-none'
        : 'text-blue-dark-1',attrs:{"to":"/assessments/settings"}},[_c('p',{staticClass:"max-w-200 text-md font-semibold"},[_vm._v(" "+_vm._s(_vm.stepNumber("settings"))+". Settings ")])]),_c('router-link',{staticClass:"py-2 relative flex items-center cursor-pointer pl-6 pr-2",class:this.isDisabled('communication')
        ? 'text-grey-medium-2 pointer-events-none'
        : 'text-blue-dark-1',attrs:{"to":"/assessments/communication"}},[_c('p',{staticClass:"max-w-200 text-md font-semibold"},[_vm._v(" "+_vm._s(_vm.stepNumber("communication"))+". Communication ")])]),_c('router-link',{staticClass:"py-2 relative flex items-center cursor-pointer pl-6 pr-2",class:this.isDisabled('summary')
        ? 'text-grey-medium-2 pointer-events-none'
        : 'text-blue-dark-1',attrs:{"to":"/assessments/summary"}},[_c('p',{staticClass:"max-w-200 text-md font-semibold"},[_vm._v(" "+_vm._s(_vm.stepNumber("summary"))+". Summary ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }