<template>
  <div class="add-invites-modal">
    <div
      class="w-full h-full fixed z-40 bottom-0 flex justify-center items-center modal"
    >
      <div class="w-1/3 bg-white p-4 shadow rounded flex-grow-0 rounded">
        <h1 class="font-headers font-bold text-grey-dark-2 text-l mb-1.5">
          Add Invites
        </h1>
        <p class="text-grey-light text-sm-2">
          Each assessment invitation consumes one assessment per individual
          invited.
        </p>
        <div class="flex flex-row w-full justify-between ml-2">
          <InputField
            label="Number of invites to be added"
            type="number"
            :onChange="setValue"
            :isTextOk="fieldError === null ? null : !fieldError"
            :errorText="fieldError"
            class="add-invites-btn mb-2 mt-3"
            data-testid="inputfield"
            step="1"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          />
        </div>
        <div class="flex flex-row flex-grow justify-end mt-4 space-x-2">
          <Button
            type="tertiary"
            size="medium"
            text="Cancel"
            @click.native="closeModal"
            data-testid="cancel-button"
          />
          <Button
            text="Save"
            type="primary"
            size="medium"
            class="px-4"
            :disabled="disableBtn"
            @click.native="save()"
            data-testid="save-button"
          />
        </div>
      </div>
    </div>
    <div
      class="w-full h-full fixed z-30 bottom-0 bg-grey-dark-1 opacity-70 modal transition-all"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { numeric, maxValue, minValue } from "vuelidate/lib/validators";

export default {
  name: "AddInvitesModal",
  data: () => ({
    totalLicenses: "",
    disableBtn: false,
  }),
  validations: {
    totalLicenses: {
      numeric,
      maxValue: maxValue(10000),
      minValue: minValue(1),
    },
  },
  computed: {
    fieldError() {
      if (this.$v.totalLicenses.$dirty) {
        if (!this.$v.totalLicenses.maxValue) {
          return 'Add a maximum of 10.000 invites.';
        }
        if (!this.$v.totalLicenses.minValue) {
          return 'Add at least 1 invites.';
        }
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      addInvites: "invites/addInvites",
    }),
    setValue(val) {
      this.totalLicenses = parseInt(val);
      this.$v.totalLicenses.$touch();
      this.disableBtn = this.$v.$invalid;
    },
    async save() {
      this.totalLicenses &&
        (await this.addInvites({ orgId: parseInt(this.$route.params.companyId, 10), nrOfLicenses: parseInt(this.totalLicenses) }));
      this.closeModal();
    },
  },
};
</script>

<style lang="scss">
.add-invites-modal {
  .modal {
    top: 0;
    .add-invites-btn {
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }
}
</style>
