<template>
  <div>
    <div
      class="w-full h-full fixed z-40 flex flex-col justify-center items-center modal"
    >
      <div class="w-jobRolesModal bg-white p-4 shadow overflow-hidden rounded">
        <p class="font-barlow font-bold text-grey-dark-2 text-l mb-1.5">
          Add Employees Individually
        </p>
        <div class="p-1">
          <div class="flex flex-grow mb-3 space-x-2">
            <InputField
              label="First Name"
              class="flex-1"
              :onChange="setFirstName"
              :isTextOk="
                !$v.firstName.$dirty
                  ? null
                  : $v.firstName.required
                  ? null
                  : false
              "
              v-model="$v.firstName.$model"
              errorText="First name is required"
              data-testid="first-name-field"
            />
            <InputField
              label="Last Name"
              class="flex-1"
              :onChange="setLastName"
              :isTextOk="
                !$v.lastName.$dirty ? null : $v.lastName.required ? null : false
              "
              errorText="Last name is required"
              data-testid="last-name-field"
            />
          </div>
          <InputField
            label="Email"
            class="mb-3"
            :onChange="setEmail"
            :isTextOk="
              !$v.email.$dirty ? null : $v.email.emailValidator ? null : false
            "
            errorText="Email is required"
            data-testid="email-field"
          />
          <InputField
            label="Job Title"
            :onChange="setJobTitle"
            data-testid="job-title-field"
          />
        </div>
        <div class="pl-1 mt-3" v-if="this.$attrs.withRole">
          <p class="text-sm-2 text-grey-light mb-1">System Roles</p>
          <div class="flex flex-row space-x-4">
            <div
              @click="switchRole('employeeRole')"
              class="flex flex-row items-center"
            >
              <Checkbox
                class="cursor-pointer"
                :active="employeeRole"
                data-testid="employee-checkbox"
              />
              <p class="pl-1 cursor-pointer">
                Employee
              </p>
            </div>
            <div
              @click="switchRole('managerRole')"
              class="flex flex-row items-center"
            >
              <Checkbox
                class="cursor-pointer"
                :active="managerRole"
                data-testid="manager-checkbox"
              />
              <p class="pl-1 cursor-pointer">
                Manager
              </p>
            </div>
            <div
              @click="switchRole('adminRole')"
              class="flex flex-row items-center"
            >
              <Checkbox
                class="cursor-pointer"
                :active="adminRole"
                data-testid="admin-checkbox"
              />
              <p class="pl-1 cursor-pointer">
                Admin
              </p>
            </div>
          </div>
          <span class="text-red mt-2 text-sm-2">
            {{ roleError }}
          </span>
        </div>

        <div class="flex flex-row flex-grow justify-end mt-4">
          <Button
            type="tertiary"
            size="medium"
            text="Cancel"
            @click.native="closeModal"
            class="mr-2"
            data-testid="cancel-button"
          />
          <Button
            text="Save"
            type="primary"
            size="medium"
            @click.native="close"
            data-testid="save-button"
          />
        </div>
      </div>
    </div>
    <div
      class="w-full h-full fixed z-30 bottom-0 bg-grey-dark-1 opacity-70 modal transition-all"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import InputField from "../../../components/InputField/InputField.vue";
import { required } from "vuelidate/lib/validators";
import { emailValidator } from '../utils/emailValidator';

export default {
  name: "IndividualUserModal",
  components: { InputField },
  data: () => ({
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    employeeRole: true,
    managerRole: false,
    adminRole: false,
  }),
  validations: {
    firstName: {
      required,
    },
    lastName: {
      required,
    },
    email: {
      required,
      emailValidator,
    },
  },
  computed: {
    ...mapGetters({
      orgId: "auth/getOrganizationId",
      isImpersonating: "auth/isImpersonating",
    }),
    ...mapState({
      id: (state) => state.companies.currentCompany.id,
      userRoles: (state) => state.auth.decodedData.roles,
    }),
    roleError() {
      if (!this.employeeRole && !this.managerRole && !this.adminRole) {
        return "At least one role needs to be selected";
      }
    },
  },
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      saveUsers: "assessmentSetup/saveUsers",
      inviteUser: "assessmentSetup/inviteUser",
      saveUsersAsSeller: "companies/saveUsersAsSeller",
    }),
    setFirstName(value) {
      this.firstName = value;
      this.$v.firstName.$touch();
    },
    setLastName(value) {
      this.lastName = value;
      this.$v.lastName.$touch();
    },
    setEmail(value) {
      this.email = value;
      this.$v.email.$touch();
    },
    setJobTitle(value) {
      this.jobTitle = value;
    },
    switchRole(key) {
      this[key] = !this[key];
    },
    async close() {
      this.$v.$touch();
      if (
        this.$v.$invalid ||
        (this.$attrs.withRole &&
          !this.employeeRole &&
          !this.managerRole &&
          !this.adminRole)
      )
        return;
      const isSeller = this.userRoles.includes("SellerAdmin");
      if (this.$attrs.withRole) {
        const roles = [];
        if (this.employeeRole) roles.push(4);
        if (this.managerRole) roles.push(3);
        if (this.adminRole) roles.push(2);
        const payload = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          jobTitle: this.jobTitle,
          organizationId: isSeller ? this.id : this.orgId,
          roles,
        };
        await this.inviteUser({ payload });
        if (this.$attrs.callback) {
          await this.$attrs.callback(true);
        }
      } else {
        const users = [
          {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            jobTitle: this.jobTitle,
          },
        ];
        if (isSeller && !this.isImpersonating) {
          await this.saveUsersAsSeller({ companyId: this.id, users });
        } else {
          await this.saveUsers({ users });
        }
        if (this.$attrs.callback) { 
          await this.$attrs.callback(false);
        }
      }
      await this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  top: 0;
}
</style>
