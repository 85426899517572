import {
  fetchPeopleUsers,
  editUser,
  sendInviteEmail,
} from "@/services/api/people";
import Vue from "vue";

const getDefaultState = () => ({
  people: [],
  selectedUsersForInvite: [],
  user: null,
});

const actions = {
  resetPeopleState({ commit }) {
    commit("resetState");
  },
  sendInviteEmail({}, { sendDate, usersIds }) {
    return sendInviteEmail(sendDate, usersIds).then((rsp) => {});
  },
  setUser({ commit }, { user }) {
    commit("SET_USER", user);
  },
  setSelectedUsersForInvite({ commit }, users) {
    commit("SET_SELECTED_USERS_FOR_INVITE", users);
  },
  addUserToInvite({ commit }, user) {
    commit("ADD_USER_TO_INVITE", user);
  },
  removeUserFromInvite({ commit }, user) {
    commit("REMOVE_SELECTED_FROM_INVITE", user);
  },
  edit({ commit, dispatch, rootState }, { payload }) {
    return editUser(payload)
      .then(() => {
        commit(
          "companies/SET_SELECTED_USER",
          {
            ...rootState.companies.selectedUser,
            firstName: payload.firstName,
            lastName: payload.lastName,
            email: payload.email,
          },
          { root: true }
        );
        dispatch(
          "alerts/showSuccess",
          {
            messageHeader: "User details updated",
            message:
              "The information for this user has been successfully updated.",
          },
          { root: true }
        );
      })
      .catch(() =>
        dispatch(
          "alerts/showError",
          {
            messageHeader: "Error",
            message: "Something went wrong, try again.",
          },
          { root: true }
        )
      );
  },
  getPeople(
    { commit },
    { keyword, pageNr, sorting, allowInviteInactiveUsers, pageSize, status }
  ) {
    return fetchPeopleUsers(
      keyword,
      pageNr,
      sorting,
      allowInviteInactiveUsers,
      pageSize,
      status
    ).then((rsp) => {
      commit("SET_PEOPLE", rsp.data.data);
      return rsp;
    });
  },
};

const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  SET_PEOPLE(state, people) {
    state.people = people;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_SELECTED_USERS_FOR_INVITE(state, users) {
    state.selectedUsersForInvite = users;
  },
  ADD_USER_TO_INVITE(state, user) {
    state.selectedUsersForInvite.push(user);
  },
  REMOVE_SELECTED_FROM_INVITE(state, user) {
    const newList = state.selectedUsersForInvite.filter(
      (selectedUser) => selectedUser.id !== user.id
    );
    Vue.set(state, "selectedUsersForInvite", newList);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
