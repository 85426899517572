<template>
  <Modal :data="$data" :isVisible="visible">
    <p class="font-main text-md text-blue-dark-4 mb-5.5">
      Are you sure you want to copy this Job Role?
    </p>
    <div class="flex flex-row flex-grow justify-end">
      <Button
        type="tertiary"
        size="medium"
        text="Close"
        @click.native="closeModal"
        class="mr-2"
      />
      <Button
        type="primary"
        text="Copy Job Roles"
        size="medium"
        @click.native="copyJobRoles"
        class="px-3"
      />
    </div>
  </Modal>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "CopyJobRolesModal",
  props: ["visible", "id", "payload"],
  data: () => ({
    title: "Copying Job Roles",
    content:
      "Copying an existing Job Roles made you will create an new instance of them including its structure, and you will be able to edit them after continuing.",
  }),
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      cloneJobRoles: "products/cloneJobRoles",
    }),
    async copyJobRoles() {
      await this.cloneJobRoles({ id: this.id, payload: this.payload }).then(
        () => {
          const id = this.$route.params.productId;
          this.$router.push(`/products/${id}/dashboard`);
        }
      );
      this.closeModal();
    },
  },
};
</script>
