<template>
  <button
    class="flex p-2 focus:outline-none w-full"
    @click="extendToggle"
    :class="source.isSelected ? 'bg-blue-light-1' : 'bg-white'"
    data-testid="toggle-checkbox"
  >
    <Checkbox isRound :active="source.isSelected" class="mt-0.4 min-w-16" />
    <div class="ml-2 flex flex-col">
      <p class="text-left font-semibold text-md text-grey-dark-1">
        {{ source.name }}
      </p>
      <p class="text-left text-sm-2 text-grey-dark-2 ">
        {{ source.description }}
      </p>
    </div>
  </button>
</template>

<script>
import Checkbox from "../../../components/Checkbox/";
export default {
  name: "JobRoleItem",
  components: { Checkbox },
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
    toggle: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    extendToggle() {
      this.toggle(this.index);
    },
  },
};
</script>
