<template>
    <div>
        <div class="w-full h-full fixed z-40 flex flex-col justify-center items-center modal">
            <div class="w-userPreview bg-white p-4 shadow overflow-hidden rounded">
                <p class="font-barlow font-bold text-grey-dark-2 text-l mb-1.5">
                    {{this.uploadText || 'Upload Preview'}}
                </p>
                <p class="text-mdh text-grey-dark-2 mb-2">
                    Review your upload list before saving the data into the platform.
                </p>
                <p class="mb-1 text-sm-2 text-red" v-if="this.usersWithFormatErrors()">
                    {{ this.usersWithFormatErrors() }} users will not be created as Email Address format is not correct.
                </p>
                <p v-if="hasInvalidUsers" class="mb-1 text-sm-2 text-red">
                    {{ invalidUsersCount }} participants have been removed as there is missing data. First Name,
                    Last Name and Email Address are mandatory fields.
                </p>
                <p class="text-sm-2 text-grey-dark-2 mb-2">
                    Showing {{ mappedList.length }} team member{{ mappedList.length != 1 ? 's' : null }}
                </p>
                <div>
                    <div
                        class="flex flex-row w-full bg-grey-light-3 py-1 pl-2 rounded"
                        :class="mappedList.length < 10 ? '' : 'pr-2'"
                    >
                        <div class="flex flex-row items-center focus:outline-none w-tableIndex mr-4">
                            <button
                                class="flex flex-row items-center focus:outline-none"
                                @click="sortIndex"
                                data-testid="header-1"
                            >
                                <p class="text-sm-2 text-grey-light mr-0.4">NO.</p>
                                <img
                                src="../../../assets/img/delta-table/caret.svg"
                                class="transition-all"
                                :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
                            />
                            </button>

                        </div>
                        <div class="flex flex-grow w-1/4">
                            <button
                                class="flex flex-row items-center focus:outline-none"
                                @click="sortName"
                                data-testid="header-1"
                            >
                                <p class="text-sm-2 text-grey-light mr-0.4">NAME</p>
                                <img
                                    src="../../../assets/img/delta-table/caret.svg"
                                    class="transition-all"
                                    :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
                                />
                            </button>
                        </div>
                        <div class="flex flex-grow w-1/4">
                            <button
                                class="flex flex-row items-center focus:outline-none"
                                @click="sortJobTitle"
                                data-testid="header-2"
                            >
                                <p class="text-sm-2 text-grey-light mr-0.4">JOB TITLE</p>
                                <img
                                    src="../../../assets/img/delta-table/caret.svg"
                                    class="transition-all"
                                    :class="rotationMatrix[2] !== 1 ? 'transform rotate-180' : null"
                                />
                            </button>
                        </div>
                        <div class="flex flex-grow w-1/4">
                            <button
                                class="flex flex-row items-center focus:outline-none"
                                @click="sortEmail"
                                data-testid="header-3"
                            >
                                <p class="text-sm-2 text-grey-light mr-0.4">EMAIL</p>
                                <img
                                    src="../../../assets/img/delta-table/caret.svg"
                                    class="transition-all"
                                    :class="rotationMatrix[3] !== 1 ? 'transform rotate-180' : null"
                                />
                            </button>
                        </div>
                        <div class="w-3.5 mr-2" />
                    </div>
                    <div>
                        <VirtualList
                            style="max-height: 360px; overflow-y: auto"
                            :data-key="'firstName'"
                            :data-sources="mappedList"
                            :data-component="itemComponent"
                            :extra-props="{ removeItem: removeItem }"
                            data-testid="user-previes-table"
                        />
                    </div>
                </div>

                <div class="flex flex-row flex-grow justify-end mt-4">
                    <Button
                        type="tertiary"
                        size="medium"
                        text="Cancel"
                        @click.native="closeModal"
                        class="mr-2"
                        data-testid="cancel-button"
                    />
                    <Button
                        text="Accept"
                        type="primary"
                        size="medium"
                        @click.native="close"
                        data-testid="save-button"
                    />
                </div>
            </div>
        </div>
        <div class="w-full h-full fixed z-30 bottom-0 bg-grey-dark-1 opacity-70 modal transition-all" />
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import ListItem from './ListItem';
import VirtualList from 'vue-virtual-scroll-list';
import { emailValidator } from '../utils/emailValidator';

export default {
    name: 'UsersPreview',
    props: ['hasInvalidUsers', 'invalidUsersCount'],
    components: { VirtualList },
    data: () => ({
        mappedList: [],
        rotationMatrix: [1, 1, 1, 1, 1],
        itemComponent: ListItem,
        wrongFormatUserCount: 0,
    }),
    computed: {
        ...mapState({
            users: (state) => state.assessmentSetup.userPreviewList,
            id: (state) => state.companies.currentCompany.id,
            uploadText: (state) => state.companies.uploadUsersText || state.assessmentSetup.uploadUsersText,
            userRoles: (state) => state.auth.decodedData.roles,
        }),
        ...mapGetters({
            isImpersonating: 'auth/isImpersonating',
        }),
    },
    methods: {
        ...mapActions({
            closeModal: 'modals/closeModal',
            saveUsers: 'assessmentSetup/saveUsers',
            saveUsersAsSeller: 'companies/saveUsersAsSeller',
            resetTextAsSeller: 'companies/resetUsersUploadText',
            resetText: 'assessmentSetup/resetUsersUploadText'
        }),
        async close() {
            let isSeller = this.userRoles.includes('SellerAdmin');
            if (isSeller && !this.isImpersonating) {
                await this.saveUsersAsSeller({ companyId: this.id, users: this.users });
            } else {
                await this.saveUsers({ users: this.mappedList });
            }
            if (this.$attrs.callback) {
                await this.$attrs.callback(true);
            }
            this.closeModal();
            if (this.$route.name === 'Onboarding') {
                this.$router.push('/');
            }
        },
        setRotation(index) {
            this.rotationMatrix = this.rotationMatrix.map((item, idx) => (idx == index ? -1 * item : 1));
        },
        sortIndex() {
            this.setRotation(0);
            this.mappedList.sort((a, b) => {
                return (this.rotationMatrix[0] == 1 ? 1 : -1) * (Object.values(b.errors).filter(x => x).length - Object.values(a.errors).filter(x => x).length);
            });
        },
        sortName() {
            this.setRotation(1);
            this.mappedList.sort((a, b) => {
                return (this.rotationMatrix[1] == 1 ? 1 : -1) * (a.firstName.toUpperCase().localeCompare( b.firstName.toUpperCase()));
            });
        },
        sortJobTitle() {
            this.setRotation(2);
            this.mappedList.sort((a, b) => {
                return (this.rotationMatrix[2] == 1 ? 1 : -1) * (a.jobTitle.toUpperCase().localeCompare( b.jobTitle.toUpperCase()));
            });
        },
        sortEmail() {
            this.setRotation(3);
            this.mappedList.sort((a, b) => {
                return (this.rotationMatrix[3] == 1 ? 1 : -1) * ( a.email.toUpperCase().localeCompare(b.email.toUpperCase()));
            });
        },
     
        removeItem(index) {
            this.mappedList.splice(index, 1);
            let map = this.mappedList.map((item, idx) => ({
                ...item,
                index: idx,
            }));
            this.mappedList = [...map];
        },
        usersWithFormatErrors() {
            return this.mappedList.map(x => Object.values(x.errors).reduce((prev, current) => prev || current, false)).reduce((prev, current) => (+(prev)) + current, 0)
        }
    },
    mounted() {
        this.resetTextAsSeller();
        this.resetText();
        this.wrongFormatUserCount = 0;
        let map = this.users.map((item, idx) => {
            const errors = {
                    firstName: !item.firstName,
                    lastName: !item.lastName,
                    email: !item.email || !emailValidator(item.email)
                };
            this.wrongFormatUserCount += +Object.values(errors).reduce((prev, current) => prev || current, false)
            return {
                ...item,
                index: idx,
                errors,
            }
        })
        .sort((a, b) => Object.values(b.errors).filter(x => x).length - Object.values(a.errors).filter(x => x).length);
        this.mappedList = [...map];
    },
};
</script>

<style lang="scss" scoped>
.modal {
    top: 0;
}
</style>
