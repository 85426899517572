<template>
  <div class="inputContainer">
    <p
      class="text-sm-2 text-grey-light font-medium mb-0.4 relative textLabel"
      :class="[isTextOk !== null && 'text-red']"
    >
      {{ label }}
    </p>
    <div class="flex items-center flex-grow relative">
      <textarea
        v-model="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="maxLength"
        class="w-full outline-none placeholder-grey-medium-2 font-medium text-md text-grey-dark-1 px-2 py-1 border rounded focus:border-blue-dark-1 disabled:text-grey-light disabled:placeholder-grey-light-1 disabled:border-grey-light-1 disabled:bg-white"
        :class="[colorClass, height]"
        @input="onChange(value)"
      />
    </div>
    <p
      v-if="isTextOk !== null"
      :class="!isTextOk ? 'text-red' : 'text-green'"
      class="text-sm mt-0.4 absolute"
    >
      {{ errorText }}
    </p>
  </div>
</template>
<script>
export default {
  name: "TextArea",
  data() {
    return { value: "" };
  },
  props: {
    label: { type: String, default: null },
    defaultValue: { type: String, default: null },
    placeholder: { type: String, default: null },
    onChange: { type: Function, default: null },
    isTextOk: { type: Boolean, default: null },
    errorText: { type: String, default: null },
    customHeight: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    maxLength: { type: String, default: "1000"}
  },
  mounted() {
    this.value = this.defaultValue || "";
  },
  computed: {
    colorClass() {
      switch (this.isTextOk) {
        case true:
          return "border-green focus:border-green";
        case false:
          return "border-red focus:border-red";
        default:
          return "border-grey-light-1";
      }
    },
    height() {
      return this.customHeight ? this.customHeight : "min-h-200";
    },
  },
};
</script>
<style lang="scss" scoped>
.inputContainer {
  &:hover {
    .textLabel {
      color: #00a1d5;
    }
    .inputField {
      border-color: #00a1d5;
    }
  }
}
</style>
