<template>
  <div class="rounded bg-white shadow py-0 mt-2 pb-3">
    <p class="font-barlow text-mdl font-bold text-grey-light py-3 ml-2">
      {{ title }} 
    </p>
    <div
      v-for="(assessment, index) in assessments"
      :key="assessment.id"
      class="mx-4"
    >
      <StatusItem :assessment="assessment" />
      <div class="flex justify-end">
        <router-link :to="link(assessment)">
          <Button
            text="View details"
            type="secondary"
            :iconRight="arrow"
            size="medium"
          />
        </router-link>
      </div>
      <template v-if="index + 1 < assessments.length"
        ><div class="border my-3 border-grey-light-1"
      /></template>
    </div>
  </div>
</template>
<script>
import Button from "../Button/Button.vue";
import StatusItem from "./StatusItem.vue";
import arrow from "@/assets/img/icons/arrow-right.svg";

export default {
  name: "StatusList",
  components: { StatusItem, Button },
  props: {
    assessments: { type: Array, default: [] },
    teamName: { type: String, default: "" },
    type: { type: String, default: "manager" },
  },
  data: () => ({ arrow }),
  computed: {
    title() {
      return this.type === "manager"
        ? `${this.teamName} Active Assessments`
        : "Active Assessments";
    },
  },
  methods: {
    link(assessment) {
      return this.type === "manager"
        ? `/team-results/${assessment.id}/${assessment.teamId}`
        : `/company-results/${assessment.id}`;
    },
  },
};
</script>
