<template>
  <div>
    <div
      class="w-full h-full fixed z-40 bottom-0 flex justify-center items-center modal"
    >
      <div class="w-modal bg-white p-4 shadow flex-grow-0 rounded">
        <div class="flex flex-col mt-2">
          <p class="text-grey-dark-1 text-l font-bold mb-1.5">
            Remove Admin Rights
          </p>
          <div>
            <p class="text-grey-light text-sm-2 mb-3">
              If you remove this user as admin, they will no longer be able to
              manage this company. Other roles of the user will not be affected.
              If the user doesn't have other roles, their account will be
              deactivated.
            </p>
            <p class="text-grey-light text-sm-2">
              Are you sure you want to remove admin rights for this user?
            </p>
            <div class="flex flex-row mt-3">
              <div class="pt-0.5">
                <Checkbox
                  class="cursor-pointer"
                  :active="checked"
                  @click.native="toggleChecked"
                  data-testid="permission-checkbox"
                />
              </div>
              <p
                class="text-mdh pl-1 cursor-pointer text-grey-dark-2"
                @click="toggleChecked"
              >
                I understand that if this user has no other roles, their account
                will be deactivated.
              </p>
            </div>
          </div>
        </div>
        <div class="flex flex-row flex-grow justify-end mt-4 space-x-2">
          <Button
            type="tertiary"
            size="medium"
            text="Close"
            @click.native="closeModal"
            data-testid="close-button"
          />

          <button
            class="bg-red text-white rounded text-md flex justify-center items-center font-semibold px-2 py-1 disabled:bg-grey-medium-2 disabled:text-opacity-50 disabled:pointer-events-none"
            :disabled="disableSave"
            data-testid="save-button"
            @click="update"
          >
            Remove Admin Rights
          </button>
        </div>
      </div>
    </div>
    <div
      class="w-full h-full fixed z-30 bottom-0 bg-grey-dark-1 opacity-70 modal transition-all"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "RemoveAdminRightsModal",
  data: () => ({ checked: false }),
  computed: {
    ...mapState({
      selectedUser: (state) => state.companies.selectedUser,
      currentCompany: (state) => state.companies.currentCompany,
    }),
    disableSave() {
      return !this.checked;
    },
  },
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      editCompanyAdmin: "companies/editCompanyAdmin",
    }),
    toggleChecked() {
      this.checked = !this.checked;
    },
    async update() {
      const payload = {
        id: this.selectedUser.id,
        firstName: this.selectedUser.firstName,
        lastName: this.selectedUser.lastName,
        email: this.selectedUser.email,
        removeAdminRights: true,
      };
      await this.editCompanyAdmin({
        id: this.currentCompany.id,
        payload,
      });
      this.closeModal();
      this.$router.push(`/company/${this.currentCompany.id}/overview`);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  top: 0;
}
</style>
