<template>
  <Modal :data="$data" :isVisible="visible">
    <div class="mb-6">
      <InputField
        label="Name"
        placeholder="E.x. Lorem Ipsum"
        :onChange="(value) => (name = value)"
        :defaultValue="productDraftName"
        class="mb-2"
      />
      <div>
        <CustomCheckbox
          title="Minor Updates"
          description="A minor updates include small adjustments and improvements, that doesn’t change the behaviour of the existing assessments."
          :onClick="() => setIsMajor(false)"
          :active="!isMajor"
          class="mb-3"
        />
        <CustomCheckbox
          title="Major Updates"
          description="A major updates bring substantial changes that may include new questions, remove or add new competencies and job roles, or different kind of questions and communications messages"
          :onClick="() => setIsMajor(true)"
          :active="isMajor"
        />
      </div>
    </div>
    <div class="flex flex-row flex-grow justify-end">
      <Button
        type="tertiary"
        size="medium"
        text="Close"
        @click.native="closeModal"
        class="mr-2"
      />
      <Button
        type="primary"
        text="Continue"
        size="medium"
        @click.native="createVersion"
        :disabled="disableSave"
        class="px-3"
      />
    </div>
  </Modal>
</template>
<script>
import { mapActions } from "vuex";

import InputField from "../../../components/InputField/InputField.vue";
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox";

export default {
  name: "NewVersionSettingsModal",
  props: ["visible", "id", "productDraftName"],
  components: { InputField, CustomCheckbox },
  data: () => ({
    title: "New Version Settings",
    content:
      "When a new version is created you need to define if its a minor or major update and set a version name.",
    name: "",
    isMajor: false,
  }),
  computed: {
    disableSave() {
      return this.name.trim() === "";
    },
  },
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      createNewVersion: "products/createNewVersion",
      getDrafts: "products/getDrafts",
      resetProductDetails: "products/resetProductDetails",
      showError: "alerts/showError",
    }),
    async createVersion() {
      await this.createNewVersion({
        isMajor: this.isMajor,
        name: this.name,
        productId: this.id,
      })
        .then((id) => {
          this.resetProductDetails();
          this.$router.push(`/products/${id}/dashboard`);
        })
        .catch((err) => {
          var error = {
            messageHeader: "Error",
            message: err.message || "One version of this product already exists in product drafts.",
          };
          try {
            var parsedError = JSON.parse(err.toString().substring(err.toString().indexOf('{'), err.length)) 
            error.messageHeader = parsedError.Title || "Error";
            error.message = parsedError.Message || "One version of this product already exists in product drafts.";
          } catch {}
          this.showError(error);
        });   
      this.closeModal();
    },
    setIsMajor(value) {
      this.isMajor = value;
    },
  },
  created(){
    this.name = this.productDraftName;
  }
};
</script>
