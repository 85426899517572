const getDefaultState = () => ({});

const actions = {
  resetCallsState({ commit }) {
    commit("resetState");
  },
  showError(_, { messageHeader, message }) {
    this._vm.$toasted.global.toasty_error({ messageHeader, message });
  },
  showInfo(_, { messageHeader, message }) {
    this._vm.$toasted.global.toasty_info({ messageHeader, message });
  },
  showSuccess(_, { messageHeader, message }) {
    this._vm.$toasted.global.toasty_success({ messageHeader, message });
  },
  showWarning(_, { messageHeader, message }) {
    this._vm.$toasted.global.toasty({ messageHeader, message });
  },
};
const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
