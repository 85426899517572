<template>
  <Modal :data="$data" :isVisible="visible">
    <p class="font-main text-md text-blue-dark-4 mb-5.5">
      Are you sure you want to remove this Job Role?
    </p>
    <div class="flex flex-row flex-grow justify-end">
      <Button
        type="tertiary"
        size="medium"
        text="Close"
        @click.native="closeModal"
        class="mr-2"
      />
      <Button
        type="danger"
        text="Remove"
        size="medium"
        @click.native="removeJobRole"
        class="px-3"
      />
    </div>
  </Modal>
</template>
<script>
import { mapActions, mapState } from "vuex";

import { deleteJobRole } from "../../../services/api/products";

export default {
  name: "DeleteJobRoleModal",
  props: ["visible", "id"],
  data: () => ({
    title: "Remove Job Role",
    content:
      "Deleting this job role means it will be permanently removed from the current assessment draft.",
  }),
  computed: {
    ...mapState({
      productDetails: (state) => state.products.productDetails,
    }),
  },
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      getProductById: "products/getProductById",
    }),
    async removeJobRole() {
      await deleteJobRole(this.id).then(
        async () =>
          await this.getProductById({ id: this.productDetails.productId })
      );
      this.closeModal();
    },
  },
};
</script>
