<template>
  <Modal :data="$data" :isVisible="visible">
    <div class="flex flex-row flex-grow justify-end">
      <Button
        type="tertiary"
        size="medium"
        text="No"
        @click.native="closeModal"
        class="mr-2"
      />
      <Button
        type="primary"
        text="Yes"
        size="medium"
        @click.native="confirm"
        class="px-3"
      />
    </div>
  </Modal>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "ManageAssessmentModal",
  props: ["visible", "id", "productDraftName"],
  data: () => ({
    title: "Manage Assessment",
    content:
      "When a product is manage a new version of the assessment is created, you will be able to edit some of its content and it will be moved from Launched Products to the Product Drafts section until you launch the new version.",
    confirmationText:
      "Are you sure you want to create a new version of this product?",
  }),
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      openModal: "modals/openModal",
    }),
    confirm() {
      this.openModal({
        modal: "newVersionSettingsModal",
        props: { id: this.id, productDraftName: this.productDraftName},
      });
    },
  },
};
</script>
