<template>
  <Modal :data="$data" :isVisible="true">
    <div class="flex flex-row ">
      <div class="flex flex-row flex-grow justify-end">
        <Button
          type="tertiary"
          size="medium"
          text="Cancel"
          @click.native="closeModal"
          class="mr-2"
        />
        <Button
          text="Continue"
          type="primary"
          size="medium"
          @click.native="close"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "AddingParticipantsModal",
  props: ["visible", "next"],
  data: () => ({
    title: "Changes to the participants list",
    content:
      "If you want to invite the new participants to the assessment you need to follow the setup process and fill in all the necessary data. The new Participants will be invited to the assessment once you commit to all changes by clicking the Update button at the end of the setup.",
    confirmationText: "",
  }),
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      setCurrentStep: "assessmentSetup/setCurrentStep"
    }),
    close() {
      this.closeModal();
      this.setCurrentStep(1);
      this.next();
    },
  },
};
</script>
