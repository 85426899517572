<template>
  <button
    class="flex w-iconBtn h-iconBtn bg-white justify-center items-center cursor-pointer"
    :disabled="disabled"
    @click="onClick"
  >
    <img :src="icon" />
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    icon: { default: null, type: String },
    disabled: { default: false, type: Boolean },
    onClick: { default: () => {}, type: Function },
  },
};
</script>
