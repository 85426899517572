<template>
  <div class="flex flex-row space-x-2">
    <div
      class="flex w-full mb-3 justify-between rounded bg-white shadow py-0 mt-2 p-3"
    >
      <div>
        <p class="font-barlow text-mdl font-bold text-grey-light mb-2">
          Team Skill Gaps
        </p>
        <p class="text-md text-grey-dark-1 ml-3">No Data to show yet.</p>
      </div>
    </div>
    <div
      class="flex w-full mb-3 justify-between rounded bg-white shadow py-0 mt-2 p-3"
    >
      <div>
        <p class="font-barlow text-mdl font-bold text-grey-light mb-2">
          Team Strengths
        </p>
        <p class="text-md text-grey-dark-1 ml-3">No Data to show yet.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClosedEmptyState",
};
</script>