<template>
  <div>
    <div
      class="w-full h-full fixed z-40 bottom-0 flex flex-col justify-center items-center modal"
    >
      <div class="w-modal bg-white p-4 shadow overflow-hidden rounded">
        <p class="font-barlow font-bold text-grey-dark-2 text-l mb-1.5">
          {{ isExiting ? "Exit Assessment Setup" : "Leave this step" }}
        </p>
        <p class="text-mdh text-grey-dark-2">
          Your assessment set up is in progress. Click
          {{ isExiting ? "Exit" : "Leave" }} and Save to save your progress.
        </p>

        <div class="flex flex-row flex-grow justify-end mt-4.5">
          <Button
            type="tertiary"
            size="medium"
            text="Cancel"
            class="mr-2"
            @click.native="close"
            data-testid="close-button"
          />
          <Button
            :text="isExiting ? 'Exit Without Saving' : 'Leave Without Saving'"
            type="secondary"
            size="medium"
            class="mr-2"
            @click.native="onlyNext"
            data-testid="next-button"
          />
          <Button
            :text="isExiting ? 'Exit And Save' : 'Leave And Save'"
            type="primary"
            size="medium"
            @click.native="nextWithSave"
            data-testid="save-button"
          />
        </div>
      </div>
    </div>
    <div
      class="w-full h-full fixed z-30 bottom-0 bg-grey-dark-1 opacity-70 modal transition-all"
    />
  </div>
</template>

<script>
import Button from "../../../components/Button/Button.vue";
import { mapActions } from "vuex";
export default {
  components: { Button },
  name: "ExitAssessmentModal",
  props: {
    next: { type: Function },
    save: { type: Function },
    path: { type: String },
    noSaveHelper: { type: Function, default: () => {} },
  },
  computed: {
    isExiting() {
      return !this.path.includes("assessments/");
    },
  },
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
    }),
    close() {
      this.closeModal();
    },
    onlyNext() {
      this.closeModal();
      this.next();
      this.noSaveHelper();
    },
    async nextWithSave() {
      await this.save();
      this.closeModal();
      this.next();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  top: 0;
}
</style>
