<template>
  <div>
    <fusioncharts
      :type="type"
      :width="width"
      :height="this.height || '400'"
      :dataFormat="dataFormat"
      :dataSource="dataSource"
      :id="id"
    ></fusioncharts>
  </div>
</template>

<script>
import Vue from "vue";
import FusionCharts from "fusioncharts";
import VueFusionCharts from "vue-fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";

import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

Vue.use(VueFusionCharts, FusionCharts, FusionTheme);

// Resolves charts dependency
Charts(FusionCharts);

export default {
  name: "LineBarChart",
  props: [
    "categories",
    "barData",
    "lineData",
    "lineData2",
    "lineData3",
    "plotSpace",
    "barTitle",
    "barColor",
    "hideLegend",
    "height",
    "id",
    "yAxisMaxValue",
    "numDivLines",
  ],
  data: () => ({
    type: "mscombi2d",
    width: "100%",
    dataFormat: "json",
  }),
  computed: {
    dataSource() {
      const data = {
        chart: {
          theme: "fusion",
          plotSpacePercent: this.plotSpace || 100,
          yAxisValueDecimals: "1",
          forceDecimals: true,
          yAxisMaxValue: this.yAxisMaxValue || "4",
          yAxisValuesPadding: "50",
          yAxisMinValue: "0",
          setAdaptiveYMin: "0",
          adjustDiv: "0",
          numDivLines: this.numDivLines || "7",
          divLineDashed: "1",
          divLineDashLen: "15",
          divLineDashGap: "6",
          divLineColor: "#D0DAE3",
          crosslinecolor: "#EFF7FD",
          labelFontSize: "12",
          labelFontColor: "#748FA8",
          canvasTopPadding: "15",
          showLegend: this.hideLegend ? "0" : "1",
          interactiveLegend: "0",
          labelDisplay: "rotate",
          slantLabel: "1",
          legendPosition: "bottom-right",
          tooltipborderradius: "4",
          toolTipBorderColor: "#E8EBF2",
          toolTipPadding: "12",
          toolTipColor: "#7780A1",
          showBorder: 0,
        },
        categories: [
          {
            category: this.categories,
          },
        ],
        dataset: [
          {
            seriesName: this.barTitle,
            color: this.barColor,
            ...this.barData,
          },
          this.lineData,
        ],
      };
      if (this.lineData2) {
        data.dataset.push(this.lineData2);
      }
      if (this.lineData3) {
        data.dataset.push(this.lineData3);
      }
      return data;
    },
  },
};
</script>
