<template>
  <div :data-testid="`listitem-${index}`">
    <div class="flex flex-row w-full py-2 pl-2 items-center">
      <div class="flex w-tableIndex justify-center mr-1 pr-2">
        <p class="font-normal text-md text-grey-dark-2">
          {{ index + 1 }}
        </p>
      </div>
      <div class="flex w-2 justify-center mr-2">
          <div v-if="showError" class="w-10 h-10 rounded-full bg-red" />
      </div>
      <div class="flex flex-grow w-1/4">
        <p class="text-md text-grey-dark-1 font-semibold" v-clamp="25">
          {{ fullName }}
        </p>
      </div>
      <div class="flex flex-grow w-1/4">
        <p class="text-md text-grey-dark-2" v-clamp="25">
          {{ source.jobTitle }}
        </p>
      </div>
      <div class="flex flex-grow w-1/4">
        <p class="text-md text-grey-dark-2" v-clamp="25">
          {{ source.email }}
        </p>
      </div>
      <button
        class="ml-auto mr-2 p-1 bg-blue-light-5 rounded"
        @click="removeItem(source.index)"
        data-testid="remove-button"
      >
        <img src="@/assets/img/icons/remove.svg" />
      </button>
    </div>
    <div class="border border-grey-light-1 border-top-none" />
  </div>
</template>
<script>
export default {
  name: "ListItem",
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
    removeItem: { type: Function },
  },
  computed: {
    fullName() {
      return this.source.firstName + " " + this.source.lastName;
    },
    showError() {
      return this.source?.errors != null && Object.values(this.source.errors).reduce((prev, current) => prev || current, false);
    }
  },
};
</script>
