<template>
  <div>
    <div
      class="w-full h-full fixed z-40 bottom-0 flex justify-center items-center modal"
    >
      <div class="w-modal bg-white p-4 shadow flex-grow-0 rounded">
        <h1 class="font-headers font-bold text-grey-dark-2 text-l mb-1.5">
          Deactivate Team
        </h1>
          <div class="ml-2">
            <p class="text-grey-dark text-mdh">
              If you decide to deactivate this team, you will not be able to assign this team to future assessments but we will keep its data for reporting purposes.
            </p>
            <div class="flex flex-row mt-1.5">
              <div class="pt-0.5">
                <Checkbox
                  class="cursor-pointer"
                  :active="checkboxes.assign"
                  @click.native="check('assign')"
                />
              </div>
              <p
                class="text-mdh pl-1 cursor-pointer text-grey-dark-2"
                @click="check('assign')"
              >
                I accept that I will not be able to assign this team to future assessments.
              </p>
            </div>
        </div>
        <div class="flex flex-row flex-grow justify-end mt-4 space-x-2">
          <Button
            type="tertiary"
            size="medium"
            text="Cancel"
            @click.native="closeModal"
          />
          <Button
            text="Deactivate Team"
            type="primary"
            size="medium"
            :disabled="showErr"
            @click.native="deactivate()"
          />
        </div>
      </div>
    </div>
    <div
      class="w-full h-full fixed z-30 bottom-0 bg-grey-dark-1 opacity-70 modal transition-all"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "DeleteTeamModal",
  props: {
    teamId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    checkboxes: {
      assign: false,
    }
  }),
  computed: {
    showErr() {
      if (this.checkboxes.assign) {
        return;
      } 
      return true;
    }
  },
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      deactivateTeamById: "adminTeams/deactivateTeamById"
    }),
    check(agreement) {
      this.checkboxes[agreement] = !this.checkboxes[agreement];
    },
    async deactivate() {
      await this.deactivateTeamById({ id: this.teamId });
      this.$router.push("/admin-teams");
      this.closeModal();
    },
  },

};
</script>

<style lang="scss" scoped>
.modal {
  top: 0;
}
</style>
