<template>
  <Modal :data="$data" :isVisible="visible">
    <p class="font-main text-md text-blue-dark-4 mb-5.5">
      Are you sure you want to delete this draft product?
    </p>
    <div class="flex flex-row flex-grow justify-end">
      <Button
        type="tertiary"
        size="medium"
        text="Close"
        @click.native="closeModal"
        class="mr-2"
      />
      <Button
        type="danger"
        text="Delete"
        size="medium"
        @click.native="deleteDraft"
        class="px-5.5"
      />
    </div>
  </Modal>
</template>
<script>
import { mapActions } from "vuex";
import { deleteDraftProduct } from "../../../services/api/products";

export default {
  name: "DeleteProductModal",
  props: ["visible", "id", "isProductsView"],
  data: () => ({
    title: "Delete Draft",
    content:
      "Deleting this draft product means it will be permanently removed from the platform.",
  }),
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      getDrafts: "products/getDrafts",
    }),
    deleteDraft() {
      deleteDraftProduct(this.id).then(() =>
        this.isProductsView ? this.getDrafts() : this.$router.push("/products")
      );
      this.closeModal();
    },
  },
};
</script>
