<template>
  <Modal :data="$data" :isVisible="true">
    <div class="flex flex-row ">
      <!-- <Button
          text="Quit and Discard"
          type="secondary"
          size="medium"
          class="mr-2"
        /> -->
      <div class="flex flex-row flex-grow justify-end">
        <Button
          type="tertiary"
          size="medium"
          text="Close"
          @click.native="closeModal"
          class="mr-2"
        />
        <Button
          :text="allowInMultipleSessions ? 'Exit and Save' : 'Exit'"
          type="primary"
          size="medium"
          @click.native="close"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "QuitSurveyModal",
  props: ["visible", "allowInMultipleSessions"],
  data: () => ({
    title: "Exit Assessment",
    content:
      "Please click Exit and Save to close the assessment and save your progress. Click close to return to the survey.",
    confirmationText: "",
  }),
  mounted() {
    if (!this.allowInMultipleSessions) {
      this.content = "Are you sure you want to exit? The survey can't be edited after this step, and all progress will be lost. Click close to return to the survey.";
    }
  },
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
    }),
    close() {
      this.closeModal();
      this.$router.push("/");
    },
  },
};
</script>
