export default [
  {
    asses_id: 1,
    id: 1,
    ord: 0,
    question:
      "Understanding my organization's supplier portfolio supports optimum supplier management. To contribute to this, I:",
    type: 0,
    answers: [
      {
        id: 1,
        text:
          "Understand implications of portfolio analysis to supplier volume and market difficulty in supplier relationships.",
      },
      {
        id: 2,
        text:
          "Drive strategies around supplier relationships that matter most (e.g. strategic) and set expectations for tough negotiations with leveraged suppliers. Drive a multi-year plan to shift categories to preferred quadrant.",
      },
      {
        id: 3,
        text:
          "Look for opportunities to treat suppliers appropriately given their relative portfolio volumes and market complexity.",
      },
      {
        id: 4,
        text:
          "Possess basic understanding of the portfolio of suppliers and whether they are critical, acquired, strategic or leveraged.",
      },
      {
        id: 5,
        text: "I have no knowledge or experience with this topic.",
      },
    ],
  },
  {
    asses_id: 1,
    id: 2,
    ord: 1,
    question:
      "Quality is an ongoing process and objective. In support of my organization's quality initiatives, I:",
    type: 0,
    answers: [
      {
        id: 1,
        text:
          "Recommend ideas and plans for continuous improvement, with input from internal stakeholders and suppliers.",
      },
      {
        id: 2,
        text:
          "Develop and implement documented plans (defined by internal stakeholders and suppliers) for quality continuous improvement that incorporates a cost analysis.",
      },
      {
        id: 3,
        text:
          "Set cultural expectations for a quality continuous improvement philosophy that incorporates cost of quality.",
      },
      {
        id: 4,
        text:
          "Develop infrastructure (with internal stakeholders and suppliers) to drive cost-effective, quality continuous improvement.",
      },
      {
        id: 5,
        text: "I have no knowledge or experience with this topic.",
      },
    ],
  },
  {
    asses_id: 1,
    id: 3,
    ord: 2,
    question:
      "Big Data (Use of Data) can provide guidance and wisdom in creating a successful enterprise. To facilitate this, I:",
    type: 0,
    answers: [
      {
        id: 1,
        text:
          "Understand the systems, applications and mechanisms associated with data management. Control and use them appropriately with little guidance.",
      },
      {
        id: 2,
        text:
          "Support and promote the use of appropriate data management, controls and data mining applications/systems. Am asked for advice in existing system use, as well as future system development.",
      },
      {
        id: 3,
        text:
          "Strategically drive appropriate data management, control and data mining applications/systems. Provide the leadership for senior sponsorship in organizational adoption.",
      },
      {
        id: 4,
        text:
          "Have basic knowledge of the various systems, applications and mechanisms. Follow established processes/procedures relating to data management and control. May seek help/advice on the most suitable for purpose.",
      },
      {
        id: 5,
        text: "I have no knowledge or experience with this topic.",
      },
    ],
  },
  {
    asses_id: 1,
    id: 4,
    ord: 3,
    question:
      "Supply management is a key player in preventing or resolving disputes. Currently, I'm most likely to:",
    type: 0,
    answers: [
      {
        id: 1,
        text:
          "Anticipate and identify conflicts, attempt to remove conflict situations before referring to management and actively work with internal customers and suppliers to prevent disputes",
      },
      {
        id: 2,
        text:
          "Efficiently manage a team, while identifying and effectively handling a range of conflict management situations. Work with all internal stakeholders in the contracting process in case of potential dispute. Ensure contracts are continually amended to reflect performance status.",
      },
      {
        id: 3,
        text:
          "Work with legal and the internal customer to ensure the dispute is resolved and that a mechanism is put in place to prevent further dispute. Efficiently manage team(s), handling conflict and resolving effectively, as appropriate.",
      },
      {
        id: 4,
        text:
          "Be able to anticipate (or have key awareness of) when conflict arises and seek help/support to address issues.",
      },
      {
        id: 5,
        text: "I have no knowledge or experience with this topic.",
      },
    ],
  },
  {
    asses_id: 1,
    id: 5,
    ord: 4,
    question:
      "Internal and external collaboration are critical for organization's future. It is accurate to say that:",
    type: 0,
    answers: [
      {
        id: 1,
        text:
          "I collaborate with functional counterparts/stakeholders on optimization of materials/services, solicit ideas from suppliers, communicate broader category goals to suppliers and track/manage improvement plans resulting from collaboration.",
      },
      {
        id: 2,
        text:
          "I develop strategic goals with adjacent functions/stakeholders to assure continued collaboration and ensure overall business strategy is fully reflected in each category strategy required for the company/industry.",
      },
      {
        id: 3,
        text:
          "I routinely confer with internal functions/stakeholders on design, use attributes/budget planning/expectations, collaborate accordingly with suppliers based on internal feedback and provide follow-through for enhancements resulting from collaboration.",
      },
      {
        id: 4,
        text:
          "I understand the need and participate in internal-external communications with company stakeholders/supplier representatives at all levels of responsibility.",
      },
      {
        id: 5,
        text: "I have no knowledge or experience with this topic.",
      },
    ],
  },
];
