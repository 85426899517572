export const getImageDataByType = (type) => {
    switch (type) {
        case 'Intro':
            return { name: "introImage", type: 0 };
        case 'Outro':
            return { name: "outroImage", type: 1 };
        case 'Closed':
            return { name: "closedImage", type: 2 };
        default:
            break;
    }
}
