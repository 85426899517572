<template>
  <Modal :data="$data" :isVisible="visible" hasCloseIcon>
    <TextArea
        label="Release notes"
        class="mb-3 w-full"
        :customHeight="'min-h-100 h-100'"
        :onChange="setReleaseNotes"
        :defaultValue="productDetails.releaseNotes"
        :placeholder="'Notes for the changes of this version'"
        max-length="500"
      />
    <p v-if="!isVersionDraft" class="font-main text-md text-blue-dark-4 mb-5.5">
      Are you sure you want to launch it now?
    </p>
    <div class="flex flex-row flex-grow justify-end">
      <Button
        type="tertiary"
        size="medium"
        text="Publish Later"
        @click.native="publishLater"
        class="mr-2"
      />
      <Button
        type="primary"
        text="Publish Now"
        size="medium"
        @click.native="launch"
        class="px-3.5"
      />
    </div>
  </Modal>
</template>
<script>
import { mapActions, mapState } from "vuex";
import TextArea from "../../../components/TextArea";

export default {
  name: "PublishProductModal",
  props: ["visible"],
  components: {TextArea},
  data: () => ({
    title: "Publish Product",
    content:
      "When a product is launched it becomes accessible to all customers on the platform.",
    }),
  computed: {
    ...mapState({
      productDetails: (state) => state.products.productDetails,
    }),
    isVersionDraft() {
      return this.productDetails?.productVersions.length > 1;
    },
  },
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      openModal: "modals/openModal",
      publishProduct: "products/publishProduct",
      setProductReleaseNotes: "products/setProductReleaseNotes",
    }),
    launch() {
      const productId = this.productDetails?.productId;
      const releaseNotes = this.productDetails.releaseNotes;
      this.publishProduct({ id: productId, payload: { productId, releaseNotes } }).then(() =>
        this.$router.push("/products")
      );
      this.closeModal();
    },
    publishLater() {
      this.openModal({ modal: "scheduleProductLaunchModal" });
    },
    setReleaseNotes(value) {
      this.setProductReleaseNotes(value);
    }
  },
  mounted() {
    if (this.isVersionDraft) {
      this.title = `Publish Assessment Version ${this.productDetails.currentVersion}`;
      this.content =
        "A new version of this assessment will be created, the current version will be removed from Launched Products and no longer be accessible to customers. When a new version of a product is launched it becomes accessible to customers on the platform.";
    }
  },
};
</script>
