<template>
  <Modal :data="$data" :isVisible="visible">
    <p class="font-main text-md text-blue-dark-4 mb-5.5">
      Are you sure you want to delete this survey?
    </p>
    <div class="flex flex-row flex-grow justify-end">
      <Button
        type="tertiary"
        size="medium"
        text="Close"
        @click.native="closeModal"
        class="mr-2"
      />
      <Button
        type="danger"
        text="Delete Survey"
        size="medium"
        @click.native="deleteSurvey"
        class="px-5.5"
      />
    </div>
  </Modal>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { deleteSurvey } from "../../../services/api/products";

export default {
  name: "DeleteSurveyModal",
  props: ["visible", "id"],
  data: () => ({
    title: "Deleting Survey",
    content:
      "Deleting a survey means that you will remove all the information relating with the assessment, scores, questions, answers. This action cannot be undone.",
  }),
  computed: {
    ...mapState({
      productDetails: (state) => state.products.productDetails,
    }),
  },
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      getProductById: "products/getProductById",
    }),
    deleteSurvey() {
      deleteSurvey(this.id).then(() =>
        this.getProductById({ id: this.productDetails.productId })
      );
      this.closeModal();
    },
  },
};
</script>
