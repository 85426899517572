<template>
  <div>
    <div
      class="w-full h-full fixed z-40 bottom-0 flex justify-center items-center modal"
    >
      <div class="w-modal bg-white p-4 shadow flex-grow-0 rounded">
        <p class="font-headers font-bold text-grey-dark-2 text-l mb-1.5">
          Deactivate Company
        </p>
        <div>
          <p class="text-mdh text-grey-dark-2 mb-4">
            Deactivating a company means that its users will no longer be able
            to create and run assessments. They will only be able to see their
            past results. Companies can be re-activated at any stage.
          </p>
          <p class="text-mdh text-grey-dark-2">
            Are you sure you want to deactivate this company?
          </p>
        </div>
        <div class="flex flex-row flex-grow justify-end mt-4 space-x-2">
          <Button
            type="tertiary"
            size="medium"
            text="Close"
            @click.native="closeModal"
            data-testid="close-button"
          />
          <button
            class="bg-red px-2 py-1 font-semibold text-md text-white rounded"
            @click="deactivate"
            data-testid="disable-seller-button"
          >
            Deactivate Company
          </button>
        </div>
      </div>
    </div>
    <div
      class="w-full h-full fixed z-30 bottom-0 bg-grey-dark-1 opacity-70 modal transition-all"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "DeactivateCompanyModal",
  props: { id: { type: Number } },
  computed: {
    ...mapState({ currentCompany: (state) => state.companies.currentCompany }),
  },
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      edit: "companies/editCompany",
    }),
    async deactivate() {
      let payload = {
        name: this.currentCompany.name,
        industryId: this.currentCompany.industryId,
        active: false,
      };
      await this.edit({
        companyId: this.currentCompany.id,
        payload,
      });
      this.$router.push(`/company/${this.currentCompany.id}/overview`);
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  top: 0;
}
</style>
