<template>
  <Modal :data="modalData" :isVisible="visible" hasCloseIcon showOverflow>
    <div class="flex flex-col gap-2 mb-4">
      <DatePicker
        v-model="launchDate"
        mode="dateTime"
        :min-date="new Date()"
        :timezone="this.timezone ? this.timezone.id : 'UTC'"
        :valid-hours="validHours"
        :is-required="true"
        is24hr
        :model-config="{
          type: 'string',
        }"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <div>
            <p class="text-sm-2 text-grey-light font-medium mb-0.4 relative">
              Launch Date
            </p>
            <div class="relative">
              <input
                :value="inputValue"
                v-on="inputEvents"
                class="w-full outline-none placeholder-grey-light-2 font-medium text-md text-grey-dark-1 px-2 py-1 border-grey-light-1 border rounded focus:border-blue-dark-1 disabled:text-grey-light disabled:placeholder-grey-light-1 disabled:border-grey-light-1 disabled:bg-white"
              />
              <img
                src="../../../assets/img/icons/date.svg"
                class="absolute right-2 inputIcon"
              />
            </div>
          </div>
        </template>
      </DatePicker>
      <Select
        label="Timezone"
        searchable
        :value="timezone"
        :options="timezonesList"
        :onSelect="(value) => (timezone = value)"
      />
    </div>
    <div class="flex flex-row flex-grow justify-end">
      <Button
        type="tertiary"
        size="medium"
        text="Back"
        class="mr-2"
        @click.native="goBack"
      />
      <Button
        type="primary"
        text="Schedule"
        size="medium"
        class="px-3.5"
        @click.native="scheduleLaunch"
      />
    </div>
  </Modal>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";
import momentTimezone from 'moment-timezone';

import { getTimezones } from "../../../services/api/products";

export default {
  name: "ScheduleProductLaunchModal",
  props: ["visible"],
  components: { DatePicker },
  data: () => ({
    modalData: {
      title: "Launch Schedule",
      content:
        "Select Date and timezone for launching the new version of this assessment.",
    },
    launchDate: new Date().toISOString(),
    timezone: null,
    timezonesList: [],
  }),
  computed: {
    ...mapState({
      productDetails: (state) => state.products.productDetails,
      releaseNotes: (state) => state.products.productDetails.releaseNotes,
    }),
    ...mapGetters({
      publishDate: "products/publishDate",
    }),
  },
  watch: {
    publishDate(value) {
      if (value) {
        this.launchDate = moment(value?.date).format("YYYY-MM-DDTHH:mm:ss");
        this.timezone = this.timezonesList.find(
          (item) => item.id === value?.timezone
        );
      }
    },
  },
  methods: {
    ...mapActions({
      openModal: "modals/openModal",
      closeModal: "modals/closeModal",
      publishProduct: "products/publishProduct",
      editLaunchDate: "products/editLaunchDate",
    }),
    goBack() {
      this.openModal({ modal: "publishProductModal" });
    },
    scheduleLaunch() {
      const id = this.productDetails?.productId;
      const dateToSpecificTImeZone = momentTimezone(this.launchDate).tz(this.timezone?.id).format("YYYY-MM-DDTHH:mm:ss");
      const payload = {
        productId: id,
        publishDate: dateToSpecificTImeZone,
        timezone: this.timezone.id,
        releaseNotes: this.releaseNotes,
      };
      if (this.publishDate) {
        this.editLaunchDate({ id, payload }).then(() =>
          this.$router.push("/products")
        );
      } else {
        this.publishProduct({ id, payload, flag: true }).then(() =>
          this.$router.push("/products")
        );
      }
      this.closeModal();
    },
    validHours(hour, dateParts) {
      const timeZoneId = this.timezone ? this.timezone.id : 'UTC';
      const date = new Date().toLocaleString("en-US", { timeZone: timeZoneId });
      const day = new Date(date).getDate();
      const localHour = new Date(date).getHours();
      if (day === dateParts.day) {
        return hour > localHour;
      }
      return true;
    },
  },
  async mounted() {
    const res = await getTimezones();
    const timezones = res.data.data.map((item) => ({
      id: item.id,
      label: item.displayName,
    }));
    this.timezonesList = timezones;
    this.timezone = timezones.find((item) => item.id === "UTC");
  },
};
</script>
<style lang="scss" scoped>
.inputIcon {
  top: 10px;
}
</style>
