var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.label)?_c('p',{staticClass:"font-medium text-sm-2 text-grey-light mb-0.5"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"cl-richtext editor",class:[
      _vm.haveMinHeight && _vm.editable && 'have-min-height',
      _vm.editable &&
        'border border-grey-light-1 rounded bg-white max-w-onboarding',
      _vm.error && 'border-red' ]},[(_vm.editor && _vm.editable)?_c('div',{staticClass:"p-1.5 bg-grey-light-3 border border-grey-light-1 border-t-0 border-r-0 border-l-0 items-center flex",class:_vm.error && 'border-red'},[(_vm.headerMode === 'standard')?_c('div',[_c('button',{class:{ 'is-active': _vm.editor.isActive('bold') },on:{"click":function($event){_vm.editor
              .chain()
              .focus()
              .toggleBold()
              .run()}}},[_c('img',{attrs:{"src":_vm.editor.isActive('bold') ? _vm.boldBlue : _vm.bold}})]),_c('button',{class:{ 'is-active': _vm.editor.isActive('italic') },on:{"click":function($event){_vm.editor
              .chain()
              .focus()
              .toggleItalic()
              .run()}}},[_c('img',{attrs:{"src":_vm.editor.isActive('italic') ? _vm.italicBlue : _vm.italic}})]),_c('button',{staticClass:"mr-6",class:{ 'is-active': _vm.editor.isActive('underline') },on:{"click":function($event){_vm.editor
              .chain()
              .focus()
              .toggleUnderline()
              .run()}}},[_c('img',{attrs:{"src":_vm.editor.isActive('underline') ? _vm.underlineBlue : _vm.underline}})]),_c('button',{class:{ 'is-active': _vm.editor.isActive({ textAlign: 'left' }) },on:{"click":function($event){_vm.editor
              .chain()
              .focus()
              .setTextAlign('left')
              .run()}}},[_c('img',{attrs:{"src":_vm.editor.isActive({ textAlign: 'left' }) ? _vm.leftBlue : _vm.left}})]),_c('button',{class:{ 'is-active': _vm.editor.isActive({ textAlign: 'center' }) },on:{"click":function($event){_vm.editor
              .chain()
              .focus()
              .setTextAlign('center')
              .run()}}},[_c('img',{attrs:{"src":_vm.editor.isActive({ textAlign: 'center' }) ? _vm.centerBlue : _vm.center}})]),_c('button',{class:{ 'is-active': _vm.editor.isActive({ textAlign: 'right' }) },on:{"click":function($event){_vm.editor
              .chain()
              .focus()
              .setTextAlign('right')
              .run()}}},[_c('img',{attrs:{"src":_vm.editor.isActive({ textAlign: 'right' }) ? _vm.rightBlue : _vm.right}})]),_c('button',{staticClass:"mr-6",class:{ 'is-active': _vm.editor.isActive({ textAlign: 'justify' }) },on:{"click":function($event){_vm.editor
              .chain()
              .focus()
              .setTextAlign('justify')
              .run()}}},[_c('img',{attrs:{"src":_vm.editor.isActive({ textAlign: 'justify' })
                ? _vm.justifyBlue
                : _vm.justify}})]),_c('button',{staticClass:"mr-6",class:{ 'is-active': _vm.editor.isActive('link') },on:{"click":_vm.toggleLink}},[_c('img',{attrs:{"src":_vm.editor.isActive('link') ? _vm.linkBlue : _vm.link}})]),_c('button',{class:{ 'is-active': _vm.editor.isActive('bulletList') },on:{"click":function($event){_vm.editor
              .chain()
              .focus()
              .toggleBulletList()
              .run()}}},[_c('img',{attrs:{"src":_vm.editor.isActive('bulletList') ? _vm.bulletedBlue : _vm.bulleted}})]),_c('button',{class:{ 'is-active': _vm.editor.isActive('orderedList') },on:{"click":function($event){_vm.editor
              .chain()
              .focus()
              .toggleOrderedList()
              .run()}}},[_c('img',{attrs:{"src":_vm.editor.isActive('orderedList') ? _vm.numberedBlue : _vm.numbered}})]),_c('button',{on:{"click":function($event){_vm.editor
              .chain()
              .focus()
              .undo()
              .run()}}},[_c('img',{attrs:{"src":_vm.undo}})]),_c('button',{on:{"click":function($event){_vm.editor
              .chain()
              .focus()
              .redo()
              .run()}}},[_c('img',{attrs:{"src":_vm.redo}})])]):_vm._e(),(_vm.headerMode === 'link')?_c('div',{staticClass:"flex flex-grow items-center"},[_c('InputField',{staticClass:"w-full",attrs:{"placeholder":"Enter link here","onChange":_vm.setURL}}),_c('Button',{staticClass:"mx-3",attrs:{"type":"tertiary","size":"medium","text":"Cancel"},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}}),_c('Button',{attrs:{"type":"primary","size":"medium","text":"Save"},nativeOn:{"click":function($event){return _vm.saveLink.apply(null, arguments)}}})],1):_vm._e()]):_vm._e(),_c('editor-content',{staticClass:"editor__content",class:_vm.editable ? 'p-2' : _vm.nonEditableTextStyle,attrs:{"editor":_vm.editor}}),(_vm.error)?_c('p',{staticClass:"text-sm mt-0.4 absolute text-red"},[_vm._v(_vm._s(_vm.error))]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }