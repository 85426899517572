import moment from "moment";

export const canViewResults = (
  resultsPermission,
  surveyPermission,
  closingAt
) => {
  const now = moment();
  const closingDate = moment(closingAt);
  if (surveyPermission === 1) {
    return false;
  } else {
    if (resultsPermission !== 1) {
      if (surveyPermission === 2) {
        return true;
      }
      if (surveyPermission === 3) {
        return now > closingDate;
      }
    } else if (resultsPermission === 1) {
      return false;
    }
  }
};
