<template>
  <div class="flex flex-row items-center mb-2 space-x-2">
    <p v-if="!hideTitle" class="font-headers font-bold text-mdl text-grey-light">
      {{ onAdmin ? "Subscription" : "Subscription Management" }}
    </p>
    <div
      class="border border-green rounded px-1 py-0.5"
      v-if="subStatus === 1"
    >
      <p class="text-green text-sm-2">
        Active
      </p>
    </div>
    <div
      class="border border-grey-medium-2 rounded px-1 py-0.5"
      v-if="subStatus === 3"
    >
      <p class="text-grey-medium-2 text-sm-2">
        Suspended
      </p>
    </div>
    <div
      class="border border-red rounded px-1 py-0.5"
      v-if="subStatus === 2"
    >
      <p class="text-red text-sm-2">
        Deactivated
      </p>
    </div>
    <div
      class="border border-red rounded px-1 py-0.5"
      v-if="subStatus === 4"
    >
      <p class="text-red text-sm-2">
        Expired
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    subStatus: {
      type: Number,
      default: 1,
    },
    onAdmin: {
      type: Boolean,
      default: true,
    },
    hideTitle: {
      type: Boolean,
      default: false
    }
  },
};
</script>
