<template>
  <div class="vld-parent">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      loader="dots"
      color="#00A1D5"
    ></loading>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Loader",
  data() {
    return {
      fullPage: true,
    };
  },
  components: {
    Loading,
  },
  computed: {
    ...mapGetters({
      isLoading: "calls/isLoading",
    }),
  },
};
</script>
