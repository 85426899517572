const getDefaultState = () => ({
  step: 0,
});

const actions = {
  resetCallsState({ commit }) {
    commit("resetState");
  },
  setStep({ commit }, { step }) {
    commit("SET_STEP", step);
  },
};

const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  SET_STEP(state, step) {
    state.step = step;
  },
};

const getters = {
  getStep: (state) => state.step,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
