<template>
  <div class="inputContainer">
    <p
      class="text-sm-2 text-grey-light font-medium mb-0.4 relative"
      :class="[
        isTextOk !== null && 'text-red',
      ]"
    >
      {{ label }}
    </p>
    <div class="flex items-center flex-grow relative">
      <img
        v-if="leftIcon"
        src="../../assets/img/icons/search.svg"
        class="absolute left-1.5"
      />
      <input
        v-model="value"
        :type="fieldType"
        :placeholder="placeholder"
        :maxlength="maxlength"
        class="w-full outline-none placeholder-grey-medium-2 font-medium text-md text-grey-dark-1 px-2 py-1 border rounded focus:border-blue-dark-1 disabled:text-grey-light disabled:placeholder-grey-light-1 disabled:border-grey-light-1 disabled:bg-white"
        :class="[
          colorClass,
          leftIcon ? 'pl-5' : null,
          !disabled ? 'inputField' : null,
        ]"
        :disabled="disabled"
        :readonly="readonly"
        @input="onChange(value)"
        v-on:keyup.enter="submit"
        v-bind="$attrs"
      />
      <button
        v-if="type === 'password'"
        class="absolute right-2 focus:outline-none"
        @click="onClickIcon"
      >
        <img :src="visible" />
      </button>
    </div>
    <p
      v-if="isTextOk !== null"
      :class="!isTextOk ? 'text-red' : 'text-green'"
      class="text-sm mt-0.4 absolute"
    >
      {{ errorText }}
    </p>
  </div>
</template>
<script>
import showText from "@/assets/img/icons/visible.svg";
import hideText from "@/assets/img/icons/not-visible.svg";

export default {
  name: "InputField",
  data() {
    return { value: "", toggle: false, fieldType: this.type };
  },
  props: {
    label: { type: String, default: null },
    defaultValue: { type: String, default: null },
    placeholder: { type: String, default: null },
    maxlength: { type: Number, default: null },
    propValue: { type: String, default: null },
    type: { type: String, default: "text" },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    onChange: { type: Function, default: null },
    isTextOk: { type: Boolean, default: null },
    errorText: { type: String, default: null },
    submit: { type: Function, default: () => {} },
    leftIcon: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.value = this.defaultValue || "";
  },
  watch: {
    propValue(val) {
      this.value = val;
    },
  },
  computed: {
    visible() {
      return this.toggle ? showText : hideText;
    },
    colorClass() {
      switch (this.isTextOk) {
        case true:
          return "border-green focus:border-green";
        case false:
          return "border-red focus:border-red";
        default:
          return "border-grey-light-1";
      }
    },
  },
  methods: {
    onClickIcon() {
      this.toggle = !this.toggle;
      this.fieldType = this.toggle ? "text" : "password";
    },
  },
};
</script>
<style lang="scss" scoped>
.inputContainer {
  &:hover {
    .textLabel {
      color: #00a1d5;
    }
    .inputField {
      border-color: #00a1d5;
    }
  }
}
</style>
