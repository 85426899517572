<template>
  <div class="rounded flex flex-col w-full bg-white shadow">
    <p class="font-barlow font-bold text-mdl text-grey-light mb-1.5 mt-3 ml-2">
      {{ title }}
    </p>
    <div
      v-for="survey in data.closedAssessments"
      :key="data.id + survey.surveyId"
    >
      <p class="font-medium text-md-2 text-grey-dark-1 ml-4">
        {{ survey.parentSurveyName }}
      </p>
      <div class="flex flex-row ml-4">
        <img src="../../assets/img/delta-table/clipboard.svg" class="mr-0.4" />
        <p class="mr-0.4 font-bold text-sm-2 text-grey-light">Source:</p>
        <p class="mr-0.4 text-sm-2 text-grey-light">Latest {{ survey.name }}</p>
        <p class="mr-0.4 text-sm-2 text-grey-light">- Finished on</p>
        <p class="text-sm-2 text-grey-dark-1">
          {{ formatDate(survey.closingTime) }}
        </p>
      </div>
      <div class="mx-4 my-3">
        <div class="flex flex-row w-full bg-grey-light-3 py-1 pl-2 rounded">
          <div class="flex flex-row items-center w-1/2">
            <p class="text-sm-2 text-grey-light mr-0.4">CORE COMPETENCY</p>
          </div>
          <div class="flex flex-row items-center w-1/6 justify-center">
            <p class="text-sm-2 text-grey-light mr-0.4">ACTUAL</p>
          </div>
          <div class="flex flex-row items-center w-1/6 justify-center">
            <p class="text-sm-2 text-grey-light mr-0.4">TARGET</p>
          </div>
          <div class="flex flex-row items-center w-1/6 justify-center">
            <p class="text-sm-2 text-grey-light mr-0.4">DELTA</p>
          </div>
        </div>
        <div
          v-for="item in separateLists(survey.results)"
          :key="item.competency"
        >
          <TableItemPreview :item="item" type="competency" />
          <div class="border border-grey-light-1" />
        </div>
      </div>
      <div class="flex justify-end mb-2 mr-4 mt-auto">
        <router-link :to="`/team-results/${survey.surveyId}/${data.id}`">
          <Button
            text="View Details"
            type="secondary"
            :iconRight="arrow"
            size="medium"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import Button from "../Button/Button.vue";
import TableItemPreview from "./TableItemPreview";
import moment from "moment";
import arrow from "@/assets/img/icons/arrow-right.svg";

export default {
  name: "DeltaPreview",
  components: { TableItemPreview, Button },
  props: {
    type: { type: String, default: null },
    data: { type: Object, default: null },
  },
  data: () => ({ arrow }),
  computed: {
    title() {
      return this.type === "gaps" ? "Team Skill Gaps" : "Team Strengths";
    },
  },
  methods: {
    separateLists(results) {
      const negativeValues = results
        .filter((el) => Math.sign(el.delta) === -1)
        .sort((a, b) => a.delta - b.delta);
      const positiveValues = results
        .filter((el) => Math.sign(el.delta) !== -1)
        .sort((a, b) => b.delta - a.delta);

      if (this.type === "gaps")
        return negativeValues
          .map((item, index) => ({
            ...item,
            color: this.coloring((index / negativeValues.length) * 100),
          }))
          .slice(0, 5);

      return positiveValues.slice(0, 5).map((item, index) => ({
        ...item,
        color: this.coloring(50 + (5 - index) * 10),
      }));
    },
    percentage(index) {
      return this.type === "gaps"
        ? (index / this.orderedTable.length) * 100
        : ((this.orderedTable.length - index) / this.orderedTable.length) * 100;
    },
    formatDate(date) {
      return moment(date).format("ddd, D MMM YYYY");
    },
    coloring(percentage) {
      var r,
        g,
        b = 0;
      if (percentage < 50) {
        r = 255;
        g = Math.round(5.1 * percentage);
      } else {
        g = 255;
        r = Math.round(510 - 5.1 * percentage);
      }
      var h = r * 0x10000 + g * 0x100 + b * 0x1;
      return "#" + ("000000" + h.toString(16)).slice(-6);
    },
  },
};
</script>
