<template>
  <Modal :data="$data" :isVisible="true">
    <div class="flex flex-col space-y-1 font-main text-md text-blue-dark-4 mb-4">
      <p>
        Deleting your personal data means your personal information will be removed from the platform. You will no
        longer be able to take future assessments under this account or have access to any information on the platform.
        It also means you will lose access to your account and assessment results. Your personal data will be forgotten
        and any remaining data will be anonymized.
      </p>
      <p>
        A request will be sent to your company administrator, the data controller, for review and identity verification.
        You will be notified if your request is declined. This action cannot be undone.
      </p>
      <p>
        Are you sure you want to request the deletion of your personal data in the platform?
      </p>
    </div>
    <div class="flex flex-row ">
      <div class="flex flex-row flex-grow justify-end">
        <Button type="tertiary" size="medium" text="Cancel" @click.native="closeModal" class="mr-2" />
        <Button type="danger" text="Request to Delete My Personal Data" size="medium" @click.native="requestDelete" />
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'DataDeletionModal',
  props: ['visible', 'deleteFn'],
  data: () => ({
    title: 'Delete My Personal Data Request',
  }),
  methods: {
    ...mapActions({
      closeModal: 'modals/closeModal',
    }),
    async requestDelete() {
      await this.deleteFn();
      this.closeModal();
    },
  },
};
</script>
