<template>
  <div>
    <h1 class="text-xl text-grey-dark-3 mb-2 font-bold font-headers">
      Dashboard
    </h1>
    <GdprRequests
      :deleteFn="deleteData"
      :declineFn="declineFn"
      :requests="deletionRequests"
      v-if="deletionRequests.length > 0"
    />
    <SubscriptionInfoCard
      :details="subscriptionInfo"
      :renewalDetails="subscriptionRenewalInfo"
      class="mb-3"
      :onAdmin="true"
    />
    <InvitesInfoCard
      :invitesInfo="invitesInfo"
      :allowUnlimitedInvites="allowUnlimitedInvites"
      :isAdmin="true"
    />
    <DraftAssessments />
    <ActiveAssessments type="company" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ActiveAssessments from "./ActiveAssessments";
import DraftAssessments from "./DraftAssessments";
import GdprRequests from "./GdprRequests";
import InvitesInfoCard from "../../Companies/components/InvitesInfoCard.vue";
import SubscriptionInfoCard from "../../Companies/components/SubscriptionInfoCard/index.vue";
import {
  getUsersWithRequest,
  declineRequest,
  approveRequest,
} from "../../../services/api/gdpr";

export default {
  name: "AdminDashboard",
  components: {
    ActiveAssessments,
    DraftAssessments,
    InvitesInfoCard,
    SubscriptionInfoCard,
    GdprRequests,
  },
  data: () => ({
    deletionRequests: [],
  }),
  computed: {
    ...mapState({
      assessments: (state) => state.assessments.assessments,
      invitesInfo: (state) => state.invites.invitesInfo,
      allowUnlimitedInvites: (state) => state.invites.allowUnlimitedInvites,
      currentCompany: (state) => state.companies.currentCompany,
      subscriptionInfo: (state) => state.subscriptions.subscriptionDetails,
      subscriptionRenewalInfo: state => state.subscriptions.renewalDetails,
      companyId: (state) => state.auth?.decodedData["organization-id"],
      impersonatedCompanyId: (state) =>
        state.auth.impersonateDecodedData["organization-id"],
    }),
    compId() {
      return this.companyId ? this.companyId : this.impersonatedCompanyId;
    },
  },
  methods: {
    ...mapActions({
      openModal: "modals/openModal",
      getOrgInvitesInfo: "invites/getOrgInvitesInfo",
      getDrafts: "assessmentSetup/getDrafts",
      getAdminActiveAsessments: "home/getAdminActiveAsessments",
      getCompanyAssessments: "home/getCompanyAssessments",
      getSubscriptionDetails: "subscriptions/getSubscriptionDetails",
      getSubscriptionRenewalDetails: "subscriptions/getSubscriptionRenewalDetails",
      showSuccess: "alerts/showSuccess",
    }),
    async deleteFn(userId, organizationId) {
      const res = await approveRequest(userId, organizationId);
      await this.getDeletionRequests();
      if (res.status === 200) {
        this.showSuccess({
          messageHeader: "User's Personal Data Deleted!",
          message:
            "This user's personal data has been permanently deleted. Their results are now anonymized in the platform.",
        });
      }
    },
    async declineFn(userId) {
      await declineRequest(userId);
      await this.getDeletionRequests();
    },
    deleteData(userId, organizationId) {
      this.openModal({
        modal: "confirmDataDeleteModal",
        props: {
          deleteFn: this.deleteFn,
          userId,
          organizationId,
        },
      });
    },
    async getDeletionRequests() {
      const requests = await getUsersWithRequest(parseInt(this.compId, 10));
      this.deletionRequests = requests.data.data;
    },
  },
  async mounted() {
    await this.getSubscriptionDetails(this.compId);
    await this.getOrgInvitesInfo(this.compId);
    await this.getSubscriptionRenewalDetails(this.compId);
    await this.getCompanyAssessments();
    await this.getDeletionRequests();
    this.getDrafts();
    this.getAdminActiveAsessments();
  },
};
</script>

<style></style>
