import { apiCall, apiCallBlob } from "./api";

export const fetchSurveyStats = (surveyId, teamId, jobRoleId) => {
  let params = `?TeamId=${teamId}`;
  if (jobRoleId) params += `&JobRoleId=${jobRoleId}`;
  return apiCall("GET", `api/v1/Survey/${surveyId}/overall_progress${params}`);
};

export const fetchAssessmentResults = (
  surveyId,
  teamId,
  group,
  jobId,
  userId
) => {
  const comp = group === "competency";
  let params = `?SurveyId=${surveyId}&TeamId=${teamId}&GroupUnderParent=${comp}`;
  if (jobId) params += `&JobRoleId=${jobId}`;
  if (userId) params += `&UserId=${userId}`;
  return apiCall("GET", `api/v1/Competency/results_by_filters${params}`);
};

export const fetchTeamProgress = (surveyId, teamId) =>
  apiCall("GET", `api/v1/Survey/${surveyId}/team_progress?TeamId=${teamId}`);

export const fetchTeamAssessments = () =>
  apiCall("GET", "api/v1/Survey/completed-surveys-for-manager");

export const fetchTeamsForAssessment = (surveyId) =>
  apiCall("GET", `api/v1/Team/manager-teams-for-survey/${surveyId}`);

export const fetchJobRoles = (surveyId, teamId) =>
  apiCall(
    "GET",
    `api/v1/survey/${surveyId}/job-roles-for-manager-team-members/${teamId}`
  );

export const downloadTeamSurvey = (
  id,
  teamId,
  jobId,
  userId,
  competencyImg,
  subcompetencyImg,
  competencySorting,
  subcompetencySorting,
) => {
  let competencySortingValue = {};
  competencySortingValue[competencySorting.column || "NONE"] = competencySorting.direction 

  let subcompetencySortingValue = {};
  subcompetencySortingValue[subcompetencySorting.column || "NONE"] = subcompetencySorting.direction 

  const payload = {
    teamId,
    teamCoreCompetenciesAndTargetsImage: `data:image/svg+xml;base64, ${competencyImg}`,
    teamSubCompetenciesAndTargetsImage: `data:image/svg+xml;base64, ${subcompetencyImg}`,
    competencySorting: competencySortingValue,
    subCompetencySorting: subcompetencySortingValue,
  };
  if (jobId !== 0) payload.jobRoleId = jobId;
  if (userId !== 0) payload.userId = userId;
  return apiCallBlob(
    "POST",
    `api/v1/survey/${id}/download_team_results`,
    payload
  );
};

export const exportSurveyData = (surveyId, teamId, jobId, userId) => {
  const payload = {
    teamId,
  };
  if (jobId !== 0) payload.jobRoleId = jobId;
  if (userId !== 0) payload.userId = userId;
  return apiCallBlob(
    "POST",
    `api/v1/File/${surveyId}/export-survey-data`,
    payload
  );
}