import store from "@/store";
import jwt_decode from "jwt-decode";

export const tokenHandler = () => {
  const token = store.getters["auth/isImpersonating"]
    ? store.getters["auth/getImpersonateToken"]
    : store.getters["auth/getToken"];

  var date = new Date();
  var timestamp = Math.floor(date.getTime() / 1000.0);
  const decodedToken = jwt_decode(token);
  if (decodedToken.exp < timestamp) {
    if (store.getters["auth/isImpersonating"]) {
      store.dispatch("auth/cleanImpersonation", { otherPath: "/" });
      return store.getters["auth/getToken"];
    } else {
      store.dispatch("auth/logout", { doRedirect: true });
      return null;
    }
  } else {
    return token;
  }
};
