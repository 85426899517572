const getDefaultState = () => ({});

const actions = {
  downloadTemplate() {
    const downloadLink = document.createElement("a");
    const blob = new Blob(["First Name,Last Name,Job Title,Email"], {
      type: "text/csv",
    });
    const url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = "employees_template.csv";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  },
};

const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
