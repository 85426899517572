<template>
  <div>
    <div
      class="w-full h-full fixed z-40 bottom-0 flex justify-center items-center modal"
    >
      <div class="w-modal bg-white p-4 shadow flex-grow-0 rounded">
        <p class="font-headers font-bold text-grey-dark-2 text-l mb-1.5">
          Deactivate Account
        </p>
        <div>
          <p class="text-mdh text-grey-dark-2 mb-4">
            Deactivating an account would mean that this user can no longer log
            into the platform from now on.
          </p>
          <p class="text-mdh text-grey-dark-2">
            Are you sure you want to deactivate their account?
          </p>
        </div>
        <div class="flex flex-row flex-grow justify-end mt-4 space-x-2">
          <Button
            type="tertiary"
            size="medium"
            text="Close"
            @click.native="closeModal"
            data-testid="close-button"
          />
          <button
            class="bg-red px-2 py-1 font-semibold text-md text-white rounded"
            @click="disable"
            data-testid="disable-seller-button"
          >
            Deactivate Account
          </button>
        </div>
      </div>
    </div>
    <div
      class="w-full h-full fixed z-30 bottom-0 bg-grey-dark-1 opacity-70 modal transition-all"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "DeactivateAccountModal",
  props: {
    userId: { type: Number },
  },
  methods: {
    ...mapActions({
      closeModal: "modals/closeModal",
      changeUserStatus: "companies/changeUserStatus",
    }),
    async disable() {
      await this.changeUserStatus({ userId: this.userId, isActive: false });
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  top: 0;
}
</style>
