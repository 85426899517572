import { render, staticRenderFns } from "./DeactivateJobRoleModal.vue?vue&type=template&id=69236fe8&scoped=true&"
import script from "./DeactivateJobRoleModal.vue?vue&type=script&lang=js&"
export * from "./DeactivateJobRoleModal.vue?vue&type=script&lang=js&"
import style0 from "./DeactivateJobRoleModal.vue?vue&type=style&index=0&id=69236fe8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69236fe8",
  null
  
)

export default component.exports