<template>
  <div>
    <div class="w-full h-full fixed z-40 bottom-0 flex justify-center items-center modal">
      <div class="w-modal bg-white p-4 shadow flex-grow-0 rounded">
        <p class="font-headers font-bold text-grey-dark-2 text-l mb-1.5">
          Edit {{ displayJobRoleOrCompetency }}
        </p>
        <div>
          <p class="text-mdh text-grey-dark-2 mb-4">
            This {{ displayJobRoleOrCompetency }} belongs to product <strong>{{ displayProductName }} {{
              isThisVersionDraft ? " [Draft]" : "" }} </strong> <br />
            {{ displayTextForLatestPublishedVersion }} <br />
            {{ displayTextForExistingDraft }}
          </p>
          <p class="text-mdh text-grey-dark-2">
            Please choose the next action you'd like to take:
          </p>
        </div>
        <div class="flex flex-row flex-grow justify-end mt-4 space-x-2">
          <Button type="tertiary" size="medium" text="Close" @click.native="closeModal" data-testid="close-button" />
          <button v-if="displayGoToVersionItBelongs"
            class="bg-blue-dark-2 px-2 py-1 font-semibold text-md text-white rounded" @click="goToVersionItBelongsTo"
            data-testid="disable-go-to-version-it-belongs">
            Go to the version it's used in
          </button>
          <button v-if="displayGoToDraft" class="bg-blue-dark-2 px-2 py-1 font-semibold text-md text-white rounded"
            @click="goToDraft" data-testid="disable-go-to-draft">
            Go to draft
          </button>
          <button v-if="displayCreateNewVersion" class="bg-blue-dark-2 px-2 py-1 font-semibold text-md text-white rounded"
            @click="createNewVersion" data-testid="disable-go-to-draft">
            Create new product version
          </button>
        </div>
      </div>
    </div>
    <div class="w-full h-full fixed z-30 bottom-0 bg-grey-dark-1 opacity-70 modal transition-all" />
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "EditJobRoleOrCompetencyModal",
  props: {
    isJobRole: { type: Boolean, required: true },
    productId: { type: Number, required: true },
    productName: { type: String, required: true },
    displayGoToDraft: { type: Boolean, required: true },
    displayGoToVersionItBelongs: { type: Boolean, required: true },
    displayCreateNewVersion: { type: Boolean, required: true },
    latestVersion: { type: String, required: true },
    latestPublishedVersion: { type: String, required: true },
    currentVersion: { type: String, required: true },
    draftVersion: { type: String },
  },
  computed: {
    isThisVersionDraft() {
      if (this.currentVersion === this.draftVersion && this.draftVersion === this.latestVersion)
        return true;
      return false;
    },
    isLatestPublishedVersion() {
      return this.currentVersion === this.latestPublishedVersion;
    },
    displayTextForLatestPublishedVersion() {
      if (this.isThisVersionDraft)
        return "";

      if (this.isLatestPublishedVersion)
        return "This is the latest published version for this product.";
      else
        return "The latest published version for this product is (" + this.latestPublishedVersion + ")";
    },
    displayTextForExistingDraft() {
      if (this.isThisVersionDraft || this.draftVersion === null)
        return "";
      return "This product already has a draft at version (" + this.draftVersion + ")";
    },
    displayJobRoleOrCompetency() {
      if (this.isJobRole)
        return "Job Role";
      else
        return "Competency";
    },
    displayProductName() {
      return this.productName + " (" + this.currentVersion + ")";
    }
  },
  methods: {
    ...mapActions({
      openModal: "modals/openModal",
      closeModal: "modals/closeModal",
      resetProductDetails: "products/resetProductDetails",
      getProductById: "companies/getProductById"
    }),
    async goToDraft() {
      if (this.isThisVersionDraft) {
        this.resetProductDetails();
        this.$router.push(`/products/${this.productId}/dashboard`);
        this.closeModal();
      }
      else if (this.draftVersion && this.draftVersion !== "") {
        // Fetch draft product ID
        var productDraft = await this.getProductById({ id: this.productId, version: this.draftVersion });
        if (productDraft?.id) {
          this.resetProductDetails();
          this.$router.push(`/products/${productDraft.id}/dashboard`);
          this.closeModal();
        }
      }
    },
    async goToVersionItBelongsTo() {
      // Fetch product ID
      var currentVersionProduct = await this.getProductById({ id: this.productId, version: this.currentVersion });
      if (currentVersionProduct?.id) {
        this.resetProductDetails();
        this.$router.push(`/products/${currentVersionProduct.id}/dashboard`);
        this.closeModal();
      }
    },
    async createNewVersion() {
      if (this.currentVersion === this.latestPublishedVersion) {
        this.openModal({
          modal: "newVersionSettingsModal",
          props: { id: this.productId, productDraftName: this.productName },
        });
      }
      else {
        var latestPublishedProduct = await this.getProductById({ id: this.productId, version: this.latestPublishedVersion });
        if (latestPublishedProduct?.id) {
          this.openModal({
            modal: "newVersionSettingsModal",
            props: { id: latestPublishedProduct.id, productDraftName: latestPublishedProduct.name },
          });
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.modal {
  top: 0;
}
</style>
