<template>
  <div>
    <div>
      <h1 class="font-bold text-l text-grey-dark-3">
        Hello, {{ getFullName }}
      </h1>
      <div class="flex flex-row pt-2">
        <img src="../../../assets/img/icons/job.svg" width="30" class="pr-1" />
        <p class="text-grey-dark-3">
          {{ position }}
        </p>
      </div>
    </div>
    <AssessmentsSlider :assessments="assessmentsList" />
    <div v-if="results.length > 0" class="mt-2 mb-4 w-full">
      <ResultsList :data="results" />
    </div>
    <div
      v-else
      class="flex w-full mb-3 justify-between rounded bg-white shadow py-0 mt-5 p-3"
    >
      <div class="max-w-1/5">
        <p class="font-barlow text-mdl font-bold text-grey-light mb-2">
          Your Activity
        </p>
        <p class="text-md text-grey-dark-1 ml-3 pb-1">
          No assessments completed.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import ResultsList from "@/components/results-list/ResultsList.vue";
import AssessmentsSlider from "./AssessmentsSlider.vue";

export default {
  name: "Employee",
  components: {
    ResultsList,
    AssessmentsSlider,
},
  computed: {
    ...mapGetters({
      getFullName: "home/getFullName",
    }),
    ...mapState({
      assessments: (state) => state.assessments.assessments,
      results: (state) => state.assessments.results,
      position: (state) => state.home.position,
    }),
    assessmentsList() {
      return this.filterAssessmentCards();
    },
  },
  methods: {
    ...mapActions({
      getSurveys: "assessments/getSurveys",
      getResults: "assessments/getDashboardResults",
    }),
    sortingByPriority(a, b){
      if (
        a.inProgress && !a.isClosed && b.inProgress && !b.isClosed || 
        !a.inProgress && !a.isClosed && !b.inProgress && !b.isClosed ||
        !a.inProgress && a.isClosed && !b.inProgress && b.isClosed
      ) return 0;

      if (
        a.inProgress && !a.isClosed && !b.inProgress && !b.isClosed || 
        a.inProgress && !a.isClosed && !b.inProgress && b.isClosed ||
        !a.inProgress && !a.isClosed && !b.inProgress && b.isClosed
      ) return -1;

      if (
        !a.inProgress && !a.isClosed && b.inProgress && !b.isClosed || 
        !a.inProgress && a.isClosed && b.inProgress && !b.isClosed ||
        !a.inProgress && a.isClosed && !b.inProgress && !b.isClosed
      ) return 1;
    },
    filterAssessmentCards() {
      return this.assessments
        .sort((a, b) => this.sortingByPriority(a, b) || +new Date(a.dueOn) - +new Date(b.dueOn) || a.title.localeCompare(b.title));
    },
  },
  async mounted() {
    await this.getSurveys();
    await this.getResults();
  },
};
</script>
