import { render, staticRenderFns } from "./AssessmentCard.vue?vue&type=template&id=ab419d1e&scoped=true&"
import script from "./AssessmentCard.vue?vue&type=script&lang=js&"
export * from "./AssessmentCard.vue?vue&type=script&lang=js&"
import style0 from "./AssessmentCard.vue?vue&type=style&index=0&id=ab419d1e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab419d1e",
  null
  
)

export default component.exports