<template>
    <div class="py-3 px-2 bg-white rounded mb-3">
      <div class="flex-grow flex justify-between items-center pb-2">
        <p class="font-barlow font-bold text-mdl text-grey-light">
          Personal Data Deletion Requests
        </p>
      </div>
      <div class="w-full h-line bg-grey-light-2" />
      <div class="flex-col flex">
        <div class="flex-row justify-between flex py-2 px-4 items-center" v-for="request in requests" :key="request.id">
          <div class="flex-col flex">
            <h2 class="text-grey-dark-1 text-md-2 font-medium pb-1">
              {{ `${request.firstName} ${request.lastName}` }}
            </h2>
            <p class="text-grey-light text-sm-2">
              {{ roles(request.roles) }}
            </p>
          </div>
          <div>
            <p class="text-grey-light text-sm-2">
              Requested on {{ formatDate(request.dateOfAccountChangeRequest) }}
            </p>
          </div>
          <div class="flex flex-row space-x-2">
            <Button
              text="Decline"
              type="secondary"
              size="medium"
              data-testid="data-download-button"
              :iconLeft="CloseBlue"
              @click.native="declineFn(request.id)"
            />
            <Button
              text="Approve"
              type="primary"
              size="medium"
              data-testid="data-download-button"
              :iconLeft="CheckWhite"
              @click.native="deleteFn(request.id, request.organizationId)"
              
            />
          </div>
        </div>
        <div class="w-full h-line bg-grey-light-2" />
      </div>
    </div>
</template>
<script>
import Button from '@/components/Button/Button.vue';
import _forEach from 'lodash/forEach';
import moment from 'moment';
import CheckWhite from "../../../assets/img/icons/check-white.svg";
import CloseBlue from "../../../assets/img/icons/close-blue.svg";
export default {
  name: 'GdprRequests',
  props: {
    deleteFn: {
      type: Function,
      default: () => (null)
    },
    declineFn: {
      type: Function,
      default: () => (null)
    },
    requests: {
      type: Array,
      default: () => ([])
    }
  },
  components: {
    Button
  },
  data: () => ({
    CheckWhite,
    CloseBlue
  }),
  methods: {
    roles(userRoles) {
      let roles = '';
      _forEach(userRoles, (role, index) => {
        switch (role.name) {
          case 'SellerAdmin':
            roles += 'ISM Admin';
            break;
          case 'CompanyAdmin':
            roles += 'Admin';
            break;
          default:
            roles += `${role.name}`;
            break;
        }
        if (index !== userRoles.length - 1) roles += ', ';
      });
      return roles;
    },
    formatDate(date) {
      return moment(date).format("ddd, D MMM YYYY");
    },
  }
}
</script>