<template>
  <div
    :id="data.index % 15 === 0 && 'infinite-scroll-anchor'"
    class="list-item-container rounded border border-blue-lightest mb-1 flex flex-row w-full h-list-item"
  >
    <div class="completed-container bg-blue-lightest">
      <p class="text-sm text-grey-light text-center font-normal">
        Completed on
      </p>
      <p class="font-bold text-md text-grey-dark-2 text-center">
        {{ data.completedOn }}
      </p>
    </div>
    <div class="p-2 flex flex-grow justify-between">
      <div>
        <p class="text-grey-dark-3 font-medium text-md">{{ data.title }}</p>
        <p class="text-sm text-blue-dark-4">
          Launched on {{ data.launchedOn }}
        </p>
      </div>
      <Button
        v-if="data.canViewResults"
        @click.native="goToResult"
        text="View results"
        size="medium"
        :iconRight="arrow"
        type="secondary"
      />
      <div v-else>
        <p class="text-right text-md font-semibold text-grey-light">
          No Access to Results
        </p>
        <p class="text-right text-sm text-grey-light">
          Enabled by Platform Administrator
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Button from "../Button/Button.vue";
import arrow from "@/assets/img/icons/arrow-right.svg";

export default {
  name: "ListItem",
  components: { Button },
  props: { data: { default: null, type: Object } },
  data: () => ({ arrow }),
  methods: {
    ...mapActions({
      getAssessment: "results/getAssessment",
      getSurveys: "results/getSurveys",
    }),
    goToResult() {
      this.$router.push({
        name: "AssessmentResult",
        params: { id: this.data.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.completed-container {
  width: 122px;
  height: auto;
  padding-top: 18px;
  padding-bottom: 18px;
}
</style>
