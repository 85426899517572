<template>
  <Modal :data="$data" :isVisible="true">
    <div class="flex flex-col space-y-1 font-main text-md text-blue-dark-4 mb-4">
      <p>
        Deleting a user's personal data would mean that their personal information will be deleted and they will be
        anonymized in aggregated results. They will no longer be able to take future assessments under this account or
        have access to any information on the platform. It also means that they will lose access to their account and
        assessment results. This action cannot be undone.
      </p>
      <p>
        Are you sure you want to delete this user's personal information?
      </p>
    </div>
    <div class="flex flex-row ">
      <div class="flex flex-row flex-grow justify-end">
        <Button type="tertiary" size="medium" text="Cancel" @click.native="closeModal" class="mr-2" />
        <Button type="danger" text="Delete Personal Information" size="medium" @click.native="requestDelete" />
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'ConfirmDataDeleteModal',
  props: ['visible', 'deleteFn', 'userId', 'organizationId'],
  data: () => ({
    title: 'Delete User’s Personal Data',
  }),
  methods: {
    ...mapActions({
      closeModal: 'modals/closeModal',
    }),
    async requestDelete() {
      await this.deleteFn(this.userId, this.organizationId);
      this.closeModal();
    },
  },
};
</script>
