import { apiCall } from "./api";

export const requestDelete = () => {
  /*  genericApiCall("POST", "api/account/", {
    body,
  }) */
};
export const changePass = (email, currentPassword, newPassword) =>
  apiCall("POST", "api/account/change-password", {
    email,
    currentPassword,
    newPassword,
    confirmPassword: newPassword,
  });
export const changePosition = (jobTitle) =>
  apiCall("PUT", "api/v1/User/update_job_title", {
    jobTitle,
  });

export const setNotifications = () => {
  /* genericApiCall("POST",'api/account/',{
    body
  }) */
};

export const invite = (payload) => {
  return apiCall("POST", "api/Account/invite", payload);
};

export const inviteSeller = (payload) => {
  return apiCall("POST", `api/Account/invite_seller`, payload);
};
