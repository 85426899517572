import {
  changePosition,
  changePass,
  requestDelete,
  setNotifications,
} from "@/services/api/account";

const getDefaultState = () => ({
  alerts: {
    newAssessmentAlert: false,
    unfinishedAssessmentAlert: true,
    upcomingDeadlineAlert: false,
    newResultsAlert: true,
  },
  requestStatus: false,
});

const actions = {
  resetCallsState({ commit }) {
    commit("resetState");
  },
  setAlertStatus({ commit }, { alertName, value }) {
    commit("SET_STATUS", { alertName, value });
  },
  changePosition({ commit, dispatch }, { jobTitle }) {
    changePosition(jobTitle).then(() => {
      commit("home/CHANGE_POSITION", jobTitle, { root: true });
      dispatch(
        "alerts/showSuccess",
        {
          messageHeader: "Job Title Updated",
          message: "Your changes have been saved.",
        },
        { root: true }
      );
    });
  },
  changePassword({ dispatch }, { email, currentPassword, newPassword }) {
    return changePass(email, currentPassword, newPassword)
      .then((rsp) => {
        dispatch(
          "alerts/showSuccess",
          {
            messageHeader: "Password changed!",
            message: "The password was changed successfully.",
          },
          { root: true }
        );
        return true;
      })
      .catch((err) => {
        const error = JSON.parse(err.message);
        dispatch(
          "alerts/showError",
          {
            messageHeader: error?.Title || "Incorrect password!",
            message:
              error?.Message ||
              error ||
              "Please check the fields marked bellow and make sure you submit valid information.",
          },
          { root: true }
        );
        return false;
      });
  },
  requestDelete({ commit }) {
    requestDelete().then((rsp) => {
      commit("SET_REQUEST_STATUS", rsp.data); // maybe not rsp.data
    });
  },
  setNotifications() {
    setNotifications(state.alerts);
  },
};
const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  SET_STATUS(state, payload) {
    state.alerts[payload.alertName] = payload.value;
  },
  SET_REQUEST_STATUS(state, payload) {
    state.requestStatus = payload;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
